import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';


export const FolderOpenedIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  function FolderOpenedIcon(props, ref) {
    return (
      <SvgIcon ref={ref} {...props}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22 19.8889C22 20.4488 21.7682 20.9858 21.3556 21.3817C20.9431 21.7776 20.3835 22 19.8 22H2.2C1.61652 22 1.05695 21.7776 0.644365 21.3817C0.231785 20.9858 0 20.4488 0 19.8889V5.11111C0 4.55121 0.231785 4.01424 0.644365 3.61833C1.05695 3.22242 1.61652 3 2.2 3H7.7L9.9 6.16667H19.8C20.3835 6.16667 20.9431 6.38909 21.3556 6.785C21.7682 7.18091 22 7.71788 22 8.27778C22 8.27778 22 19.329 22 19.8889Z" fill="#7286A3"/>
          <path d="M2.31399 9.63219C2.49104 8.68589 3.31715 8 4.27987 8H22.5911C23.8431 8 24.7872 9.13721 24.557 10.3678L22.686 20.3678C22.509 21.3141 21.6828 22 20.7201 22H2.4089C1.15694 22 0.212768 20.8628 0.443012 19.6322L2.31399 9.63219Z" fill="#91ABD2"/>
        </svg>


      </SvgIcon>
    );
  }
);