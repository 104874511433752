import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary, Box, Button, CircularProgress, IconButton, Skeleton, SxProps, Theme, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRequiredDocuments } from '../contexts/RequiredDocumentsContext';
import { getUserId } from '../helpers/auth';
import { RequiredDocumentApprovalStatus } from '../helpers/constants';
import { niceDateTime } from '../helpers/converters';
import { convertHeicToJpeg, shortenFilename } from '../helpers/helpers';
import { FileActionType, FileType, UserFileVerification } from '../helpers/types';
import AddDeclineCommentModal from './AddDeclineCommentModal';
import ArchiveModal from './ArchiveModal';
import { BetterAccordion } from './BetterAccordion';
import { ShowIf } from './ConditionalRender/ShowIf';
import FileActionButton from './FileActionBtn';
import FileHistory from './FileHistory';
import ReplaceFileModal from './ReplaceFileModal';
import { StyledSwipeableDrawer } from './StyledComponents/StyledSwipeableDrawer';


interface FilePreviewDrawerProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  customSx?: SxProps<Theme>;
}

export const FilePreviewDrawer = (
  {
    isDrawerOpen,
    toggleDrawer,
    customSx,
  }: FilePreviewDrawerProps
) => {
  const {
    state: {
      selectedFile,
      openedFiles,
      loanSummaryExpanded,
      selectedRequiredDocument
    },
    setSelectedFile,
    resetOpenedFiles,
    replaceCurrentFile,
    archiveFile,
    unarchiveFile,
    verifyFile,
    declineFile,
    deleteFileAction
  } = useRequiredDocuments();

  const userId = getUserId();

  const [selectedTab, setSelectedTab] = useState(0);

  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const [isLoading, setIsLoading] = useState({
    action: '',
    replace: '',
    archive: '',
    unarchive: '',
    deleteAction: '',
  });

  const defaultFileUrl = `/api/v1/loans/file/${selectedFile?.id}`;
  const currentFileId = useRef<string | null>(null);

  const currentFile = useMemo(() => {
    return selectedRequiredDocument?.files?.find((file) => file.is_current === true);
  }, [selectedRequiredDocument]);

  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false);
  const [fileToReplaceWith, setFileToReplaceWith] = useState<FileType | null>(null);

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [fileToArchive, setFileToArchive] = useState<FileType | null>(null);

  const [expanded, setExpanded] = useState(false);

  const [declineModalOpen, setDeclineModalOpen] = useState(false);

  const [showUpdated, setShowUpdated] = useState('');

  const isFileSentToBank = selectedRequiredDocument?.approval_status === RequiredDocumentApprovalStatus.SENT_TO_BANK;

  const handleOpenReplaceModal = async (file: FileType) => {
    setIsReplaceModalOpen(true);
    setFileToReplaceWith(file);
  };

  const handleOpenArchiveModal = async (file: FileType) => {
    setIsArchiveModalOpen(true);
    setFileToArchive(file);
  };

  const handleConfirmReplace = async (fileToReplace: FileType) => {
    setIsLoading({ ...isLoading, replace: fileToReplace.id });
    await replaceCurrentFile(fileToReplace.id, selectedRequiredDocument!.parent_id);
    setShowUpdated('Updated!')
    setTimeout(() => {
      setShowUpdated('');
    }, 5000);
    setIsLoading({ ...isLoading, replace: '' });
    setIsReplaceModalOpen(false);
  };

  const handleConfirmArchive = async (fileToArchive: FileType) => {
    setIsLoading({ ...isLoading, archive: fileToArchive.id });
    await archiveFile(fileToArchive.id, selectedRequiredDocument!.parent_id);
    setIsLoading({ ...isLoading, archive: '' });
    setIsArchiveModalOpen(false);
  };

  const handleUnarchiveFile = async (fileToUnarchive: FileType) => {
    setIsLoading({ ...isLoading, unarchive: fileToUnarchive.id });
    await unarchiveFile(fileToUnarchive.id, selectedRequiredDocument!.parent_id);
    setIsLoading({ ...isLoading, unarchive: '' });
  };

  const handleVerifyFile = async (fileToVerify: FileType) => {
    setIsLoading({ ...isLoading, action: fileToVerify.id });
    await verifyFile(fileToVerify.id, selectedRequiredDocument!.parent_id, userId);
    setIsLoading({ ...isLoading, action: '' });
  };

  const handleDeclineFile = async (fileToDecline: FileType, comment: string) => {
    setIsLoading({ ...isLoading, action: fileToDecline.id });
    await declineFile(fileToDecline.id, selectedRequiredDocument!.parent_id, userId, comment);
    setIsLoading({ ...isLoading, action: '' });
    setDeclineModalOpen(false);
  };

  const handleDeleteFileAction = async (fileToUndo: FileType, actionToDelete: UserFileVerification) => {
    setIsLoading({ ...isLoading, deleteAction: String(actionToDelete.id) });
    await deleteFileAction(fileToUndo.id, selectedRequiredDocument!.parent_id, userId, actionToDelete.id);
    setIsLoading({ ...isLoading, deleteAction: '' });
  };

  const handleCloseDrawer = useCallback(() => {
    // Remove all files
    resetOpenedFiles()

    // Reset selected tab
    setSelectedTab(0);

    // Close the drawer
    toggleDrawer();
  }, [])

  useEffect(() => {
    if (!openedFiles.length) {
      setSelectedFile(null);
      setSelectedTab(0);
    } else {
      setSelectedFile(selectedFile);
      const selectedIndex = openedFiles.findIndex(file => file.id === selectedFile?.id);
      setSelectedTab(selectedIndex !== -1 ? selectedIndex : 0);
    }
  }, [openedFiles, selectedFile, handleCloseDrawer]);

  useEffect(() => {
    if (selectedFile?.file_name.toLowerCase().endsWith('.heic')) {
      currentFileId.current = selectedFile.id; // Track the current file being processed
      setIsFileLoading(true);

      // Convert HEIC file to JPEG
      convertHeicToJpeg(defaultFileUrl)
        .then((url) => {
          // Only update if the file is still the active one
          if (currentFileId.current === selectedFile.id) {
            setFileUrl(url || undefined);
          }
        })
        .catch(() => {
          if (currentFileId.current === selectedFile.id) {
            setFileUrl(undefined);
          }
        })
        .finally(() => {
          setIsFileLoading(false);
        });
    } else {
      currentFileId.current = selectedFile ? selectedFile.id : null;
      setFileUrl(defaultFileUrl);
      setIsFileLoading(false);
    }
  }, [selectedFile]);

  return (
    <>
      <StyledSwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        onOpen={toggleDrawer}
        variant="permanent" // This is ABSOLUTELY necessary in order for the component to be always open and allow opening, closing, and interacting with the page
        PaperProps={{
          sx: {
            ...customSx,
            transition: 'none',
            transitionDuration: '0s',
            '& .MuiPaper-root': {
              transition: 'none',
              transitionDuration: '0s',
            },
            '& .MuiDrawer-docked': {
              transition: 'none',
              transitionDuration: '0s'
            },
            '& .MuiDrawer-paper': {
              transition: 'none',
              transitionDuration: '0s'
            },
          },
          'data-testid': 'drawer-paper',
          transition: 'none',
          transitionDuration: '0s',
        }}
        ModalProps={{ keepMounted: true }}
      >
        <Box sx={{ px: 2, pt: .5, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }} role="presentation">
          <IconButton onClick={handleCloseDrawer} sx={{ px: 0 }}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto', // Enable scrolling inside the content area
            padding: 2,
            paddingTop: 0,
          }}
        >
          <ShowIf every={[openedFiles.length > 0]}>
            <>
              {selectedFile && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        border: '1px solid #DDD',
                        borderRadius: '4px',
                        padding: '10px',
                        backgroundColor: '#F9F9F9',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: loanSummaryExpanded ? '32vh' : '45vh',
                      }}
                    >
                      {isFileLoading ? (
                        <Skeleton variant="rectangular" width="100%" height="100%" />
                      ) : (
                        selectedFile.file_name.toLowerCase().match(/\.(jpeg|jpg|png|gif|heic)$/) ? (
                          <img
                            src={fileUrl}
                            alt={selectedFile.file_name}
                            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                          />
                        ) : (
                          selectedFile.file_name.toLowerCase().endsWith('.pdf') ? (
                            <iframe
                              src={fileUrl}
                              title="PDF Preview"
                              style={{ width: '100%', height: '100%', border: 'none' }}
                            />
                          ) : (
                            <Box textAlign="center">
                              <Typography variant="body1" color="textSecondary">
                                Preview is not available.{' '}
                                <a href={fileUrl} download={selectedFile.file_name} style={{ color: '#007bff' }}>
                                  Download File
                                </a>
                              </Typography>
                            </Box>
                          )
                        )
                      )}
                    </Box>
                    <Typography sx={{ fontSize: '16px', fontWeight: 700, fontFamily: 'Lato, sans-serif', mt: 1 }}>
                      {selectedRequiredDocument?.requirement_name}
                    </Typography>
                    <Box
                      sx={{
                        height: loanSummaryExpanded ? '35%' : '45%',
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        overflowY: 'auto',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '1rem',
                          height: '90%',
                        }}
                      >
                        {currentFile && Object.keys(currentFile).length > 0 && (
                          <Box>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end', mb: 1 }}>
                              <Typography sx={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Lato, sans-serif' }}>Current</Typography>
                              <ShowIf every={[!!showUpdated]}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 700, fontFamily: 'Lato, sans-serif', color: '#2E9C7F' }}>
                                  {showUpdated}
                                </Typography>
                              </ShowIf>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: selectedFile?.id === currentFile?.id ? '#F4FAFF' : 'transparent',
                                border: selectedFile?.id === currentFile?.id ? '2px solid #1E89DD' : '1px solid #DDDFE8',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: '1rem',
                                  justifyContent: 'space-between',
                                  p: '12px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setSelectedFile(currentFile!);
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Typography sx={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Lato, sans-serif', color: '#1E89DD' }}>
                                    {currentFile && shortenFilename(currentFile?.file_name, 25)}
                                  </Typography>
                                  <Typography sx={{ fontSize: '11px', fontWeight: 400, fontFamily: 'Lato, sans-serif', color: '#868795' }}>
                                    {`${currentFile?.created_by} ${niceDateTime(currentFile?.created_at)}`}
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: 1,
                                  }}
                                >
                                  {isLoading.action === currentFile?.id ? (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mr: '1rem',
                                      }}
                                    >
                                      <CircularProgress size={20} />
                                    </Box>
                                  ) : (
                                    <>
                                      <FileActionButton
                                        text="Verify"
                                        onClick={() => handleVerifyFile(currentFile!)}
                                        // Disable Verify button if the last action from the user was Verify (excluding Undo Actions)
                                        disabled={
                                          !!(currentFile?.user_file_verifications
                                            ?.find(action => action.user.id === userId && action.action === FileActionType.VERIFY)) ||
                                          isFileSentToBank
                                        }
                                      />
                                      <FileActionButton
                                        text="Decline"
                                        onClick={() => setDeclineModalOpen(true)}
                                        // Disable Decline button if the last action from the user was Decline (excluding Undo Actions)
                                        disabled={
                                          !!(currentFile?.user_file_verifications
                                            ?.find(action => action.user.id === userId && action.action === FileActionType.DECLINE)) ||
                                          isFileSentToBank
                                        }
                                      />
                                    </>
                                  )}
                                </Box>
                              </Box>
                              {/* Show if there's action history and only display the last 2 actions */}
                              <ShowIf every={[!!(currentFile?.user_file_verifications?.length)]}>
                                <FileHistory
                                  userFileVerifications={currentFile?.user_file_verifications!}
                                  isLoading={isLoading}
                                  handleDeleteFileAction={(actionToDelete) => handleDeleteFileAction(currentFile!, actionToDelete)}
                                  file={currentFile!}
                                  displayedInFilePreview={true}
                                  reqDocApprovalStatus={selectedRequiredDocument?.approval_status}
                                />
                              </ShowIf>
                            </Box>
                          </Box>
                        )}
                        {selectedRequiredDocument?.files.some(file => !file.is_current && !file.is_deleted) && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '1rem',
                              height: '90%',
                            }}
                          >
                            <Typography sx={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Lato, sans-serif' }}>
                              {selectedRequiredDocument.requirement_name} Past File Versions
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '.5rem',
                                height: '90%',
                                overflowY: 'auto',
                              }}
                            >
                              {selectedRequiredDocument?.files.filter((file) => !file.is_current && !file.is_deleted).map((file) => (
                                <Box key={file.id}
                                  sx={{
                                    border: selectedFile?.id === file.id ? '2px solid #1E89DD' : '1px solid #DDDFE8',
                                    backgroundColor: selectedFile?.id === file.id ? '#F4FAFF' : 'transparent',
                                    p: '12px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                  onClick={() => {
                                    setSelectedFile(file);
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      maxWidth: '40%',
                                    }}
                                  >
                                    <Typography sx={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Lato, sans-serif', color: '#1E89DD' }}>
                                      {shortenFilename(file.file_name, 25)}
                                    </Typography>
                                    <Typography sx={{ fontSize: '11px', fontWeight: 400, fontFamily: 'Lato, sans-serif', color: '#868795' }}>
                                      {`${niceDateTime(file?.created_at)} ${file?.created_by}`}
                                    </Typography>
                                  </Box>

                                  {isLoading.replace === file.id || isLoading.archive === file.id ? (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mr: '1rem',
                                      }}
                                    >
                                      <CircularProgress size={20} />
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        gap: 1,
                                        alignItems: 'center',
                                      }}
                                    >
                                      <FileActionButton
                                        text="Replace Current"
                                        onClick={() => handleOpenReplaceModal(file)}
                                        disabled={isFileSentToBank}
                                      />
                                      <FileActionButton
                                        text="Archive"
                                        onClick={() => handleOpenArchiveModal(file)}
                                        disabled={isFileSentToBank}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}
                        {/* Only show the Accordion if there are any archived files */}
                        {selectedRequiredDocument?.files.some(file => file.is_deleted) && (
                          <BetterAccordion
                            expanded={expanded}
                            onChange={(event, isExpanded) => {
                              setExpanded(isExpanded);
                            }}
                            sx={{
                              mb: 2,
                              border: 'none',
                              width: '100%',
                              backgroundColor: 'transparent',
                              boxShadow: 'none',
                              '&.MuiAccordion-root::before': {
                                display: 'none',
                              },
                              '&.MuiAccordion-root': {
                                border: 'none',
                                boxShadow: 'none',
                                width: 1,
                              },
                              '& .Mui-expanded': {
                                minHeight: 'none',
                              },
                              '& .MuiAccordionSummary-content': {
                                margin: '12px 0px',
                              },
                              '& .MuiAccordionSummary-root': {
                                padding: '0px',
                              },
                            }}
                          >
                            <AccordionSummary
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <ExpandMoreIcon sx={{ transform: expanded ? 'rotate(0deg)' : 'rotate(270deg)' }} />
                              <Typography sx={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Lato, sans-serif' }}>
                                Archived
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                p: 0,
                              }}
                            >
                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

                                {selectedRequiredDocument?.files.filter(file => file.is_deleted).map((file) => (
                                  <Box key={file.id}
                                    sx={{
                                      border: selectedFile?.id === file.id ? '2px solid #C8CAD4' : '1px solid #DDDFE8',
                                      backgroundColor: selectedFile?.id === file.id ? '#F5F5F5' : 'transparent',
                                      p: '12px',
                                      cursor: 'pointer',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                    onClick={() => {
                                      setSelectedFile(file);
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        maxWidth: '40%',
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Lato, sans-serif', color: '#333333' }}>
                                        {shortenFilename(file.file_name, 25)}
                                      </Typography>
                                      <Typography sx={{ fontSize: '11px', fontWeight: 400, fontFamily: 'Lato, sans-serif', color: '#868795' }}>
                                        {`${niceDateTime(file?.created_at)} ${file?.created_by}`}
                                      </Typography>
                                    </Box>
                                    {isLoading.unarchive === file.id ? (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          mr: '1rem',
                                        }}
                                      >
                                        <CircularProgress size={20} />
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          gap: 1,
                                          alignItems: 'center',
                                        }}
                                      >
                                        <FileActionButton
                                          text="Unarchive"
                                          onClick={() => handleUnarchiveFile(file)}
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                ))}
                              </Box>
                            </AccordionDetails>
                          </BetterAccordion>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '1rem',
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => window.open(fileUrl, '_blank')}
                    >
                      Open in Tab
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      href={fileUrl || defaultFileUrl}
                      download={selectedFile?.file_name}
                      sx={{
                        color: 'white !important',
                      }}
                    >
                      Download File
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          </ShowIf>
          <ShowIf every={[openedFiles.length === 0]}>
            <Box
              mt={2}
              sx={{
                border: '1px solid #4476AD',
                borderRadius: '6px',
                p: '.5rem',
                backgroundColor: '#D8E4F1',
              }}
            >
              <Typography fontSize={'14px'} color={'#4476AD'}>
                Select files to preview.
              </Typography>
            </Box>
          </ShowIf>
        </Box>
      </StyledSwipeableDrawer>
      <ReplaceFileModal
        open={isReplaceModalOpen}
        onClose={() => setIsReplaceModalOpen(false)}
        onConfirm={() => handleConfirmReplace(fileToReplaceWith!)}
        requiredDocName={selectedRequiredDocument?.requirement_name || ''}
        fileToReplace={currentFile?.file_name || ''}
        fileToReplaceWith={fileToReplaceWith?.file_name || ''}
      />
      <ArchiveModal
        open={isArchiveModalOpen}
        onClose={() => setIsArchiveModalOpen(false)}
        onConfirm={() => handleConfirmArchive(fileToArchive!)}
        requiredDocName={selectedRequiredDocument?.requirement_name || ''}
        fileToArchive={fileToArchive?.file_name || ''}
      />
      <AddDeclineCommentModal
        open={declineModalOpen}
        onClose={() => setDeclineModalOpen(false)}
        onConfirm={(comment) => handleDeclineFile(currentFile!, comment)}
        documentName={selectedRequiredDocument?.requirement_name || ''}
        file={currentFile || {} as FileType}
      />
    </>
  );
};