import React, { useState } from 'react';
import { Box, Button, Typography, Modal, TextField } from '@mui/material';
import { FileType } from '../helpers/types';
import { niceDateTime } from '../helpers/converters';


interface ReplaceFileModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (comment: string) => void;
  documentName: string;
  file: FileType;
}

const ReplaceFileModal: React.FC<ReplaceFileModalProps> = ({
  open,
  onClose,
  onConfirm,
  documentName,
  file,
}) => {
  const [comment, setComment] = useState('');

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography 
            sx={{
                fontWeight: 400,
                fontFamily: 'Lato, sans-serif',
                fontSize: '24px',
            }}
        >
          Decline Document
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              border: '1px solid gray',
              borderRadius: '4px',
              p: '10px',
              flexDirection: 'column',
            }}
          >
            <Typography 
              sx={{
                fontWeight: 400,
                fontFamily: 'Lato, sans-serif',
                fontSize: '14px',
                color: '#333333',
              }}
            >
              {documentName}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'flex-end',
              }}
            >
            <Typography sx={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Lato, sans-serif', color: '#1E89DD' }}>
              {file.file_name}
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Lato, sans-serif', color: '#868795' }}>
              {file.created_by}
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Lato, sans-serif', color: '#868795' }}>
              {niceDateTime(file.created_at)}
            </Typography>
          </Box>
          </Box>

        </Box>
        <Typography
          sx={{
              fontWeight: 400,
              fontFamily: 'Lato, sans-serif',
              fontSize: '14px',
              color: '#333333',
              mt: 2,
          }}
        >
          Please provide a reason for declining this document.
        </Typography>
        <TextField
          label="Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          fullWidth
          multiline
          rows={4}
          sx={{ 
            mt: 2,
            fontWeight: 400,
            fontFamily: 'Lato, sans-serif',
            fontSize: '14px',
            color: '#333333',
          }}
        />
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={() => {
            onConfirm(comment);
            setComment('');
          }}
            disabled={!comment}
          >
            Decline
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReplaceFileModal;