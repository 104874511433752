import React from 'react';
import { Box, Typography, SxProps, Theme, Button } from '@mui/material';
import { fileActionTakenMapping } from '../helpers/types';

interface FileActionButtonProps {
  text: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  customSx?: SxProps<Theme>;
  disabled?: boolean;
}
const FileActionButton: React.FC<FileActionButtonProps> = ({ text, onClick, customSx, disabled }) => {

  return (
    <Button
      component="div"
      sx={{
        p: '6px 12px',
        border: disabled ? 'default' : '1px solid #ECECEC',
        borderRadius: '28px',
        backgroundColor: '#FFF',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'fit-content',
        textTransform: 'none',
        ...customSx,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography sx={{ fontSize: '12px', fontWeight: 700, fontFamily: 'Lato, sans-serif', color: disabled ? '#ECECEC' : '#1E89DD', textAlign: 'center' }}>
        {text}
      </Typography>
    </Button>
  );
};

export default FileActionButton;