import React from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';

interface ArchiveModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  requiredDocName: string;
  fileToArchive: string;
}

const ArchiveModal: React.FC<ArchiveModalProps> = ({
  open,
  onClose,
  onConfirm,
  requiredDocName,
  fileToArchive,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography 
            sx={{
                fontWeight: 400,
                fontFamily: 'Lato, sans-serif',
                fontSize: '24px',
            }}
        >
          Archive document
        </Typography>
        <Typography 
            sx={{
                fontWeight: 400,
                fontFamily: 'Lato, sans-serif',
                fontSize: '14px',
                color: '#333333',
                mt: 2,
            }}
        >
          {requiredDocName}
        </Typography>
        <Typography 
            sx={{ 
                mt: 2,
                fontWeight: 400,
                fontFamily: 'Lato, sans-serif',
                fontSize: '14px',
                color: '#333333',
            }}>
          You are archiving <b>"{fileToArchive}"</b>. This document will be removed in Railway. This action can be undone.
        </Typography>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Archive
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ArchiveModal;