import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  IconButton,
  TextField,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useRequiredDocuments } from '../../../contexts/RequiredDocumentsContext';
import { ArchetypeDocumentType, RequiredDocument as RequiredDocumentType } from '../../../helpers/types';
import { ArchetypeConditionsModal } from './ArchetypeConditionsModal';
import { isAdmin } from '../../../helpers/auth';
import { ShowIf } from '../../../components/ConditionalRender/ShowIf';
import { downloadCSV } from '../../../helpers/files';


export const ArchetypeDocumentTypesRequirements = () => {
  const {
    state: { allArchetypeDocumentTypes },
    getAllArchetypeDocumentTypes,
    updateArchetypeDocumentTypeConditions
  } = useRequiredDocuments();

  const [selectedRow, setSelectedRow] = useState<ArchetypeDocumentType | null>(null);
  const [conditions, setConditions] = useState<{ key: string; value: string }[]>([]);
  const [open, setOpen] = useState(false);

  const [filterBy, setFilterBy] = useState<string>('all');
  const [filterValue, setFilterValue] = useState<string>('');
  const filterValueRef = useRef<HTMLInputElement>(null);

  // Return allArchetypeDocumentTypes elements where filterValue exists in one of filterBy: archetype, document type, or conditions
  // If filterBy is all, return all elements
  // This does not affect the export to CSV
  const filteredArchetypeDocumentTypes: ArchetypeDocumentType[] = useMemo(() => {

    if (filterBy === 'all' || !filterValue) {
      return allArchetypeDocumentTypes?.data || [];
    }
    return allArchetypeDocumentTypes?.data.filter((doc) => {
      let valueToCheck = '';

      if (filterBy === 'archetype') {
        // If filtering by archetype, we allow users to filter by name or description
        valueToCheck = `${doc.archetype?.display_name || ''} ${doc.archetype?.description || ''} ${doc.archetype?.sf_api_name || ''}`.toLowerCase();
      } else if (filterBy === 'document_type') {
        // If filtering by document type, we allow users to filter by name or crb_abbrev
        valueToCheck = `${doc.document_type?.name || ''} ${doc.document_type?.crb_abbrev || ''}`.toLowerCase();
      } else if (filterBy === 'conditions') {

        const normalizedFilterValue = filterValue.replace(/_+|__c/g, ' ').toLowerCase();

        return Object.entries(doc.conditions || {}).some(([key, value]) => {
          const normalizedKey = key.replace(/_+|__c/g, ' ').toLowerCase();
          const normalizedValue = String(value).toLowerCase();

          const keyMatches = normalizedKey.includes(normalizedFilterValue);
          const valueMatches = normalizedValue.includes(normalizedFilterValue);

          return keyMatches || valueMatches;
        });
      } else {
        // If filtering by any other field, we allow users to filter by the value of that field
        valueToCheck = doc[filterBy]?.toString().toLowerCase() || '';
      }

      return valueToCheck.includes(filterValue.toLowerCase());
    });
  }, [allArchetypeDocumentTypes, filterBy, filterValue]);

  useEffect(() => {
    getAllArchetypeDocumentTypes();
  }, []);

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    // Convert JSON conditions into an array of key-value pairs
    const parsedConditions = row.conditions
      ? Object.entries(row.conditions).map(([key, value]) => ({ key, value: String(value) }))
      : [{ key: '', value: '' }];
    setConditions(parsedConditions);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedRow(null);
    setConditions([]);
  };

  const handleConditionChange = (index, field, value) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][field] = value;
    setConditions(updatedConditions);
  };

  // Add new key-value pair
  const addCondition = () => {
    setConditions([...conditions, { key: '', value: '' }]);
  };

  const removeCondition = (index) => {
    setConditions(conditions.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    if (!selectedRow) return;

    let updatedConditions: Record<string, string | boolean> | null = conditions.reduce((acc, { key, value }) => {
      if (key.trim()) {
        // Parse "true" or "false" as boolean, else, keep the value as a string
        const parsedValue = value.trim().toLowerCase() === 'true'
          ? true
          : value.trim().toLowerCase() === 'false'
            ? false
            : value.trim();
        acc[key.trim()] = parsedValue;
      }
      return acc;
    }, {});

    // If all conditions are removed, set the value as null
    if (Object.keys(updatedConditions).length === 0) {
      updatedConditions = null;
    }

    try {
      await updateArchetypeDocumentTypeConditions(selectedRow.id, updatedConditions);

      handleCloseModal();
    } catch (error) {
      console.error('Error updating record:', error);
    }
  };

  const convertToCSV = (data: ArchetypeDocumentType[]) => {
    const headers = ['document_name', 'archetype_sf_api_name', 'conditions'];

    const content = data.map((row) => {
      
      const documentName = row.document_type.name;
      const archetypeSFApiName = row.archetype.sf_api_name;

      // Convert JSON conditions to proper format without extra quotes
      let conditions = row.conditions
        ? JSON.stringify(row.conditions)
          .replace(/^"|"$/g, '') // Remove surrounding quotes
          .replace(/\\"/g, '"') // Unescape quotes
        : '';

      return [documentName, archetypeSFApiName, conditions].join(',');
    });

    return { headers: headers.join(',') + '\n', content: content.join('\n') };
  };

  return (
    <Box sx={{ px: 4, py: 5, }}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography variant="h5" >
          Document Types Requirements
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const { headers, content } = convertToCSV(allArchetypeDocumentTypes?.data || []);
            downloadCSV(headers, content, 'archetype-document-type');
          }}
          sx={{}}
        >
          Download CSV
        </Button>
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 4, gap: 2 }}
      >
        <TextField
          select
          label="Filter by"
          id="filter-by"
          value={filterBy}
          onChange={(e) => setFilterBy(e.target.value)}
          fullWidth
          sx={{
            fontFamily: 'Lato, sans-serif',
            width: '12rem',
            '& .MuiSelect-select': {
              py: '5px',
            },
          }}
          variant="outlined"
          InputLabelProps={{
            shrink: true, // This ensures the label is always at the top
          }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="archetype">Archetype</MenuItem>
          <MenuItem value="document_type">Document Type</MenuItem>
          <MenuItem value="conditions">Conditions</MenuItem>
        </TextField>
        {/* Show input field if filtering by anything other than 'all' */}
        {filterBy !== 'all' && (
          <TextField
            id="filter-input"
            label={`Filter ${filterBy.replace(/_/g, ' ')}`}
            variant="outlined"
            sx={{
              fontFamily: 'Lato, sans-serif',
              '& .MuiInputBase-input': {
                py: '5px',
              },
              '& .MuiInputLabel-root': {
                top: '-10px',
              },
              // This is for the label to shrink and move up when the input is focused
              '& .MuiInputLabel-root.MuiInputLabel-shrink': {
                transform: 'translate(14px, 1px) scale(.75)',
              },
            }}
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            inputRef={filterValueRef}
            placeholder={filterBy === 'created_after_date' || filterBy === 'created_before_date' ? 'YYYY, YYYY-MM-DD' : ''}
          />
        )}
      </Box>
      <TableContainer component={Paper} sx={{ height: '75vh', marginTop: 4 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Document Type</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Archetype</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Conditions</TableCell>
              <ShowIf every={[isAdmin()]}>
                <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
              </ShowIf>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredArchetypeDocumentTypes.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.document_type.name}</TableCell>
                <TableCell>{row.archetype.description}</TableCell>
                <TableCell>
                  {row.conditions
                    ? JSON.stringify(row.conditions, null, 2)
                    : 'No conditions'}
                </TableCell>
                <ShowIf every={[isAdmin()]}>
                  <TableCell>
                    <IconButton onClick={() => handleOpenModal(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </ShowIf>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <ArchetypeConditionsModal
        open={open}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
        conditions={conditions}
        handleConditionChange={handleConditionChange}
        addCondition={addCondition}
        removeCondition={removeCondition}
        handleSave={handleSave}
      />
    </Box>
  );
};
