import { MasterApprovers } from '@backend/constants';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { getUserId } from '../helpers/auth';
import { RequiredDocumentApprovalStatus } from '../helpers/constants';
import { niceDateTime } from '../helpers/converters';
import { fileActionTakenMapping, FileActionType, FileType, RequiredDocument, UserFileVerification } from '../helpers/types';


interface FileHistoryProps {
  userFileVerifications: UserFileVerification[];
  isLoading: { deleteAction: string };
  handleDeleteFileAction: (fileAction: UserFileVerification) => void;
  displayedInFilePreview?: boolean;
  file: FileType;
  reqDocApprovalStatus: RequiredDocument['approval_status'] | undefined;
}

const FileHistory: React.FC<FileHistoryProps> = ({
  userFileVerifications,
  isLoading,
  handleDeleteFileAction,
  displayedInFilePreview = false,
  reqDocApprovalStatus,
}) => {

  const userId = getUserId();

  const isActionFromUser = (action: UserFileVerification) => {
    return action.user.id === userId;
  };

  // This is to make the scroll bar start at the bottom of the file history container
  const containerRef = useRef<HTMLDivElement | null>(null);

  const renderUserNameWithStar = (user: UserFileVerification['user']) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: .5 }}>
      {user.name}
      {MasterApprovers.has(user.username) && (
        <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#000000', borderRadius: '4px', padding: '1px 4px' }}>
          <Typography sx={{ fontSize: '7px', fontWeight: 400, fontFamily: 'Lato, sans-serif', color: '#FFFFFF' }}>
            M
          </Typography>
        </Box>
      )}
    </Box>
  );

  useEffect(() => {
    // This is to make the scroll bar start at the bottom of the file history container
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [userFileVerifications]);

  return (
    <Box
      ref={containerRef} // This is to make the scroll bar start at the bottom of the file history container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        backgroundColor: displayedInFilePreview ? '#E9F5FF' : 'transparent',
        p: displayedInFilePreview ? '12px' : '12px 0 12px .5rem',
      }}
    >
      {userFileVerifications
        .map((fileAction) => (
          <Box
            key={fileAction.id}
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'flex-end',
              justifyContent: displayedInFilePreview ? 'space-between' : 'flex-start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'flex-end',
                width: 'fit-content',
              }}
            >
              <Typography sx={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Lato, sans-serif' }}>
                {fileAction.user.position?.display_name}
              </Typography>
              <Typography sx={{ fontSize: '11px', fontWeight: 400, fontFamily: 'Lato, sans-serif', color: '#868795' }}>
                {renderUserNameWithStar(fileAction.user)}
              </Typography>
              <Tooltip title={niceDateTime(fileAction.created_at)}>
                <Typography sx={{ fontSize: '11px', fontWeight: 400, fontFamily: 'Lato, sans-serif', color: '#868795' }}>
                  {niceDateTime(fileAction.created_at, false)}
                </Typography>
              </Tooltip>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Tooltip title={
                fileAction.action === FileActionType.DECLINE
                  ? <Typography sx={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Lato, sans-serif', }}>
                    {fileAction.comment}
                  </Typography>
                  : null
              }
                placement="top"
                slotProps={{
                  tooltip: {
                    sx: {
                      color: "#FFFFFF",
                      backgroundColor: "#333333",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    fontFamily: 'Lato, sans-serif',
                    backgroundColor: fileActionTakenMapping[fileAction.action].backgroundColor,
                    color: fileActionTakenMapping[fileAction.action].color,
                    borderRadius: '40px',
                    padding: '2px 10px',
                  }}
                >
                  {fileActionTakenMapping[fileAction.action].display_name}
                </Typography>
              </Tooltip>
              {isActionFromUser(fileAction) && reqDocApprovalStatus !== RequiredDocumentApprovalStatus.SENT_TO_BANK && (
                isLoading.deleteAction === String(fileAction.id) ? (
                  <CircularProgress size={20} />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      p: '2px',
                    }}
                    onClick={() => handleDeleteFileAction(fileAction)}
                  >
                    <CloseRoundedIcon sx={{ color: '#2196F3', fontSize: '12px' }} />
                  </Box>
                )
              )}
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default FileHistory;