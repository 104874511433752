import { ScorecardItemKeys } from '@backend/constants';
import { itemConfig } from '@backend/services/itemConfig';
import { SFLoanApplication } from '@backend/types/Loan';
import { ArrowBack, FileUpload } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import generatePDF, { Margin } from 'react-to-pdf';
import { SummaryRow } from '../../../components/SummaryRow';
import { toCurrency } from '../../../helpers/converters';
import {
  getScoreString,
  getSectionScoreString,
  getValueHolder,
} from '../../../helpers/scorecardHelper';
import { lastYear } from '../../../helpers/uwReportsHelper';
import { getFormatter } from './Formatters';
import { ScoringLegend } from './ScoringLegend';

export const PGCreditMemo = ({
  loanAnalysis,
  scores,
  guarantor,
  annualDebtObligation,
  setBackButton,
  pgScore,
}) => {
  enum Exhibit {
    B,
    C,
  }
  const app = useOutletContext() as SFLoanApplication & any;

  const [borrowerType, setBorrowerType] = useState('');
  const [principalOwnershipPercent, setPrincipalOwnershipPercent] =
    useState('');
  const [relatedProperty, setRelatedProperty] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');
  const [creditMemoNotes, setCreditMemoNotes] = useState('');
  const [legalType, setLegalType] = useState('');

  const PAGE_WIDTH = '11.5in';
  const PAGE_HEIGHT = '15in';

  const [exhibitBPageHeight, setExhibitBPageHeight] = useState('3in');
  const [exhibitCPageHeight, setExhibitCPageHeight] = useState('3in');

  const downloadPDF = async () => {
    const getTargetElement = () => document.getElementById('credit-memo');
    const fileName = `${app.Name}_${app.Borrower_Business_Name__c}_pgcreditmemo.pdf`;
    const creditMemoPdf = await generatePDF(getTargetElement, {
      filename: fileName,
      method: 'build',
      page: {
        margin: Margin.SMALL,
        format: 'letter',
      },
    });
    creditMemoPdf.save(fileName);
    const data = new FormData();
    data.append(
      'credit-memo',
      new Blob([creditMemoPdf.getFileFromVFS(fileName)]),
      fileName,
    );
    data.append('scorecard', JSON.stringify(scores));
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `/api/v1/uw/pg-credit-memo/${app.Id}`,
      headers: {
        'Content-Type': 'application/pdf',
      },
      data: data,
    };
    try {
      await axios.request(config);
    } catch (err) {
      console.error(err);
    }
  };

  const renderValue = (key: string) => {
    const item = loanAnalysis.find((a) => a.type === key);
    if (!item) throw new Error(`Item with key ${key} not found`);

    const vh = getValueHolder(item);
    if (item.itemConfig) {
      if ('formatter' in item.itemConfig) {
        return getFormatter(item.itemConfig.formatter)(vh);
      }
    }

    return vh.value;
  };

  const toTitle = (key: string) => {
    if (key.length === 0) return key;

    return key.charAt(0).toUpperCase() + key.replace(/-/g, '/').slice(1);
  };

  const [showExhibitB, setShowExhibitB] = useState(false);
  const [showExhibitC, setShowExhibitC] = useState(false);
  const [exhibitBSource, setExhibitBSource] = useState('');
  const [exhibitCSource, setExhibitCSource] = useState('');
  const handleUpload = (exhibit: Exhibit) => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';

    input.click();
    input.onchange = (_) => {
      if (!input.files) return;

      if (exhibit === Exhibit.B) {
        setExhibitBPageHeight('15in');
        setShowExhibitB(true);
        setExhibitBSource(URL.createObjectURL(input.files[0]));
      }
      if (exhibit === Exhibit.C) {
        setExhibitCPageHeight('15in');
        setShowExhibitC(true);
        setExhibitCSource(URL.createObjectURL(input.files[0]));
      }
    };
  };

  const groups = {};
  for (const item of loanAnalysis) {
    if (groups[item.grouping]) groups[item.grouping].push(item);
    else groups[item.grouping] = [item];
  }

  const oneYearAgo = lastYear();

  const legalTypeMenuItems = [
    '1A - Standard (Owns)',
    '1B - Standard (Lease)',
    '2A - Sells Power (Owns)',
    '2B - Sells Power (Lease)',
    '2C - Sells Power/Community (Owns)',
    '2D - Sells Power/Community (Lease)',
  ].map((option) => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  ));

  return (
    <>
      <Box>
        <Button
          startIcon={<ArrowBack />}
          variant="lightweight"
          onClick={setBackButton}
        >
          Scorecard
        </Button>
      </Box>
      <Box className="flex-row-space-between">
        <Typography variant="h5">Generated Credit Memo</Typography>
        <Button
          variant="dark"
          onClick={() => {
            downloadPDF();
          }}
        >
          PDF Download
        </Button>
      </Box>

      <Paper variant="outlined" className="content">
        <Typography variant="h6">Complete the manual entries</Typography>
        <Box className="flex-row-start flex-gap-2" alignContent="top">
          <TextField
            variant="outlined"
            label="Borrower Type"
            value={borrowerType}
            onChange={(e) => setBorrowerType(e.target.value)}
          />
          <Box sx={{ width: '15em' }}>
            <FormControl fullWidth>
              <InputLabel>Guarantor Owns Related Property</InputLabel>
              <Select
                label="Guarantor Owns Related Property"
                value={relatedProperty}
                onChange={(e) => setRelatedProperty(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: '15em' }}>
            <FormControl fullWidth>
              <InputLabel>Legal Type </InputLabel>
              <Select
                label="Legal Type"
                value={legalType}
                onChange={(e) => setLegalType(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {legalTypeMenuItems}
              </Select>
            </FormControl>
          </Box>
          <TextField
            variant="outlined"
            label="Guarantor Ownership %"
            value={principalOwnershipPercent}
            onChange={(e) => setPrincipalOwnershipPercent(e.target.value)}
          />
        </Box>
        <Box sx={{ width: '30rem' }}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              label="Business Description"
              value={businessDescription}
              onChange={(e) => setBusinessDescription(e.target.value)}
              multiline
              fullWidth
              minRows={4}
            />
          </FormControl>
        </Box>
      </Paper>
      <Paper className="content" id="credit-memo">
        <Typography variant="h4">
          Credit Memo: {app.Borrower_Business_Name__c}
        </Typography>
        <Typography variant="h5">Loan and Business Summary</Typography>
        <Paper variant="outlined" className="summary">
          <SummaryRow label="Loan Application #" value={app.Name} />
          <SummaryRow label="Approval Date" value={app.Decision_Date__c} />
          <SummaryRow label="Deal Type" value="Express Loan" />
          <SummaryRow
            label="Legal Type"
            value={legalType !== '' ? legalType : '[Legal Type]'}
            placeholder={legalType === ''}
          />
          <SummaryRow
            label="Loan Size"
            value={toCurrency(app.loan_amount__c)}
          />
          <SummaryRow
            label="Loan Term (years)"
            value={app.loanProduct.Loan_Term_Years__c}
          />
          <SummaryRow label="Loan Type" value={app.Loan_Type__c} />
          <SummaryRow
            label="Interest Rate"
            value={app.loanProduct.APR_Percentage__c + '%'}
          />
          <SummaryRow
            label="Borrower Name (Business)"
            value={app.Borrower_Business_Name__c}
          />
          <SummaryRow
            label="Year Business Established"
            value={app.Business_Established_Year__c}
          />
          <SummaryRow
            label="Business Structure"
            value={app.Borrower_Structure__c}
          />
          <SummaryRow
            label="Business Address"
            value={`${app.Business_Address__c}, ${app.Business_Address_City__c}, ${app.Business_Address_State__c} ${app.Business_Address_Zip_Code__c}`}
          />
          <SummaryRow
            label="Borrower Type"
            value={borrowerType !== '' ? borrowerType : '[Borrower Type]'}
            placeholder={borrowerType === ''}
          />
          <SummaryRow
            label="If OpCo, Does Guarantor Also Own Related Property?"
            value={relatedProperty !== '' ? relatedProperty : '[Yes/No]'}
            placeholder={relatedProperty === ''}
            level={2}
          />

          <SummaryRow
            level={2}
            label="Guarantor Ownership % of Borrower"
            value={
              principalOwnershipPercent === ''
                ? '[Percentage]'
                : principalOwnershipPercent
            }
            placeholder={principalOwnershipPercent === ''}
          />

          <SummaryRow
            label="Guarantor Name"
            value={`${guarantor.First_Name__c} ${guarantor.Last_Name__c}`}
          />
          <SummaryRow
            label="Scorecard Total"
            value={`${pgScore.totalScore}/${pgScore.maxScore} points`}
          />
          <SummaryRow
            label="Borrower Business Description"
            value={
              businessDescription === ''
                ? '[Borrower Business Description]'
                : businessDescription
            }
            placeholder={businessDescription === ''}
          />
        </Paper>

        <Typography variant="h5">Key Borrower and Guarantor Metrics</Typography>

        <Paper variant="outlined" className="summary">
          <SummaryRow
            label={itemConfig[ScorecardItemKeys.PAYDEX_SCORE].label}
            value={renderValue(ScorecardItemKeys.PAYDEX_SCORE)}
          />

          <SummaryRow
            label={`${oneYearAgo} Business EBITDA`}
            value={`${renderValue(ScorecardItemKeys.EBITDA)}`}
          />
          <SummaryRow
            label="Annual Business Debt Obligations"
            value={toCurrency(annualDebtObligation)}
          />
          <SummaryRow
            label="Business Debt Service Coverage Ratio, incl Solar"
            value={renderValue(ScorecardItemKeys.BUSINESS_DSCR)}
          />
          <SummaryRow
            label='Business Property Loan to Value ("LTV")'
            value={`${renderValue(ScorecardItemKeys.PROPERTY_TO_LOAN_VALUE)}`}
          />
          <SummaryRow
            label="Guarantor FICO"
            value={renderValue(ScorecardItemKeys.FICO_SCORE)}
          />
          <SummaryRow
            label="Guarantor Personal Debt to Income"
            value={`${renderValue(ScorecardItemKeys.DEBT_TO_INCOME)}`}
          />
          <SummaryRow
            label="Guarantor Annual Personal Income"
            value={toCurrency(guarantor.Annual_Gross_Income__c)}
          />
          <SummaryRow
            label="Guarantor Occupation"
            value={guarantor.Other_Occupation__c ?? '---'}
          />
        </Paper>

        <Typography variant="h5">Key Solar Economics</Typography>

        <Paper variant="outlined" className="summary">
          <SummaryRow
            label={
              itemConfig[ScorecardItemKeys.SYSTEM_BENEFIT_TO_EXPENSE_RATIO]
                .label
            }
            value={`${renderValue(
              ScorecardItemKeys.SYSTEM_BENEFIT_TO_EXPENSE_RATIO,
            )}x`}
          />
          <SummaryRow
            label={itemConfig[ScorecardItemKeys.DSCR_AVG].label}
            value={`${renderValue(ScorecardItemKeys.DSCR_AVG)}x`}
          />
        </Paper>
        <Typography variant="h5">Special Funding Conditions</Typography>
        <Paper variant="outlined" className="summary">
          <SummaryRow label="None" value="" />
        </Paper>
        <Typography variant="h5">Credit Memo Notes</Typography>

        <Box className="flex-row-start flex-gap-2" alignContent="top">
          <Box width="100%">
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                label="Notes"
                value={creditMemoNotes}
                onChange={(e) => setCreditMemoNotes(e.target.value)}
                multiline
                fullWidth
                minRows={4}
              />
            </FormControl>
          </Box>
        </Box>

        <Paper
          variant="outlined"
          className="content"
          sx={{ mt: '1in', width: PAGE_WIDTH, height: PAGE_HEIGHT }}
        >
          <Typography variant="h4">Exhibit A: Scorecard</Typography>
          <Typography variant="h5">
            Total Score: {`${pgScore.totalScore}/${pgScore.maxScore} points`}
          </Typography>
          <Typography variant="h6">Scoring Legend</Typography>
          <Paper variant="outlined" className="summary">
            <ScoringLegend />
          </Paper>

          <Box
            sx={{
              display: 'flex',
              gap: '2em',
              flexWrap: 'wrap',
              alignContent: 'top',
            }}
          >
            {Object.keys(groups).map((groupKey) => (
              <Box
                key={groupKey}
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                  p: '1rem',
                  width: '30rem',
                }}
              >
                <Typography variant="h5">{toTitle(groupKey)}</Typography>
                <Paper
                  style={{
                    backgroundColor: '#EEF5FF',
                    padding: '1rem',
                    margin: '1em 0em',
                  }}
                >
                  <Typography>
                    Total Score:{' '}
                    {getSectionScoreString(loanAnalysis, scores, groupKey)}
                  </Typography>
                </Paper>
                <Paper variant="outlined" className="summary">
                  {groups[groupKey].map((item) => (
                    <SummaryRow
                      key={item.type}
                      label={itemConfig[item.type].label}
                      value={getScoreString(item, scores)}
                    />
                  ))}
                </Paper>
              </Box>
            ))}
          </Box>
        </Paper>

        <Paper
          variant="outlined"
          className="content"
          sx={{ mt: '0.5in', width: PAGE_WIDTH, height: exhibitBPageHeight }}
        >
          <Typography variant="h4">Exhibit B: Solar Economics</Typography>
          {showExhibitB ? (
            <Box sx={{ height: '100rem' }}>
              <img id="exhibitB-img" src={exhibitBSource} />
            </Box>
          ) : (
            <Button
              variant={'lightOutlined'}
              onClick={() => handleUpload(Exhibit.B)}
              startIcon={<FileUpload />}
            >
              Upload
            </Button>
          )}
        </Paper>

        <Paper
          variant="outlined"
          className="content"
          sx={{ mt: '0.5in', width: PAGE_WIDTH, height: exhibitCPageHeight }}
        >
          <Typography variant="h4">Exhibit C: Other</Typography>
          {showExhibitC ? (
            <Box sx={{ height: '100rem' }}>
              <img id="exhibitC-img" src={exhibitCSource} />
            </Box>
          ) : (
            <Button
              variant={'lightOutlined'}
              onClick={() => handleUpload(Exhibit.C)}
              startIcon={<FileUpload />}
            >
              Upload
            </Button>
          )}
        </Paper>
      </Paper>

      {/* <JSONTree data={{ loanAnalysis, app, scores }} /> */}
    </>
  );
};
