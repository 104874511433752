import React, { forwardRef } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';


export const CheckIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  function CheckIcon(props, ref) {
    return (
      <SvgIcon ref={ref} {...props}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#D9EAE6" />
          <path d="M14 7L8.5 13L6 10.2727" stroke="#2E9C7F" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

      </SvgIcon>
    );
  }
);