import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';


export const FolderClosedIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  function FolderClosedIcon(props, ref) {
    return (
      <SvgIcon ref={ref} {...props}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22 20.8889C22 21.4488 21.7682 21.9858 21.3556 22.3817C20.9431 22.7776 20.3835 23 19.8 23H2.2C1.61652 23 1.05695 22.7776 0.644365 22.3817C0.231785 21.9858 0 21.4488 0 20.8889V6.11111C0 5.55121 0.231785 5.01424 0.644365 4.61833C1.05695 4.22242 1.61652 4 2.2 4H7.7L9.9 7.16667H19.8C20.3835 7.16667 20.9431 7.38909 21.3556 7.785C21.7682 8.18091 22 8.71788 22 9.27778V20.8889Z" fill="#617798"/>
          <path d="M22 19.8889C22 20.4488 21.7682 20.9858 21.3556 21.3817C20.9431 21.7776 20.3835 22 19.8 22H2.2C1.61652 22 1.05695 21.7776 0.644365 21.3817C0.231785 20.9858 0 20.4488 0 19.8889V5.11111C0 4.55121 0.231785 4.01424 0.644365 3.61833C1.05695 3.22242 1.61652 3 2.2 3H7.7L9.9 6.16667H19.8C20.3835 6.16667 20.9431 6.38909 21.3556 6.785C21.7682 7.18091 22 7.71788 22 8.27778V19.8889Z" fill="#869EC2"/>
        </svg>

      </SvgIcon>
    );
  }
);