import { Box, Typography, TextField, Button, Modal } from "@mui/material";
import React from "react";


export const ArchetypeConditionsModal = ({ 
  open, 
  handleCloseModal, 
  selectedRow, 
  conditions, 
  handleConditionChange, 
  addCondition, 
  removeCondition, 
  handleSave 
}) => {

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" mb={2}>
          Edit Conditions - {selectedRow?.document_type.name}
        </Typography>
        <Typography variant="h6" mb={2}>
          {selectedRow?.archetype.description}
        </Typography>

        {conditions.map((condition, index) => (
          <Box key={index} display="flex" gap={2} mb={2} alignItems="center">
            <TextField
              label="Key"
              variant="outlined"
              size="small"
              fullWidth
              value={condition.key}
              onChange={(e) =>
                handleConditionChange(index, 'key', e.target.value)
              }
            />
            <TextField
              label="Value"
              variant="outlined"
              size="small"
              fullWidth
              value={condition.value}
              onChange={(e) =>
                handleConditionChange(index, 'value', e.target.value)
              }
            />
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => removeCondition(index)}
            >
              Remove
            </Button>
          </Box>
        ))}

        <Button variant="outlined" onClick={addCondition} sx={{ mt: 1 }}>
          Add Condition
        </Button>

        <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
