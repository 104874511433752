import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect } from 'react';

const ProjectsMap = () => {
  useEffect(() => {
    const projectsMap = L.map('projects-map').setView([37.8, -96], 4);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors'
    }).addTo(projectsMap);

    function getCircleSize(value) {
      if (value > 1000) return 26;
      else if (value > 800) return 23;
      else if (value > 600) return 20;
      else if (value > 400) return 17;
      else if (value > 200) return 14;
      else if (value > 100) return 11;
      else if (value > 50) return 8;
      else return 5;
    }

    function getCircleIcon(value) {
      const size = getCircleSize(value);
      return L.divIcon({
        className: 'blue-circle',
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
      });
    }

    const locations = [
      { name: "Cactus Land Brewing Company", lat: 29.2898496, lon: -98.25445959999999, value: 46.01 },
      { name: "Shoreline Baptist Church.", lat: 33.693583, lon: -117.9487423, value: 118.8 },
      { name: "LC Energy LLC", lat: 33.1124437, lon: -117.0706656, value: 26.4 },
      { name: "Capko Brothers Farms", lat: 45.964005, lon: -94.5926719, value: 205 },
      { name: "RM Bethany P.V. LLC", lat: 33.4053584, lon: -111.5525939, value: 37.83 },
      { name: "Pleasant Valley Baptist Church of Chico", lat: 39.7946995, lon: -121.8918256, value: 60.48 },
      { name: "Pleasant Valley Baptist Church of Chico", lat: 39.7950276, lon: -121.8917602, value: 17.28 },
      { name: "Biomarker Technologies", lat: 38.3542833, lon: -122.7234731, value: 66.2 },
      { name: "J & J Farms", lat: 39.5823345, lon: -122.195781, value: 47.7 },
      { name: "J & J Farms", lat: 39.5823345, lon: -122.195781, value: 72 },
      { name: "55 N Water St LLC", lat: 40.1578238, lon: -76.3004681, value: 79.2 },
      { name: "Rita Ranch Automotive & Tire", lat: 32.1164138, lon: -110.7714511, value: 26 },
      { name: "Bayside Solar Array LLC", lat: 38.4272027, lon: -122.753778, value: 131.52 },
      { name: "C & J Storage", lat: 38.2466272, lon: -93.3616405, value: 21.9 },
      { name: "MPress Inc.", lat: 29.5902584, lon: -95.2416047, value: 33 },
      { name: "J Synergy LLC", lat: 40.6260594, lon: -73.98809419999999, value: 62.78 },
      { name: "1 Eco Assets LLC", lat: 33.560939, lon: -117.1376728, value: 102.96 },
      { name: "Skydell Contracting", lat: 40.5626762, lon: -74.570105, value: 20.4 },
      { name: "Sarang Korean Presbyterian Church of Bakersfield", lat: 35.3172182, lon: -119.0110321, value: 28.615 },
      { name: "Lauren Moshi L.L.C.", lat: 33.9940785, lon: -118.1352432, value: 61.5 },
      { name: "Medo LLC", lat: 42.0388652, lon: -74.1127841, value: 77 },
      { name: "Palm Harbor Apartments LLC", lat: 29.9135708, lon: -93.9491697, value: 133 },
      { name: "A BETTER TOMORROW CARE CORP", lat: 34.0565207, lon: -118.2874171, value: 72.75 },
      { name: "Kiran Fitness LLC", lat: 35.0591086, lon: -81.6780759, value: 236 },
      { name: "NJ Solar 3", lat: 40.33163810000001, lon: -74.4932522, value: 905.58 },
      { name: "Mandel Tobacco of NJ", lat: 40.2624064, lon: -74.26727869999999, value: 60.3 },
      { name: "Brent and Michele Patterson Family Limited Liability Partnership", lat: 29.5147603, lon: -95.02592949999999, value: 64.93 },
      { name: "Grace Baptist Church of Tremont", lat: 40.5381081, lon: -89.5459769, value: 32 },
      { name: "Rich", lat: 39.1479657, lon: -121.5896638, value: 40.32 },
      { name: "Magnolia Springs Veterinary Center", lat: 42.7186087, lon: -87.9001609, value: 45.12 },
      { name: "Atlantic 3 Power LLC", lat: 41.0887694, lon: -74.52085149999999, value: 891 },
      { name: "Ace Fix It Hardware of Duncansville", lat: 40.4209452, lon: -78.43855959999999, value: 101.3 },
      { name: "21227", lat: 39.2530986, lon: -76.6743729, value: 53.635 },
      { name: "Browne Family Wines", lat: 38.4973686, lon: -122.8300319, value: 91.2 },
      { name: "Patou", lat: 34.323202, lon: -78.7025549, value: 49.28 },
      { name: "Huston Management Group LLC", lat: 29.6687462, lon: -82.3861002, value: 10.44 },
      { name: "Yonder", lat: 35.2183394, lon: -82.245576, value: 10.8 },
      { name: "Figueroa Machining and Manufacturing", lat: 35.660474, lon: -120.7288036, value: 51.6 },
      { name: "Land of Enchantment Properties", lat: 36.0661116, lon: -103.3050021, value: 21 },
      { name: "Scriven Properties", lat: 34.2401687, lon: -116.9112005, value: 39.6 },
      { name: "Dunn Family Farm", lat: 42.2067975, lon: -84.8496358, value: 20.46 },
      { name: "New 555", lat: 33.8046348, lon: -118.1952242, value: 24 },
      { name: "API 56-15 55th Drive", lat: 40.7268321, lon: -73.9141389, value: 279.015 },
      { name: "RM Bethany P.V. LLC", lat: 33.4053584, lon: -111.5525939, value: 55.25 },
      { name: "J&J REAL ESTATE MD", lat: 39.4197492, lon: -77.3964434, value: 398.16 },
      { name: "Corral Arena", lat: 32.680474, lon: -103.1145342, value: 88.3 },
      { name: "Paradise Twin LLC", lat: 38.8484953, lon: -76.8666393, value: 71.28 },
      { name: "Wyoming Avenue Development", lat: 41.401652, lon: -75.66971699999999, value: 1578.86 },
      { name: "KLW Capital LLC", lat: 42.3749341, lon: -70.98550639999999, value: 32.3 },
      { name: "Newtown Car Care Center", lat: 41.4164019, lon: -73.29273859999999, value: 73.2 },
      { name: "Edna Rose Realty", lat: 32.8252626, lon: -96.9928367, value: 76.1 },
      { name: "Worcester Medical One", lat: 42.2631748, lon: -71.81852239999999, value: 29.11 },
      { name: "River Chase Apartments", lat: 42.1797246, lon: -70.7481789, value: 32.64 },
      { name: "AGC Products Inc.", lat: 39.54045110000001, lon: -75.0237621, value: 203.52 },
      { name: "HAM Holdings McAlpine", lat: 35.0803075, lon: -80.82850069999999, value: 56.61 },
      { name: "42 from 21", lat: 40.4083255, lon: -105.1377279, value: 33.67 },
      { name: "M K Stuart", lat: 36.749755, lon: -93.3723316, value: 49.5 },
      { name: "Bogia Engineering Inc.", lat: 40.3313746, lon: -75.9687767, value: 49.92 },
      { name: "Gemssa Enterprises", lat: 29.5844481, lon: -98.6367746, value: 42.24 },
      { name: "Gemssa Enterprises", lat: 27.5849148, lon: -99.44765880000001, value: 88.4 },
      { name: "Aspen Industries", lat: 40.8824433, lon: -75.11365409999999, value: 65.6 },
      { name: "IJA Acquisition", lat: 40.2338523, lon: -74.76876060000001, value: 415.8 },
      { name: "McPherson Land and Livestock Company", lat: 39.5346744, lon: -112.2835549, value: 264.6 },
      { name: "RE CD TIRE SHOP LLC", lat: 29.3481688, lon: -98.5033754, value: 36 },
      { name: "Smiling Sailboat Holdings", lat: 43.89499000000001, lon: -69.9775539, value: 44.955 },
      { name: "API 56-15 55th Drive", lat: 40.726868, lon: -73.9148459, value: 297.705 },
      { name: "ZJW", lat: 34.046031, lon: -117.5427785, value: 34.5 },
      { name: "60185", lat: 41.9002199, lon: -88.2330685, value: 70.52 },
      { name: "Innovative Packaging Solutions", lat: 33.4949803, lon: -117.1573037, value: 63.6 },
      { name: "Star Ridge LLC", lat: 38.4284182, lon: -85.90597849999999, value: 47.08 },
      { name: "Golden Liquor Store", lat: 35.710697, lon: -96.85835519999999, value: 14.45 },
      { name: "Eastman Music Company", lat: 34.0568279, lon: -117.7874243, value: 48.2 },
      { name: "Eastman Music Company", lat: 34.0567736, lon: -117.7881652, value: 58.3 },
      { name: "Banderas Pet Hospital", lat: 33.6274429, lon: -117.6102554, value: 36.4 },
      { name: "Roecam Pennington Partners LLC", lat: 40.3281756, lon: -74.7921061, value: 15.2 },
      { name: "82 Main Street Properties L.L.C.", lat: 40.9339029, lon: -74.1433052, value: 150.3 },
      { name: "Res Rentals Management", lat: 35.6662824, lon: -120.7258252, value: 12.8 },
      { name: "Quality of Life Chiropractic Center LLC", lat: 40.3007848, lon: -74.0352809, value: 18.48 },
      { name: "Chateau Lake San Marcos Homeowners Association", lat: 33.1234236, lon: -117.2103163, value: 133.92 },
      { name: "Lake/Sheridan", lat: 40.7399698, lon: -89.6037672, value: 67.17 },
      { name: "PMLA Solar Arrays LLC", lat: 37.8474189, lon: -120.2193372, value: 34.5 },
      { name: "PMLA Solar Arrays LLC", lat: 37.8495691, lon: -120.2000317, value: 33.1 },
      { name: "Newburgh Algonquin Lanes", lat: 41.5232422, lon: -74.0653993, value: 217.44 },
      { name: "PMLA Solar Arrays LLC", lat: 37.8526641, lon: -120.2190166, value: 201.6 },
      { name: "Evergreen Community Charter School", lat: 35.6031491, lon: -82.4985877, value: 84.9 },
      { name: "WASCO SALES & MARKETING", lat: 34.919897, lon: -120.4644954, value: 63.25 },
      { name: "Staten Island Skating Pavilion", lat: 40.5439026, lon: -74.23235880000001, value: 400.95 },
      { name: "20037", lat: 38.9041752, lon: -77.0539053, value: 18.5 },
      { name: "20017", lat: 38.9359494, lon: -76.9921408, value: 50.88 },
      { name: "Hilliker's Ranch Fresh Eggs", lat: 32.8606824, lon: -116.9528618, value: 72 },
      { name: "Upper Lake Grocery LLC", lat: 39.1625992, lon: -122.9116784, value: 74.205 },
      { name: "Easy Deal Inc", lat: 40.6459341, lon: -74.25627360000001, value: 12.24 },
      { name: "Easy Deal Inc", lat: 40.64606209999999, lon: -74.256585, value: 24.99 },
      { name: "Smilecare Dental", lat: 33.3371951, lon: -111.8743397, value: 22 },
      { name: "Bonnell Motors", lat: 42.45805600000001, lon: -71.16123499999999, value: 347.9 },
      { name: "Templo La Hermosa of The Assemblies of God", lat: 37.4027281, lon: -121.889969, value: 39.6 },
      { name: "Hari Ohm Wine and Liquor Shop Inc.", lat: 39.8733226, lon: -75.04579319999999, value: 99.84 },
      { name: "NJ Solar 2", lat: 39.6805928, lon: -75.48115039999999, value: 287.55 },
      { name: "HD Waste & Recycling", lat: 32.6977648, lon: -96.6424747, value: 60.68 },
      { name: "Aaron Morin Sheet Metal", lat: 42.3861318, lon: -72.6325104, value: 16.32 },
      { name: "Patel's Kingston Lanes Inc.", lat: 41.949652, lon: -73.9898425, value: 245.8 },
      { name: "Church of the Open Arms Inc.", lat: 35.5016438, lon: -97.5479701, value: 61.42 },
      { name: "AJD Investments LLC", lat: 35.1775074, lon: -97.50012319999999, value: 96.2 },
      { name: "Batish Family Medicine", lat: 34.2350729, lon: -78.0009638, value: 18.04 },
      { name: "Govind Properties", lat: 33.8759022, lon: -118.1554906, value: 70.56 },
      { name: "Batish Family Medicine", lat: 34.2353412, lon: -78.00120629999999, value: 12.76 },
      { name: "John Vance Motors Inc.", lat: 35.8234048, lon: -97.4177725, value: 132.68 },
      { name: "20020", lat: 38.8569331, lon: -76.98736889999999, value: 88.4 },
      { name: "Newark SE Solar Array LLC", lat: 41.0149449, lon: -74.1704657, value: 185.12 },
      { name: "TR Solar LLC", lat: 40.0085563, lon: -74.1485993, value: 60.8 },
      { name: "Lakewood Solar LLC", lat: 40.0546788, lon: -74.17673280000001, value: 88 },
      { name: "O1NE MORTGAGE  INC", lat: 34.071007, lon: -117.947594, value: 75.9 },
      { name: "Mavericks Gymnastics", lat: 29.569405, lon: -98.472698, value: 76.56 },
      { name: "Avenstar Pain Specialists", lat: 35.446594, lon: -97.42283599999999, value: 27 },
      { name: "Kawela CBRE PV", lat: 21.084905, lon: -156.95637, value: 374.01 },
      { name: "HJ Farm", lat: 41.7249463, lon: -72.5415421, value: 25.2 },
      { name: "McD 1 FBC Solar LLC", lat: 32.8538447, lon: -117.1870629, value: 54.29 },
      { name: "Coufal's Car Care LLC", lat: 41.7316985, lon: -92.2965724, value: 26.4 },
      { name: "510 Commercial LLC", lat: 34.0542263, lon: -117.5506676, value: 269.18 },
      { name: "1201 Linwood Solar LLC", lat: 39.3480542, lon: -74.580196, value: 634.08 },
      { name: "Kaiser Investment Company", lat: 40.4050198, lon: -75.9285872, value: 412.11 },
      { name: "Shiraz Solar Ventures", lat: 33.1356123, lon: -117.2352378, value: 311.04 },
      { name: "Shiraz Solar Ventures", lat: 33.1407143, lon: -117.2542663, value: 204.12 },
      { name: "Shiraz Solar Ventures", lat: 33.1354907, lon: -117.2345423, value: 173.88 },
      { name: "5 Spoons LLC", lat: 39.7119225, lon: -105.0098633, value: 53.35 },
      { name: "Clinica La Familia (Tempe)", lat: 33.39244, lon: -111.9598686, value: 22.8 },
      { name: "Clinica La Familia", lat: 33.3937365, lon: -111.8179678, value: 35.2 },
      { name: "Clinica La Familia", lat: 33.4634985, lon: -112.0480932, value: 24 },
      { name: "Clinica La Familia", lat: 33.6228378, lon: -112.3402034, value: 55.2 },
      { name: "Clinica La Familia", lat: 33.608186, lon: -112.0136677, value: 41.62 },
      { name: "Kannur Jimmy LLC", lat: 33.8584223, lon: -117.8743444, value: 15.99 },
      { name: "McKenzie Hardware", lat: 38.35171950000001, lon: -121.9923636, value: 110.58 },
      { name: "20002", lat: 38.8840399, lon: -76.9277587, value: 14.99 },
      { name: "20002", lat: 38.9078934, lon: -77.05690729999999, value: 5.27 },
      { name: "20002", lat: 38.89147579999999, lon: -76.9336847, value: 8.51 },
      { name: "20002", lat: 38.9215173, lon: -77.00151029999999, value: 6.885 },
      { name: "20002", lat: 38.9555109, lon: -76.99947929999999, value: 14.18 },
      { name: "20002", lat: 38.9262478, lon: -77.02363390000001, value: 7.29 },
      { name: "20002", lat: 38.905123, lon: -76.9970937, value: 11.34 },
      { name: "20002", lat: 38.8649695, lon: -76.9842209, value: 6.48 },
      { name: "20002", lat: 38.8917925, lon: -76.9210252, value: 7.7 },
      { name: "20002", lat: 38.9516471, lon: -76.9951517, value: 7.7 },
      { name: "20002", lat: 38.9487834, lon: -76.9946763, value: 13.77 },
      { name: "20002", lat: 38.8877192, lon: -76.918986, value: 14.99 },
      { name: "GJC-1953 LLC", lat: 35.4390813, lon: -97.51898399999999, value: 45.24 },
      { name: "2055 Corte Del Nogal", lat: 33.12004599999999, lon: -117.2765077, value: 128.64 },
      { name: "20011", lat: 38.9482038, lon: -77.01675159999999, value: 17.6 },
      { name: "Show Me Rents", lat: 37.6120404, lon: -93.4434497, value: 24.3 },
      { name: "The Barnwood Inn", lat: 37.7658203, lon: -93.55970150000002, value: 47.79 },
      { name: "2101 East Biddle LLC", lat: 39.3041154, lon: -76.58788729999999, value: 140.3 },
      { name: "Star Dance Studio", lat: 34.3786483, lon: -118.5472168, value: 42.68 },
      { name: "20001", lat: 38.9159371, lon: -77.01081239999999, value: 23.6 },
      { name: "Rag and Sons Inc.", lat: 39.96486489999999, lon: -75.02451719999999, value: 20.4 },
      { name: "Dominican Hair Designs LLC", lat: 39.8213034, lon: -75.1504277, value: 14.58 },
      { name: "Lee Ross Thayn", lat: 39.06790489999999, lon: -110.1430746, value: 144 },
      { name: "Lee Ross Thayn", lat: 39.0323667, lon: -110.1639887, value: 270 },
      { name: "Nelson Ranch and Farm", lat: 42.2844509, lon: -111.2871914, value: 92.4 },
      { name: "Nelson Ranch and Farm", lat: 42.31762010000001, lon: -111.3150877, value: 16.5 },
      { name: "Nelson Ranch and Farm", lat: 42.2844471, lon: -111.2871913, value: 99 },
      { name: "Fairfield Auto Service LLC", lat: 39.78430220000001, lon: -77.3738869, value: 18.4 },
      { name: "DMR Richey", lat: 32.237378, lon: -110.9156898, value: 22.8 },
      { name: "802 W. Pinedale Ave", lat: 36.8459069, lon: -119.8071105, value: 55.2 },
      { name: "Valdez Investment Properties", lat: 34.0409792, lon: -118.2032306, value: 14.4 },
      { name: "Unity of Tucson", lat: 32.2745633, lon: -110.8835673, value: 39.36 },
      { name: "Francis Roux DBA Roux's Garage", lat: 42.3946297, lon: -71.6651481, value: 17 },
      { name: "KE-21011", lat: 35.3947693, lon: -118.9482417, value: 160.4 },
      { name: "KE-21005 LLC", lat: 35.3331904, lon: -119.0735389, value: 386.8 },
      { name: "KE-21024 LLC", lat: 37.5206952, lon: -122.337306, value: 166.6 },
      { name: "KE-21029", lat: 37.999306, lon: -121.3154601, value: 1201.1 },
      { name: "KE-21023 LLC", lat: 37.504904, lon: -122.4808611, value: 225.7 },
      { name: "KE-21019 LLC", lat: 37.990681, lon: -121.3208322, value: 366.5 },
      { name: "KE-21021 LLC", lat: 36.603725, lon: -119.459026, value: 289.4 },
      { name: "Utah", lat: 39.0978224, lon: -112.2948841, value: 299.4004907 },
      { name: "Rhode Island", lat: 40.8136634, lon: -96.7025764, value: 12.75 },
      { name: "Pacific Publishing Group Inc.", lat: 36.7376284, lon: -119.7927692, value: 102.125 },
      { name: "Fullmer Brothers LLC", lat: 38.7260267, lon: -112.1284946, value: 403.7 },
      { name: "Fullmer Brothers LLC", lat: 38.9143421, lon: -111.9009354, value: 403.7 },
      { name: "Fullmer Brothers LLC", lat: 38.82871910000001, lon: -112.0068518, value: 403.7 },
      { name: "Templo Nueva Vida", lat: 33.8501832, lon: -117.5496173, value: 16 },
      { name: "Sawyer House LLC", lat: 43.8886164, lon: -70.3282092, value: 19.4 },
      { name: "Knights Grapevine Nursery", lat: 39.06274459999999, lon: -121.5844049, value: 15.12 },
      { name: "NARO ENTERPRISES", lat: 41.3105489, lon: -75.5405633, value: 109 },
      { name: "GreenLife Energy Solutions", lat: 40.7994051, lon: -74.1456092, value: 280.5 },
      { name: "Shadowrock Piter", lat: 43.0286742, lon: -76.0125482, value: 34.44 },
      { name: "AFFINITY BUILDING SYSTEMS", lat: 31.0244387, lon: -83.0699455, value: 115.05 },
      { name: "Sarang Acupuncture", lat: 33.7787855, lon: -118.1847292, value: 25.22 },
    ];

    locations.forEach(location => {
      const icon = getCircleIcon(location.value);
      L.marker([location.lat, location.lon], { icon })
        .addTo(projectsMap)
        .bindPopup(`<b>${location.name}</b><br>Value: ${location.value}`);
    });
  }, []);

  return (
    <div>
      <h3 style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
        Sunstone Credit - 187 Fully Funded Projects - 22.125 Mw
      </h3>
      <div id="projects-map" style={{ height: '800px', width: '100%' }}></div>
      <style>
        {`
          .blue-circle {
            background-color: #8888FF;
            border: 0.5px solid black;
            border-radius: 50%;
            width: 20px;
            height: 20px;
          }
        `}
      </style>
    </div>
  );
};

export default ProjectsMap;