import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { canAccessDataUploadersTab, canAccessFundedProjectsTab, canAccessLoanTab, canAccessSFTPTab, canAccessToolsTab } from '../../helpers/featurePermissionRules';
import { Tab } from './Tab';
import './navStyle.css';

export const NavBar = () => {
  const uri = useLocation();
  const path = uri.pathname;
  const homePath = '/';
  const loansPath = '/loans';
  const sftp = '/files';
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        color: '#555',
      }}
    >
      {canAccessLoanTab() && (
        <Tab name="Loans" dest={loansPath} active={path.includes('/loan')} />
      )}

      {canAccessSFTPTab() && (
        <Tab
          name="SFTP File Browser"
          dest={sftp}
          active={path.includes(sftp)}
        />
      )}

      {canAccessFundedProjectsTab() && (
        <Tab
          name="Funded Projects"
          dest="/funded-projects"
          active={path.includes('/funded-projects')}
        />
      )}
      {canAccessDataUploadersTab() && (
        <Tab
          name="Data Uploaders"
          dest="/underwriting/data-tools/uw-tool-browser"
          active={path.includes('/underwriting/data-tools')}
        />
      )}
      {canAccessToolsTab() && (
        <Tab
          name="Tools"
          dest="/underwriting/tools/acr-calculator"
          active={path.includes('/underwriting/tools')}
        />
      )}
      <Tab
        name="Map"
        dest="/map"
        active={path.includes('/map')}
      />
    </Box>
  );
};
