import React, { forwardRef } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';


export const UwChklstDrawerIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  function UwChklstDrawerIcon(props, ref) {
    return (
      <SvgIcon ref={ref} {...props}>
        <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_569_54559)">
            <path d="M15.9854 1.78162H18.5751C18.8204 1.78162 19.0557 1.68776 19.2291 1.5207C19.4026 1.35364 19.5 1.12706 19.5 0.890808C19.5 0.654551 19.4026 0.42797 19.2291 0.260912C19.0557 0.0938528 18.8204 0 18.5751 0H15.9854C15.7401 0 15.5049 0.0938528 15.3314 0.260912C15.158 0.42797 15.0605 0.654551 15.0605 0.890808C15.0605 1.12706 15.158 1.35364 15.3314 1.5207C15.5049 1.68776 15.7401 1.78162 15.9854 1.78162Z" fill="#333333" />
            <path d="M18.575 11.2188H10.1585C9.91323 11.2188 9.67798 11.3126 9.50453 11.4797C9.33108 11.6467 9.23364 11.8733 9.23364 12.1096C9.23364 12.3458 9.33108 12.5724 9.50453 12.7395C9.67798 12.9065 9.91323 13.0004 10.1585 13.0004H18.575C18.8203 13.0004 19.0555 12.9065 19.229 12.7395C19.4024 12.5724 19.4999 12.3458 19.4999 12.1096C19.4999 11.8733 19.4024 11.6467 19.229 11.4797C19.0555 11.3126 18.8203 11.2188 18.575 11.2188Z" fill="#333333" />
            <path d="M12.7355 0.121428C12.5236 0.00235281 12.2713 -0.0307908 12.0341 0.0292873C11.7969 0.0893654 11.5942 0.237744 11.4705 0.441784L5.31389 10.6014L2.11489 7.15051C1.95117 6.97553 1.72217 6.87013 1.47797 6.85736C1.23377 6.84458 0.994262 6.92548 0.811837 7.08236C0.629411 7.23923 0.518919 7.45931 0.504533 7.69445C0.490147 7.92959 0.573037 8.16064 0.735071 8.33707L4.78145 12.702C4.86824 12.7956 4.97455 12.8704 5.09344 12.9217C5.21232 12.973 5.3411 12.9995 5.47136 12.9996C5.50222 12.9996 5.53338 12.998 5.56385 12.9951C5.70898 12.9808 5.84856 12.9338 5.97128 12.8578C6.09399 12.7818 6.19637 12.679 6.27011 12.5578L13.068 1.33983C13.1914 1.13573 13.2257 0.892812 13.1634 0.664391C13.101 0.43597 12.9471 0.2407 12.7355 0.121428Z" fill="#333333" />
            <path d="M18.575 5.60547H13.0716C12.8263 5.60547 12.5911 5.69932 12.4176 5.86638C12.2442 6.03344 12.1467 6.26002 12.1467 6.49628C12.1467 6.73253 12.2442 6.95911 12.4176 7.12617C12.5911 7.29323 12.8263 7.38708 13.0716 7.38708H18.575C18.8203 7.38708 19.0555 7.29323 19.229 7.12617C19.4024 6.95911 19.4999 6.73253 19.4999 6.49628C19.4999 6.26002 19.4024 6.03344 19.229 5.86638C19.0555 5.69932 18.8203 5.60547 18.575 5.60547Z" fill="#333333" />
          </g>
          <defs>
            <clipPath id="clip0_569_54559">
              <rect width="19" height="13" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>
      </SvgIcon>
    );
  }
);