import axios from "axios";

/**
 * This module contains the service functions related to the Required Documents. 
 * Some are used within the Context, others are used directly in the components.
 */

export const getRelatedParties = async (appId: string) => {
  return await axios
    .get('/api/v1/loans/related-parties/' + appId)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};
