import { useEffect, useState } from 'react';

export const useWatchHeight = (elementId: string, defaultHeight = 0, listeners: any[] = []) => {
  const [height, setHeight] = useState(document.getElementById(elementId)?.offsetHeight ?? defaultHeight);

  const updateHeight = () => {
    setHeight(document.getElementById(elementId)?.offsetHeight ?? defaultHeight)
  }

  useEffect(() => {
    setTimeout(() => updateHeight(), 0);

    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    }
    
  }, [document.getElementById(elementId)?.offsetHeight, ...listeners]);

  return height;
};

