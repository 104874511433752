import React from 'react';
import { Box, Button, Typography, Modal, CircularProgress } from '@mui/material';

interface DeleteReqDocModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  requiredDocName: string;
  isDeleting: boolean;
}

const DeleteReqDocModal: React.FC<DeleteReqDocModalProps> = ({
  open,
  onClose,
  onConfirm,
  requiredDocName,
  isDeleting,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography 
            sx={{
                fontWeight: 400,
                fontFamily: 'Lato, sans-serif',
                fontSize: '24px',
            }}
        >
          Delete Required Document
        </Typography>
        <Typography 
            sx={{ 
                mt: 2,
                fontWeight: 400,
                fontFamily: 'Lato, sans-serif',
                fontSize: '14px',
                color: '#333333',
            }}>
          Are you sure you want to delete the required document <b>"{requiredDocName}"</b>?
        </Typography>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}> 
          <Button variant="outlined" onClick={onClose} disabled={isDeleting}>
            Cancel
          </Button>
          {isDeleting ? (
            <CircularProgress size={20} />
          ) : (
            <Button variant="contained" color="primary" onClick={onConfirm}>
              Delete
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteReqDocModal;