import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { xlAcceptExt } from '../../assets/const';
import { Loading } from '../Loading';
import './uwStyle.css';

export const InstallerScorecardUpload = () => {
  const okButton = <Button onClick={() => setShowDialog(false)}>Ok</Button>;
  const [dialogContent, setDialogContent] = useState(<></>);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Error');
  const [dialogActions, setDialogActions] = useState(okButton);

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('', file, file.name);
    try {
      const result = await axios.post(
        '/api/v1/uw/installer-scorecard/upload',
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      );
      setDialogTitle('Succeeded');
      setDialogContent(
        <DialogContentText>
          {result.data?.message ?? 'Record created successfully'}
        </DialogContentText>,
      );
      setDialogActions(okButton);
      setShowDialog(true);
    } catch (error: any) {
      setDialogTitle('Failed to upload or parse the file');
      setDialogContent(
        <Typography variant="body1">
          {error.response.data.message ?? 'Failed to parse the file'}
        </Typography>,
      );
      setDialogActions(okButton);
      setShowDialog(true);
    }
  };

  const handleUpload = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = xlAcceptExt;

    input.click();
    input.onchange = (_) => {
      setDialogTitle('🚨 Head up! 🚨');
      setDialogContent(
        <Typography variant="body1">
          If there is existing installer scorecard data, it will be overwritten. Please ensure your file contains the most accurate information for reporting.
        </Typography>,
      );
      setDialogActions(
        <>
          <Button onClick={() => setShowDialog(false)} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDialogActions(<></>);
              setDialogTitle('Uploading');
              setDialogContent(<Loading />);
              if (input.files) uploadFile(input.files[0]);
            }}
            variant="contained"
            color="error"
          >
            Proceed
          </Button>
        </>,
      );
      setShowDialog(true);
    };
  };
  return (
    <Paper
      sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
    >
      <Box className="content">
        <Typography variant="h5">Installer scorecard uploader</Typography>
        <Box className={'uwTool'}>
          <Typography variant="h6">Installer scorecard uploader</Typography>
          <Button onClick={handleUpload} variant="contained">
            Upload
          </Button>
        </Box>
        {showDialog && (
          <Dialog open={showDialog}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>{dialogContent}</DialogContent>
            <DialogActions>{dialogActions}</DialogActions>
          </Dialog>
        )}
      </Box>
    </Paper>
  );
};
