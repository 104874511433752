import React, { useEffect, useMemo, useRef, useState } from 'react';

import { SFLoanApplication } from '@backend/types/Loan';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import merge from 'lodash/merge';
import { useOutletContext } from 'react-router-dom';
import { useRequiredDocuments } from '../contexts/RequiredDocumentsContext';
import { getUserId } from '../helpers/auth';
import { NonUniqueDocumentTypes, RequiredDocumentApprovalStatus, RequiredDocumentUserGroups } from '../helpers/constants';
import {
  applicationWorkflowSteps,
  DocumentGroups,
  documentTypeSystemNameMapping,
  WorkflowStep,
  workflowStepToEnumMap,
} from '../helpers/documentTypeMappings';
import { RequiredDocument as RequiredDocumentType, TemplateDocumentType, TemplateNames } from '../helpers/types';

interface UiStateValue extends TemplateDocumentType {
  selected: boolean;
  requiredStep: WorkflowStep;
  isEditingInternalNotes?: boolean;
  isEditingExternalNotes?: boolean;
}

const BulkCreateDocumentsModal = ({
  open,
  onClose,
}) => {

  const { state: { requiredDocuments }, getRequiredDocuments } = useRequiredDocuments();
  const [templateDocumentTypes, setTemplateDocumentTypes] = useState<TemplateDocumentType[]>([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('selectFilter');
  const [search, setSearch] = useState('');
  const [uiState, setUiState] = useState<Record<number, UiStateValue>>({});
  const [tempNotes, setTempNotes] = useState({});
  const [alertMessage, setAlertMessage] = useState<{ type: AlertColor, message: string }>({ type: 'error', message: '' });

  const app = useOutletContext() as SFLoanApplication;
  const userId = getUserId();

  const textFieldRefs = useRef<Record<number, HTMLElement | null>>({});

  // This function checks if a required document with a unique document type already exists for the given document type id and workflow step
  const existingUniqueRequiredDocument = (documentTypeId: string, workflowStep: WorkflowStep) => {
    return requiredDocuments.some((doc: RequiredDocumentType) => !NonUniqueDocumentTypes.includes(doc.document_type.name) && doc.document_type_id === documentTypeId && doc.required_for_step === workflowStepToEnumMap[workflowStep]);
  }

  const fetchTemplates = async () => {
    setLoadingTemplates(true);
    try {
      const templateResponse = await axios.get('/api/v1/loans/templates', {
        params: {
          templateName: TemplateNames.DOCUMENT_TYPES,
        },
      });
      const templateId = templateResponse.data[0].id;

      const response = await axios.get('/api/v1/loans/template-document-types', {
        params: {
          template_id: templateId,
        },
      });
      setTemplateDocumentTypes(response.data);

      const initialState = response.data.reduce((acc, template) => {
        acc[template.id] = {
          selected: false,
          requiredStep: 'placeholder',
          isEditingInternalNote: false,
          isEditingExternalNote: false,
          internal_notes: template.internal_notes,
          external_notes: template.external_notes,
          ...template
        };
        return acc;
      }, {});
      setUiState(initialState);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingTemplates(false);
    }
  };

  useEffect(() => {
    fetchTemplates()
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleUiStateChange = (id, field, value) => {
    setAlertMessage({ type: 'info', message: '' });
    const update = { [id]: { [field]: value || '' } }
    setUiState((prev) => merge(prev, update));
  };

  const handleSaveNotes = (id, field) => {
    const update = { [id]: { [field]: tempNotes[id][field] } };
    setUiState((prev) => merge(prev, update));
    setTempNotes((prev) => merge(prev, { [id]: { [field]: '' } }));
  };

  const handleCancelEdit = (id, field) => {
    const update = { [id]: { [field]: uiState[id][field] } }
    setTempNotes((prev) => merge(prev, update));
  };

  // We are allowing filtering by Document Group, and searching by Internal Notes, External Notes, and Document Type.
  const filteredTemplates = useMemo(() => {
    return templateDocumentTypes.filter((template) => {
      const documentGroup = documentTypeSystemNameMapping[uiState[template.id]?.document_type?.system_name];
      return (
        (filter === 'selectFilter' || filter === 'All' || documentGroup === filter) && (
          (!search || uiState[template.id]?.internal_notes?.toLowerCase().includes(search.toLowerCase())) ||
          (!search || uiState[template.id]?.external_notes?.toLowerCase().includes(search.toLowerCase())) ||
          (!search || uiState[template.id]?.document_type?.name?.toLowerCase().includes(search.toLowerCase()))
        )
      );
    })
      .sort((a, b) => {
        const nameA = a.template?.name.toLowerCase() || '';
        const nameB = b.template?.name.toLowerCase() || '';
        return nameA.localeCompare(nameB);
      });
  }, [templateDocumentTypes, filter, search]);

  const handleClose = () => {
    onClose();
    setFilter('selectFilter');
    setSearch('');
    setAlertMessage({ type: 'info', message: '' });
    setUiState((prev) => {
      const newState = { ...prev };
      Object.keys(newState).forEach((key) => {
        newState[key].selected = false
        newState[key].requiredStep = 'placeholder'
        newState[key].internal_notes = ''
        newState[key].external_notes = ''
      });
      return newState;
    });
  }

  const handleClickOutside = (event: MouseEvent) => {
    // This is to handle the case where the user clicks outside the text field so that changes are saved
    Object.entries(textFieldRefs.current).forEach(([id, ref]) => {
      if (ref && !ref.contains(event.target as Node)) {
        if (uiState[id]?.isEditingInternalNotes) {
          if (!tempNotes[id]) {
            setTempNotes((prev) => ({
              ...prev,
              [id]: { internal_notes: uiState[id]?.internal_notes || '' },
            }));
          }
          handleSaveNotes(id, 'internal_notes');
          handleUiStateChange(id, 'isEditingInternalNotes', false);
        }
        if (uiState[id]?.isEditingExternalNotes) {
          if (!tempNotes[id]) {
            setTempNotes((prev) => ({
              ...prev,
              [id]: { external_notes: uiState[id]?.external_notes || '' },
            }));
          }
          handleSaveNotes(id, 'external_notes');
          handleUiStateChange(id, 'isEditingExternalNotes', false);
        }
      }
    });
  };

  const handleRequiredDocsBulkCreation = async () => {
    setAlertMessage({ type: 'success', message: '' });
    const appId = app.Id;

    const selectedTemplates = Object.values(uiState)
      .filter((state) => state.selected)
      .map((state) => ({
        requirement_name: state.document_type?.name,
        document_type_name: state.document_type?.name,
        internal_notes: state.internal_notes,
        external_notes: state.external_notes,
        parent_id: appId,
        document_type: {
          connect: { id: state.document_type?.id }
        },
        required_for_step: workflowStepToEnumMap[state.requiredStep],
        is_required: true,
        owner: {
          connect: { id: userId },
        },
        created_by_user: {
          connect: { id: userId },
        },
        approval_status: RequiredDocumentApprovalStatus.AWAITING_UPLOAD,
        document_approval_source: 'UPLOAD',
        can_upload: RequiredDocumentUserGroups.ALL,
        can_view: RequiredDocumentUserGroups.ALL,
      }));

    // Check if any selected template does not have a required step
    const hasMissingRequiredStep = selectedTemplates.some(template => template.required_for_step === 'placeholder' || !template.required_for_step);

    if (hasMissingRequiredStep) {
      setAlertMessage({ type: 'error', message: 'Please select a required step for all selected templates.' });
      return;
    }

    if (selectedTemplates.length === 0) {
      setAlertMessage({ type: 'error', message: 'Please select at least one template to create documents.' });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post('/api/v1/loans/required-documents/bulk', selectedTemplates);

      setAlertMessage({
        type: 'success',
        message: `${response.data.createdDocuments.length} documents created successfully.`,
      });

      // Reset the selected state for all templates, clean fields and close modal
      handleClose()
    } catch (error: any) {
      setAlertMessage({ type: 'error', message: error.response.data.message || 'Failed to create documents. Please try again.' });
      console.error('Error during bulk creation:', error);
    } finally {
      setLoading(false);
      await getRequiredDocuments(appId);
    }
  };

  useEffect(() => {
    // This is to handle the case where the user clicks outside the text field so that changes are saved
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [uiState, tempNotes]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle
        sx={{
          fontFamily: 'Lato, sans-serif',
          p: '2rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Lato, sans-serif',
            fontSize: '24px',
          }}
        >
          Create Document(s) from Template
        </Typography>
        <IconButton onClick={handleClose}
          sx={{
            transform: 'scale(1.5)',
            cursor: 'pointer',
          }}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>

      {alertMessage.message && (
        <Box sx={{ px: '2rem', pb: '1rem', display: 'flex', justifyContent: 'center' }}>
          <Alert severity={alertMessage.type}>{alertMessage.message}</Alert>
        </Box>
      )}

      <DialogContent
        sx={{
          fontFamily: 'Lato, sans-serif',
          p: 0,
        }}
      >
        {(loadingTemplates || loading) ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
            <Typography>{loadingTemplates ? 'Loading templates...' : 'Creating documents...'}</Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                px: '2rem',
                pt: '2rem',
              }}
            >
              <Box display="flex" gap={2} mb={3} alignItems="center">
                <Box
                  sx={{
                    display: 'flex',
                    width: '40%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontFamily: 'Lato, sans-serif',
                    }}
                  >Filter by:</Typography>
                  <TextField
                    select
                    value={filter}
                    onChange={handleFilterChange}
                    sx={{
                      width: '85%',
                    }}
                  >
                    <MenuItem value={'selectFilter'} disabled sx={{ color: 'gray' }}>
                      --Filter by Document Group--
                    </MenuItem>
                    <MenuItem value={'All'}>
                      All
                    </MenuItem>
                    {Object.values(DocumentGroups).map((group) => (
                      <MenuItem key={group} value={group}>
                        {group}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <TextField
                  label="Search"
                  value={search}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: <SearchIcon />,
                  }}
                  sx={{
                    width: '35%',
                  }}
                />
              </Box>
              <Grid container spacing={2} sx={{ fontWeight: 700, my: 1 }}>
                <Grid item xs={1}>
                  <Typography> </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={700}>Document Type</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={700}>Internal Notes</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={700}>Notes to Borrower</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontWeight={700}>Required For Step</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              sx={{
                fontFamily: 'Lato, sans-serif',
                backgroundColor: '#F8FAFD',
                padding: '2rem',
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, fontFamily: 'Lato, sans-serif' }}>
                {filter !== 'selectFilter' ? `${filter} Templates` : 'All Templates'}
              </Typography>

              {filteredTemplates.length > 0 ? filteredTemplates.map((template) => (
                <Grid container alignItems="center" key={template.id}
                  sx={{
                    backgroundColor: uiState[template.id]?.selected ? '#E8F3FF' : '#FFFFFF',
                    padding: '.5rem',
                    borderRadius: '10px',
                    border: `1px solid ${uiState[template.id]?.selected ? '#2196F3' : '#DDDFE8'}`,
                    height: '70px',
                  }}
                >
                  <Grid item xs={1}>
                    <Checkbox
                      checked={uiState[template.id]?.selected || false}
                      onChange={(e) =>
                        handleUiStateChange(template.id, 'selected', e.target.checked)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '14px'
                      }}
                    >
                      {template.document_type?.name || 'Document Type'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} height="100%" ref={(el) => (textFieldRefs.current[template.id] = el)}>
                    <Box display="flex" height="100%" gap={1}
                      sx={{
                        width: 1,
                        alignItems: uiState[template.id]?.internal_notes ? 'flex-start' : 'center',
                        textAlign: uiState[template.id]?.internal_notes ? 'left' : 'center',
                      }}
                    >
                      {uiState[template.id]?.isEditingInternalNotes ? (
                        <>
                          <Box
                            display="flex"
                            sx={{
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              margin: 0,
                              padding: 0,
                              gap: 0,
                            }}
                          >
                            <IconButton
                              size="small"
                              color={'info'}
                              sx={{
                                backgroundColor: '#ECECEC',
                                borderRadius: '4px',
                                transform: 'scale(0.75) translateY(-6px)',
                              }}
                              onClick={() => {
                                handleSaveNotes(template.id, 'internal_notes')
                                handleUiStateChange(template.id, 'isEditingInternalNotes', false)
                              }}
                            >
                              <DoneRoundedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleCancelEdit(template.id, 'internal_notes')
                                handleUiStateChange(template.id, 'isEditingInternalNotes', false)
                              }}
                              color={'info'}
                              sx={{
                                backgroundColor: '#ECECEC',
                                borderRadius: '4px',
                                transform: 'scale(0.75) translateY(-6px)',
                              }}
                            >
                              <CloseRoundedIcon fontSize="small" />
                            </IconButton>
                          </Box>
                          <TextField
                            value={tempNotes[template.id]?.internal_notes}
                            onChange={(e) => setTempNotes((prev) => ({
                              ...prev,
                              [template.id]: { ...prev[template.id], internal_notes: e.target.value },
                            }))}
                            size="small"
                            autoFocus
                            multiline
                            rows={2}
                            sx={{
                              fontSize: '12px',
                              fontWeight: 400,
                              backgroundColor: '#FFFFFF',
                              borderRadius: '4px',
                              width: .8,
                              height: 1,
                              '& .MuiInputBase-input': {
                                fontSize: '12px',
                                fontWeight: 400,
                                py: 0,
                                height: 1,
                              },
                              '& .MuiOutlinedInput-root': {
                                py: 0,
                                width: 1,
                                height: 1,
                              },
                              '&.MuiTextField-root': {
                                height: 1,
                              },
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {uiState[template.id]?.internal_notes && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleUiStateChange(template.id, 'isEditingInternalNotes', true)
                                setTempNotes((prev) => ({
                                  ...prev,
                                  [template.id]: { ...prev[template.id], internal_notes: uiState[template.id]?.internal_notes },
                                }))
                              }}
                              color={'info'}
                              sx={{
                                backgroundColor: '#ECECEC',
                                borderRadius: '4px',
                                transform: 'scale(0.75)',
                              }}
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                          )}
                          <Typography
                            sx={{
                              fontWeight: uiState[template.id]?.internal_notes ? 400 : 700,
                              fontSize: '12px',
                              textDecoration: uiState[template.id]?.internal_notes ? 'none' : 'underline',
                              cursor: uiState[template.id]?.internal_notes ? 'default' : 'pointer',
                              maxHeight: 1,
                              overflowY: 'auto',
                              width: .85,
                              height: 1,
                              textAlign: uiState[template.id]?.internal_notes ? 'left' : 'center',
                              pt: uiState[template.id]?.internal_notes ? 0 : 2,
                            }}
                            onClick={() => {
                              if (!uiState[template.id]?.internal_notes) {
                                handleUiStateChange(template.id, 'isEditingInternalNotes', true);
                              }
                            }}
                          >
                            {uiState[template.id]?.internal_notes || '+ Add Note'}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={3} height="100%" ref={(el) => (textFieldRefs.current[template.id] = el)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Box display="flex" alignItems="flex-start" height="100%" gap={1} sx={{ width: 1 }}>
                      {uiState[template.id]?.isEditingExternalNotes ? (
                        <>
                          <Box
                            display="flex"
                            sx={{
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              margin: 0,
                              padding: 0,
                              gap: 0,
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleSaveNotes(template.id, 'external_notes')
                                handleUiStateChange(template.id, 'isEditingExternalNotes', false)
                              }}
                              color={'info'}
                              sx={{
                                backgroundColor: '#ECECEC',
                                borderRadius: '4px',
                                transform: 'scale(0.75) translateY(-6px)',
                              }}
                            >
                              <DoneRoundedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleCancelEdit(template.id, 'external_notes')
                                handleUiStateChange(template.id, 'isEditingExternalNotes', false)
                              }}
                              color={'info'}
                              sx={{
                                backgroundColor: '#ECECEC',
                                borderRadius: '4px',
                                transform: 'scale(0.75) translateY(-8px)',
                              }}
                            >
                              <CloseRoundedIcon fontSize="small" />
                            </IconButton>
                          </Box>
                          <TextField
                            value={tempNotes[template.id]?.external_notes}
                            onChange={(e) => setTempNotes((prev) => ({
                              ...prev,
                              [template.id]: { ...prev[template.id], external_notes: e.target.value },
                            }))}
                            size="small"
                            autoFocus
                            multiline
                            rows={2}
                            sx={{
                              fontSize: '12px',
                              fontWeight: 400,
                              backgroundColor: '#FFFFFF',
                              borderRadius: '4px',
                              width: .8,
                              '& .MuiInputBase-input': {
                                fontSize: '12px',
                                fontWeight: 400,
                                py: 0,
                                height: 1,
                              },
                              '& .MuiOutlinedInput-root': {
                                py: 0,
                                width: 1,
                                height: 1,
                              },
                              '&.MuiTextField-root': {
                                height: 1,
                              },
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {uiState[template.id]?.external_notes && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleUiStateChange(template.id, 'isEditingExternalNotes', true)
                                setTempNotes((prev) => ({
                                  ...prev,
                                  [template.id]: { ...prev[template.id], external_notes: uiState[template.id]?.external_notes },
                                }))
                              }}
                              color={'info'}
                              sx={{
                                backgroundColor: '#ECECEC',
                                borderRadius: '4px',
                                transform: 'scale(0.75)',

                              }}
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                          )}
                          <Typography
                            sx={{
                              fontWeight: uiState[template.id]?.external_notes ? 400 : 700,
                              fontSize: '12px',
                              textDecoration: uiState[template.id]?.external_notes ? 'none' : 'underline',
                              cursor: uiState[template.id]?.external_notes ? 'default' : 'pointer',
                              maxHeight: 1,
                              overflowY: 'auto',
                              width: .85,
                              height: 1,
                              textAlign: uiState[template.id]?.external_notes ? 'left' : 'center',
                              pt: uiState[template.id]?.external_notes ? 0 : 2,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!uiState[template.id]?.external_notes) {
                                handleUiStateChange(template.id, 'isEditingExternalNotes', true);
                              }
                            }}
                          >
                            {uiState[template.id]?.external_notes || '+ Add Note'}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={2} height="100%" display="flex" alignItems="center">
                    <Select
                      value={uiState[template.id]?.requiredStep || 'placeholder'}
                      onChange={(e) =>
                        handleUiStateChange(template.id, 'requiredStep', e.target.value)
                      }
                      fullWidth
                      sx={{
                        '& .MuiSelect-select': {
                          py: .7,
                        }
                      }}
                    >
                      <MenuItem value={"placeholder"} disabled sx={{ color: 'gray' }}>
                        --Select Workflow Step--
                      </MenuItem>
                      {Object.values(applicationWorkflowSteps).map((step) => (
                        <MenuItem key={step} value={step} disabled={existingUniqueRequiredDocument(template.document_type_id, step)}>
                          {step}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              )) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Alert severity="info">No templates found. Please create a template first.</Alert>
                </Box>
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          padding: '2rem',
        }}
      >
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={loading || loadingTemplates || filteredTemplates.length === 0} onClick={handleRequiredDocsBulkCreation}>
          Add Selected Requirements
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BulkCreateDocumentsModal
