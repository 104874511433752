import { FraudItemStatus } from '@backend/types/FraudScorecard';
import { Cancel, CheckCircle, Error, Warning } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

interface FraudScorecardStatusChipProps {
  value: FraudItemStatus;
}

const FraudScorecardStatusChip: React.FC<FraudScorecardStatusChipProps> = ({ value }) => {
  let className = '';
  let icon: React.ReactNode = <Cancel />;

  if (value === FraudItemStatus.NO_VALUE) {
    className = 'data-chip-grey';
    icon = <Cancel />;
  } else if (value === FraudItemStatus.KNOCKOUT) {
    className = 'data-chip-low';
    icon = <Error />;
  } else if (value === FraudItemStatus.WARNING) {
    className = 'data-chip-okay';
    icon = <Warning />;
  } else if (value === FraudItemStatus.PASSED) {
    className = 'data-chip-high';
    icon = <CheckCircle />;
  } else {
    return null;
  }

  return (
    <Box maxWidth={200} className={`data-chip ${className}`}>
      {value === FraudItemStatus.NO_VALUE ? (
        <Box className="flex-row-end flex-gap-0_5">
          <Cancel />
          <Typography>{FraudItemStatus.NO_VALUE}</Typography>
        </Box>
      ) : value === FraudItemStatus.KNOCKOUT ? (
        <Box className="flex-row-end flex-gap-0_5">
          <Error />
          <Typography>{FraudItemStatus.KNOCKOUT}</Typography>
        </Box>
      ) : value === FraudItemStatus.WARNING ? (
        <Box className="flex-row-end flex-gap-0_5">
          <Warning />
          <Typography>{FraudItemStatus.WARNING}</Typography>
        </Box>
      ) : value === FraudItemStatus.PASSED ? (
        <Box className="flex-row-end flex-gap-0_5">
          <CheckCircle />
          <Typography>{FraudItemStatus.PASSED}</Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default FraudScorecardStatusChip;