import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TemplateNames } from '../helpers/types';
import { useRequiredDocuments } from '../contexts/RequiredDocumentsContext';
import { useOutletContext } from 'react-router-dom';
import { SFLoanApplication } from '@backend/types/Loan';

const CreateNewTemplateModal = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { state: { documentTypes }, getRequiredDocuments } = useRequiredDocuments();
  const app = useOutletContext() as SFLoanApplication;
  const appId = app.Id;
  const [templateDocumentType, setTemplateDocumentType] = useState({
    document_type_id: '',
    internal_notes: '',
    external_notes: '',
    template_id: '',
  });
  
  const handleChange = (event) => {
    const { name, value } = event.target;

    setTemplateDocumentType((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const templateResponse = await axios.get('/api/v1/loans/templates', {
      params: {
        templateName: TemplateNames.DOCUMENT_TYPES,
      },
    });

    const templateId = templateResponse.data[0].id;

    const payload = {
      document_type_id: templateDocumentType.document_type_id || '',
      internal_notes: templateDocumentType.internal_notes || '',
      external_notes: templateDocumentType.external_notes || '',
      template_id: templateId,
    };

    onSubmit(payload)
      .then(async () => {
        onClose();
        await getRequiredDocuments(appId);
        window.location.reload(); // Need to reload the page as we are not using global state management
      })
      .catch((err) => {
        console.error(err.message);
      })
  };

  return (
    <Dialog open={open} onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          fontFamily: 'Lato, sans-serif',
          p: '2rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography 
          sx={{
            fontFamily: 'Lato, sans-serif',
            fontSize: '24px',
          }}
        >
          Create New Template
        </Typography>
        <IconButton onClick={onClose} 
        sx={{
          transform: 'scale(1.5)',
          cursor: 'pointer',
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <DialogContent>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Document Type"
                name="document_type_id"
                onChange={handleChange}
                select
                required
                value={templateDocumentType.document_type_id}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {Object.keys(documentTypes).map((key) => (
                  <MenuItem value={key} key={key}>
                    {documentTypes[key].name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Internal Notes"
                name="internal_notes"
                onChange={handleChange}
                variant="outlined"
                multiline
                rows={2}
                value={templateDocumentType.internal_notes}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="External Notes"
                name="external_notes"
                onChange={handleChange}
                variant="outlined"
                multiline
                rows={2}
                value={templateDocumentType.external_notes}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            color="inherit"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateNewTemplateModal;