import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import skynetLogo from '../../assets/img/ic-skynet-small.png';
import { useRequiredDocuments } from '../../contexts/RequiredDocumentsContext';
import { getUserId, isAdmin } from '../../helpers/auth';
import { RequiredDocumentApprovalStatus } from '../../helpers/constants';
import { niceDateTime, standardToShortDate } from '../../helpers/converters';
import { documentStatusPickerChoices } from '../../helpers/documentTypeMappings';
import { canRWFiles } from '../../helpers/featurePermissionRules';
import { capitalizeString, shortenFilename } from '../../helpers/helpers';
import { FileType, RequiredDocument as RequiredDocumentType, UserFileVerification } from '../../helpers/types';
import { archiveFile, updateRequiredDocument } from '../../services/requiredDocumentsService';
import { BetterAccordion } from '../BetterAccordion';
import { ShowIf } from '../ConditionalRender/ShowIf';
import ConfirmationModal from '../ConfirmationModal';
import { CustomArrowDown } from '../CustomArrowDown';
import DeleteReqDocModal from '../DeleteReqDocModal';
import FileHistory from '../FileHistory';
import { EditIcon } from '../Icons/EditIcon';
import { NoFileIcon } from '../Icons/NoFileIcon';
import { SingleFileIcon } from '../Icons/SingleFileIcon';
import { TrashIcon } from '../Icons/TrashIcon';
import { UploadIcon } from '../Icons/UploadIcon';
import MoveFileModal from '../MoveFileModal';
import RequiredDocumentModal from '../RequiredDocumentModal';
import { NotesSection } from './NotesSection';
import { NotesTooltipInfo } from './NotesTooltipInfo';


export const RequiredDocument = ({
  applicationId,
  document: initialDocumentState,
  users,
  onChange,
  isSubGroup = false,
  isExpanded = false,
}) => {
  const {
    state: {
      selectedFile,
      requiredDocuments,
      selectedRequiredDocument
    },
    getRequiredDocuments,
    addFileToPreview,
    setSelectedFile,
    setSelectedRequiredDocument,
    deleteRequiredDocument,
    deleteFileAction
  } = useRequiredDocuments();

  const sunstoneDocumentId = initialDocumentState.sunstone_document_id;
  const [documentState, setDocumentState] = useState(initialDocumentState);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [fileToArchive, setFileToArchive] = useState();
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isLoading, setIsLoading] = useState({
    analyze: false,
    upload: false,
    delete: false,
    deleteAction: '',
  });
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [fileToMove, setFileToMove] = useState<FileType | null>(null);
  const [anchorEls, setAnchorEls] = useState<Record<string, HTMLElement | null>>({})
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [deleteReqDocModalOpen, setDeleteReqDocModalOpen] = useState<boolean>(false);
  const [docToDelete, setDocToDelete] = useState<string>('');

  const isFileSentToBank = documentState.approval_status === RequiredDocumentApprovalStatus.SENT_TO_BANK;
  const isFileVerified = documentState.approval_status === RequiredDocumentApprovalStatus.VERIFIED;
  const isFileVerifiedOrSentToBank = isFileVerified || isFileSentToBank;
  const excludedStatuses = [
    RequiredDocumentApprovalStatus.VERIFIED,
    RequiredDocumentApprovalStatus.SENT_TO_BANK
  ];

  const userId = getUserId();

  const theme = useTheme();
  const showFileReadWriteButtons = canRWFiles();

  const handleStatusChange = async (e) => {
    setUpdatingStatus(true);
    const { name, value } = e.target;
    const updates = { [name]: value };
    const response = await updateRequiredDocument(
      updates,
      documentState.id,
      sunstoneDocumentId,
      'status',
    );
    const updatedDoc: RequiredDocumentType = response.data;
    setDocumentState((prev) => ({
      ...prev,
      ...updatedDoc,
    }));
    await getRequiredDocuments(applicationId);
    setUpdatingStatus(false);
  };

  const handleUpload = useCallback(() => {
    // Handles the file upload process for the selected required document. 
    // If said document already has a file, it will create a new required document based on the existing document and upload the file to it.
    setUploadingFile(true);
    let input = document.createElement('input');
    input.type = 'file';
    input.multiple = false;

    input.click();
    input.onchange = async (_) => {
      setIsLoading(prev => ({ ...prev, upload: true }));
      try {
        const files = input.files ?? [];
        const requests: Promise<any>[] = [];
        for (const file of files) {
          const formData = new FormData();
          formData.append('', file, file.name);
          formData.append('requiredDocumentId', documentState.id);
          formData.append('parentId', documentState.parent_id);
          formData.append('isCurrent', 'true');

          requests.push(
            axios.post(`/api/v1/loans/file`, formData, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            }),
          );
        }

        axios
          .all(requests)
          .then((result) => {
            getRequiredDocuments(applicationId);

            setUploadingFile(false);
            setIsLoading(prev => ({ ...prev, upload: false }));
          })
          .catch((err) => {
            console.error(err);
            setUploadingFile(false);
            setIsLoading(prev => ({ ...prev, upload: false }));
          });
      } catch (err) {
        console.error(err);
        setUploadingFile(false);
        setIsLoading(prev => ({ ...prev, upload: false }));
      }
    }
  }, []);

  const handleArchive = async (fileId) => {
    await archiveFile(fileId);
    setDocumentState((prev) => ({
      ...prev,
      files: prev.files.filter((file) => file.id !== fileId),
    }));
    setArchiveModalOpen(false);
  };

  const handleSubmit = async ({ files, ...updates }, id) => {
    const response = await updateRequiredDocument(
      updates,
      documentState.id,
      sunstoneDocumentId,
      'config',
    );
    const updatedDoc: RequiredDocumentType = response.data;
    setDocumentState((prev) => ({
      ...prev,
      ...updatedDoc,
    }));
    setEditModalOpen(false);
    await getRequiredDocuments(documentState.parent_id);
  };

  const handleAnalyzeOracleAnalysis = async (fileId) => {
    setIsLoading(prev => ({ ...prev, analyze: true }));
    try {
      await axios.post(`/api/v1/oracle/analyze-file?id=${fileId}`);
    } catch (error) {
      console.error('There was an error analyzing file in Skynet', error);
    } finally {
      setIsLoading(prev => ({ ...prev, analyze: false }));
    }
  };

  const handleFileMenuOpen = (fileId: string, anchor: HTMLElement) => {
    setAnchorEls((prev) => ({
      ...prev,
      [fileId]: anchor,
    }));
  };

  const handleFileMenuClose = (fileId: string) => {
    setAnchorEls((prev) => ({
      ...prev,
      [fileId]: null,
    }));
  };

  const handleFileMove = (file: FileType) => {
    setFileToMove(file);
    setMoveModalOpen(true);
    handleFileMenuClose(file.id);
  };

  const handleDeleteRequiredDocument = async (documentId: string) => {
    setIsLoading(prev => ({ ...prev, delete: true }));
    await deleteRequiredDocument(documentId, applicationId);
    await getRequiredDocuments(applicationId);
    setIsLoading(prev => ({ ...prev, delete: false }));
  };

  const handleDeleteFileAction = async (fileToDelete: FileType, actionToDelete: UserFileVerification) => {
    setIsLoading({ ...isLoading, deleteAction: String(actionToDelete.id) });
    await deleteFileAction(fileToDelete.id, applicationId, userId, actionToDelete.id);
    setIsLoading({ ...isLoading, deleteAction: '' });
  };


  useEffect(() => {
    // Find the current document in requiredDocuments using the document's ID
    const currentDocument = requiredDocuments.find(doc => doc.id === initialDocumentState.id);

    if (currentDocument) {
      // Update documentState with the current document's data
      setDocumentState({
        ...currentDocument,
      });
    }
  }, [requiredDocuments, initialDocumentState.id]);

  // This is to ensure that the selectedRequiredDocument is updated when the documentState changes (i.e.: a new file is uploaded)
  // And that the selected required document being updated is the same that was previously selected
  useEffect(() => {
    if (selectedRequiredDocument?.id === documentState.id) {
      setSelectedRequiredDocument(documentState);
    }
  }, [documentState]);

  return (
    <BetterAccordion
      key={documentState.id}
      expanded={isExpanded}
      onChange={(event, isExpanded) => {
        onChange(isExpanded);
      }}
      sx={{
        borderTop: `1px solid ${theme.palette.divider}`,
        boxShadow: 'none',
        '& .Mui-expanded': {
          margin: 0,
          minHeight: '48px !important',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        '&.MuiAccordion-root': {
          borderRadius: '0px',
        },
        '& .Mui-expanded.MuiAccordionSummary-expandIconWrapper': {
          minHeight: '8px',
        },
      }}
    >
      <AccordionSummary
        aria-controls={`${documentState.id}-content`}
        id={`${documentState.id}-header`}
        className="flex-gap-0_5 flex-row-start-reverse"
        sx={{
          my: 0,
          '& .Mui-expanded': {
            my: 0,
          },
          '& .MuiAccordionSummary-root': {
            my: 0,
          },
          '& .MuiAccordionSummary-content': {
            my: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            transition: 'none',
            transform: 'none'
          },
          borderBottom: isExpanded ? '1px solid #E0E0E0' : 'none',
          pl: '1.7rem',
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
          gap={0}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'.5rem'}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '.5rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pb: '1.5px',
                  '& .icon': {
                    transition: 'transform 0.3s ease',
                    transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
                  },
                  pl: isSubGroup ? '1.7rem' : '0',
                }}
              >
                <ExpandMoreIcon className="icon" />
                {documentState.files.filter(file => !file.is_deleted).length > 0 ?
                  <SingleFileIcon />
                  :
                  <NoFileIcon />
                }
              </Box>
              <Typography
                variant="body2"
                fontWeight={400}
                color={'text.primary'}
                sx={{
                  display: 'inline',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontFamily: 'Lato, sans-serif',
                }}
              >
                {documentState.requirement_name}
                {documentState.is_required && (
                  <span style={{ color: theme.palette.info.main, paddingLeft: '2px', display: 'inline' }}>*</span>
                )}
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} onClick={(event) => {
            event.stopPropagation();
          }}>
            {documentState.notes && documentState.notes.length > 0 && (
              <Box display={'flex'} alignItems={'center'} sx={{ cursor: 'default', }}>
                <Tooltip
                  title={<NotesTooltipInfo notes={documentState.notes} />}
                  placement="top"
                  sx={{
                    '&.MuiButtonBase-root': { backgroundColor: 'transparent' },
                  }}
                  slotProps={{
                    tooltip: {
                      sx: {
                        color: "#514E6A",
                        backgroundColor: "#F9FBFD",
                        p: 2,
                        border: '1px solid rgba(219, 224, 233, 1)',
                        borderRadius: '.75rem',
                      },
                    },
                  }}
                >
                  <IconButton>
                    <ChatBubbleOutlineRoundedIcon sx={{ color: '#2196F3' }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {showFileReadWriteButtons && !isFileSentToBank ? (
              <Box display={'flex'} alignItems={'center'} >
                <IconButton size="small" color="primary" disabled={isLoading.upload}>
                  {isLoading.upload ? (
                    <CircularProgress size={20} />
                  ) : (
                    <IconButton size="small" color="primary" onClick={(event) => {
                      // This is to prevent event from bubbling up to the accordion
                      event.stopPropagation()
                      handleUpload()
                    }}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1000,
                      }}
                    >
                      <UploadIcon
                        color="primary"
                      />
                    </IconButton>
                  )}
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(event) => {
                    // This is to prevent event from bubbling up to the accordion
                    event.stopPropagation()
                    setEditModalOpen(true)
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <EditIcon
                    color="primary"
                  />
                </IconButton>
                <ShowIf every={[[RequiredDocumentApprovalStatus.MISSING, RequiredDocumentApprovalStatus.ISSUES_IDENTIFIED, RequiredDocumentApprovalStatus.AWAITING_UPLOAD].includes(documentState.approval_status)]}>
                  <IconButton size="small" color="primary" disabled={isLoading.delete}>
                    {isLoading.delete ? (
                      <CircularProgress size={20} />
                    ) : (
                      <IconButton size="small" color="primary" onClick={(event) => {
                        // This is to prevent event from bubbling up to the accordion
                        event.stopPropagation()
                        setDocToDelete(documentState.requirement_name)
                        setDeleteReqDocModalOpen(true)
                      }}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 1000,
                        }}
                      >
                        <TrashIcon
                          color="primary"
                        />
                      </IconButton>
                    )}
                  </IconButton>
                </ShowIf>
              </Box>
            ) : null}
            {updatingStatus ? (
              <Typography variant="body2" color={'text.secondary'} sx={{ fontSize: '14px' }}>
                Updating...
              </Typography>
            ) : (
              <TextField
                value={documentState.approval_status}
                size="small"
                variant="standard"
                aria-label="Status"
                name="approval_status"
                disabled={updatingStatus || isFileVerifiedOrSentToBank}
                required
                select
                SelectProps={{
                  // Don't show the arrow down icon if the document is verified
                  IconComponent: !isFileVerifiedOrSentToBank ? CustomArrowDown : undefined,
                }}
                onChange={(event) => {
                  // This is to prevent event from bubbling up to the accordion
                  event.stopPropagation()
                  handleStatusChange(event)
                }}
                sx={{
                  backgroundColor: documentState.approval_status ? documentStatusPickerChoices.find(status => status.value === documentState.approval_status)?.backgroundColor : 'transparent',
                  color: documentState.approval_status ? documentStatusPickerChoices.find(status => status.value === documentState.approval_status)?.color : '#FFF',
                  fontSize: '12px',
                  fontWeight: 700,
                  borderRadius: '40px',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: isFileVerifiedOrSentToBank ? 'none' : '10px',
                  marginRight: isFileVerifiedOrSentToBank ? 'none' : '.5rem',
                  marginLeft: '1rem',
                  '& .MuiSvgIcon-root': {
                    display: isFileVerifiedOrSentToBank ? 'none' : 'block',
                  },
                  '& .Mui-disabled': {
                    '-webkit-text-fill-color': `${documentState.approval_status ? documentStatusPickerChoices.find(status => status.value === documentState.approval_status)?.color : '#FFF'} !important`,
                    '&::before': {
                      borderBottomStyle: 'none !important',
                    },
                  },
                  '&::before': {
                    border: 'none',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: 'none',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: 'none',
                  },
                  '& .MuiInput-input': {
                    backgroundColor: 'transparent',
                    padding: 0
                  },
                  '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
                    paddingRight: isFileVerifiedOrSentToBank ? '14px' : '24px',
                  },
                  '& .MuiSelect-select:focus': {
                    backgroundColor: 'transparent',
                  },
                  '& .MuiSelect-icon': {
                    fontSize: '12px',
                  },
                  '& .MuiChip-label': {
                    paddingRight: '0px',
                  },
                  '& .MuiChip-root': {
                    height: '28px',
                  }

                }}
              >
                {/* We keep Verified only if it's the selected value */}
                {isFileVerified && (
                  <MenuItem key="VERIFIED" value={RequiredDocumentApprovalStatus.VERIFIED}>
                    <Chip
                      label="Verified"
                      sx={{
                        backgroundColor: documentStatusPickerChoices.find(status => status.value === RequiredDocumentApprovalStatus.VERIFIED)?.backgroundColor,
                        color: documentStatusPickerChoices.find(status => status.value === RequiredDocumentApprovalStatus.VERIFIED)?.color,
                        fontSize: '12px',
                      }}
                    />
                  </MenuItem>
                )}
                {/* We keep Sent to Bank only if it's the selected value */}
                {isFileSentToBank && (
                  <MenuItem key="SENT_TO_BANK" value={RequiredDocumentApprovalStatus.SENT_TO_BANK}>
                    <Chip
                      label="Sent to Bank"
                      sx={{
                        backgroundColor: documentStatusPickerChoices.find(status => status.value === RequiredDocumentApprovalStatus.SENT_TO_BANK)?.backgroundColor,
                        color: documentStatusPickerChoices.find(status => status.value === RequiredDocumentApprovalStatus.SENT_TO_BANK)?.color,
                        fontSize: '12px',
                      }}
                    />
                  </MenuItem>
                )}
                {/* Display the rest of the statuses in the dropdown */}
                {documentStatusPickerChoices.filter(status => !excludedStatuses.includes(status.value)).map((status) => (
                  <MenuItem
                    key={status.value}
                    value={status.value}
                    onClick={(event) => {
                      // This is to prevent event from bubbling up to the accordion
                      event.stopPropagation()
                    }}
                    disabled={excludedStatuses.includes(status.value)}
                  >
                    <Chip
                      label={status.label}
                      sx={{
                        backgroundColor: status.backgroundColor,
                        color: status.color,
                        fontSize: '12px',
                      }}
                    />
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>

        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F7F7F7',
          px: 0,
          pt: 0,
        }}
      >
        {(
          documentState.files?.length > 0
        ) ? documentState.files.map((file) => {

          if (file.is_deleted !== true && file.is_current === true) {
            return (
              <>
                <Grid item xs={12} key={file.id}>
                  <Box
                    key={file.id}
                    sx={{
                      backgroundColor: selectedFile && selectedFile?.id === file.id ? '#E3F2FD' : '#F3F3F3',
                      border: selectedFile && selectedFile?.id === file.id ? '1px solid #2196F3' : 'none',
                      borderBottom: selectedFile && selectedFile?.id === file.id ? '1px solid #2196F3' : '1px solid #ECECEC',
                      borderRadius: '4px',
                      pl: isSubGroup ? '5.25rem' : '3.55rem',
                      pr: '1rem',
                      py: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      width={'100%'}
                      padding={0}
                    >
                      <Box display={'flex'} alignItems={'flex-end'} gap={'.5rem'}>
                        {showFileReadWriteButtons ? (
                          <Link
                            onClick={(event) => {
                              event.stopPropagation();
                              addFileToPreview(file);
                              setSelectedFile(file);
                              setSelectedRequiredDocument(documentState);
                            }}
                            sx={{
                              textDecoration: 'none',
                              fontWeight: 700,
                              fontFamily: 'Lato, sans-serif',
                              fontSize: '14px',
                              pl: '.5rem',
                              cursor: 'pointer',
                            }}
                          >
                            <Typography
                              variant="body2"
                              color={'text.primary'}
                              fontSize={16}
                              sx={{
                                fontStyle: 'normal',
                                color: '#1E89DD',
                                fontWeight: 400,
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '14px',
                              }}
                            >
                              {uploadingFile && !file.file_name ? 'Uploading...' : shortenFilename(file.file_name, 30)}
                            </Typography>
                          </Link>
                        ) : (
                          <Typography
                            variant="body2"
                            color={'text.primary'}
                            sx={{ fontSize: '14px' }}
                          >
                            {file.file_name}
                          </Typography>
                        )}
                        <Typography
                          variant="body2"
                          fontSize={12}
                          color={'text.secondary'}
                          display={'flex'}
                          flexWrap={'wrap'}
                          sx={{
                            mr: '1rem',
                          }}
                        >
                          <Typography
                            component={'span'}
                            sx={{
                              maxWidth: '200px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              mr: 1,
                            }}
                            fontFamily={'Lato, sans-serif'}
                            color={'#868795'}
                            fontSize={10}
                            fontWeight={400}
                          >
                            {file.created_by}
                          </Typography>
                          <Typography
                            component={'span'}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            fontFamily={'Lato, sans-serif'}
                            color={'#868795'}
                            fontSize={10}
                            fontWeight={400}
                          >
                            {niceDateTime(new Date(file.created_at))}
                          </Typography>
                        </Typography>
                      </Box>
                      <Box display={'flex'} alignItems={'center'}>
                        {isAdmin() && !file.has_oracle_insights && (
                          <Box>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                handleAnalyzeOracleAnalysis(file.id);
                              }}
                              disabled={isLoading.analyze}
                              sx={{
                                mx: 0.5,
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '12px',
                                fontWeight: 700,
                                textTransform: 'none',
                                border: '1px solid #000000',
                                color: 'black',
                                '&:hover': {
                                  border: '1px solid #000000',
                                  backgroundColor: '#000000',
                                  color: 'white',
                                },
                                borderRadius: '50px',
                                px: 1,
                                paddingRight: '.8rem',
                                py: 0,
                              }}
                            >
                              {isLoading.analyze ? (
                                <CircularProgress size={20} sx={{ mr: 1 }} />
                              ) : (
                                <img
                                  src={skynetLogo}
                                  style={{
                                    width: '25px',
                                    marginRight: '5px',
                                  }}
                                />
                              )}
                              Analyze
                            </Button>
                          </Box>
                        )}
                        {showFileReadWriteButtons && !isFileSentToBank ? (
                          <>
                            <IconButton
                              size="small"
                              onClick={(event) => handleFileMenuOpen(file.id, event.currentTarget)}
                              sx={{
                                color: '#BBC3CB',
                                p: 0
                              }}
                            >
                              <MoreVertOutlinedIcon />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEls[file.id]}
                              open={Boolean(anchorEls[file.id])}
                              onClose={() => handleFileMenuClose(file.id)}
                              sx={{
                                '& .MuiMenu-list': {
                                  backgroundColor: '#000000',
                                  color: 'white',
                                  fontFamily: 'Lato, sans-serif',
                                  fontWeight: 400,
                                  fontSize: '12px',
                                },
                              }}
                            >
                              <MenuItem
                                key={`${file.id}-${file.created_at}-move`}
                                onClick={() => handleFileMove(file)}
                                sx={{
                                  fontFamily: 'Lato, sans-serif',
                                  fontSize: '12px',
                                  fontWeight: 400,
                                }}
                              >
                                Move to
                              </MenuItem>
                              <MenuItem
                                key={`${file.id}-${file.created_at}-archive`}
                                onClick={() => {
                                  setArchiveModalOpen(true);
                                  setFileToArchive(file.id);
                                }}
                                sx={{
                                  fontFamily: 'Lato, sans-serif',
                                  fontSize: '12px',
                                  fontWeight: 400,
                                }}
                              >
                                Archive
                              </MenuItem>
                            </Menu>
                          </>
                        ) : (
                          <Box display="flex" alignItems={'center'} ml={1}></Box>
                        )}
                      </Box>
                    </Box>
                    <ShowIf every={[!!(file?.user_file_verifications?.length)]}>
                      <FileHistory
                        userFileVerifications={file?.user_file_verifications!}
                        isLoading={isLoading}
                        handleDeleteFileAction={(actionToDelete) => handleDeleteFileAction(file!, actionToDelete)}
                        file={file!}
                        reqDocApprovalStatus={documentState.approval_status}
                      />
                    </ShowIf>
                  </Box>
                </Grid>
                <ConfirmationModal
                  open={archiveModalOpen}
                  onClose={() => setArchiveModalOpen(false)}
                  onConfirm={() => {
                    handleArchive(fileToArchive);
                    setArchiveModalOpen(false);
                  }}
                  title="Confirm Archive"
                  content="Are you sure you want to archive this file?"
                  cancelText="No, cancel"
                  confirmText="Yes, archive file"
                />
              </>
            );
          }
        }) : (
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                borderBottom: '1px solid #ECECEC',
                pl: '3.55rem',
                pr: '1rem',
                py: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
                padding={0}
                pl={isSubGroup ? '1.7rem' : '0'}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <SubdirectoryArrowRightIcon
                    sx={{
                      color: '#868795',
                    }}
                  />
                  <Typography variant="body2" color={'text.primary'}
                    fontWeight={400}
                    fontSize={12}
                    fontFamily={'Lato, sans-serif'}
                    sx={{
                      pl: '.5rem',
                      color: '#868795',
                    }}
                  >
                    No files uploaded for this document.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        )}
        <NotesSection
          requiredDocument={documentState}
        />
        <Box sx={{ backgroundColor: '#F7F7F7' }}>
          {documentState.requirement_description && (
            <Grid item xs={12}
              sx={{
                paddingTop: '1rem !important',
                pl: '5rem',
                pr: '1rem',
              }}
            >
              <Typography variant="body2" color={'text.primary'}
                fontWeight={400}
                fontSize={12}
                fontFamily={'Lato, sans-serif'}
              >
                Description: {documentState.requirement_description}
              </Typography>
            </Grid>
          )}
          <Box
            sx={{
              mt: 1,
              pl: '5rem',
              pr: '1rem',
              display: 'flex',
              gap: 3,  // Adds spacing between items
              alignItems: 'center'
            }}
          >
            <Typography
              variant="body2"
              color={'text.secondary'}
              fontWeight={400}
              fontSize={12}
              sx={{
                display: 'flex',
                alignSelf: 'flex-end',
                fontFamily: 'Lato, sans-serif',
                pb: '1.5px',
              }}
            >
              Owner: {documentState.owner?.name || 'Unassigned'}
            </Typography>
            <Typography
              fontFamily={'Lato, sans-serif'}
              fontSize={12}
              fontWeight={400}
              color={'text.secondary'}
            >
              <span style={{ color: '#777' }}>Source: </span>
              {capitalizeString(documentState.document_approval_source) || '-'}
            </Typography>

            <Typography
              variant="body2"
              fontSize={12}
              color={'#868795'}
              fontFamily={'Lato, sans-serif'}
            >
              Created{' '}
              {niceDateTime(
                documentState.created_at ??
                standardToShortDate(new Date().toISOString()),
              )}{' '}
              {documentState.created_by_user
                ? `by ${documentState.created_by_user.name}`
                : ''}
            </Typography>
          </Box>
        </Box>
      </AccordionDetails>

      <RequiredDocumentModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSubmit={handleSubmit}
        initialDocument={documentState}
        editMode
        users={users}
      />
      <MoveFileModal
        open={moveModalOpen}
        onClose={() => setMoveModalOpen(false)}
        file={fileToMove || null}
        fromStep={documentState.required_for_step || ''}
        fromDoc={documentState.requirement_name || ''}
        fromDocId={documentState.id || ''}
      />
      <DeleteReqDocModal
        open={deleteReqDocModalOpen}
        onClose={() => setDeleteReqDocModalOpen(false)}
        onConfirm={() => handleDeleteRequiredDocument(documentState.id)}
        requiredDocName={docToDelete || ''}
        isDeleting={isLoading.delete}
      />
    </BetterAccordion >
  );
};
