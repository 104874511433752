import Lottie from 'lottie-react';
import React from 'react';
import SunAnimationData from '../../assets/animation/sun_loading.json';

export const SunAnimation = (props) => {
    return <Lottie
        animationData={SunAnimationData}
        loop={true}
        autoplay={true}
        style={props.style}
    />
}