export enum envKeys {
  SF_USERNAME = 'SF_USERNAME',
  SF_PASSWORD = 'SF_PASSWORD',
  SF_TOKEN = 'SF_TOKEN',
  CRB_ID = 'CRB_ID',
  CRB_SECRET = 'CRB_SECRET',
  LEXIS_NEXIS_USER = 'LEXIS_NEXIS_USER',
  LEXIS_NEXIS_PASSWORD = 'LEXIS_NEXIS_PASSWORD',
  EQUIFAX_CLIENT_ID = 'EQUIFAX_CLIENT_ID',
  EQUIFAX_CLIENT_SECRET = 'EQUIFAX_CLIENT_SECRET',
  EQUIFAX_MEMBER_NUMBER = 'EQUIFAX_MEMBER_NUMBER',
  EQUIFAX_SECURITY_CODE = 'EQUIFAX_SECURITY_CODE',
  DNB_CLIENT_ID = 'DNB_CLIENT_ID',
  DNB_CLIENT_SECRET = 'DNB_CLIENT_SECRET',
  COOKIE_SECRET = 'COOKIE_SECRET',
  DATABASE_URL = 'DATABASE_URL',
  GA_FTP_USERNAME = 'GA_FTP_USERNAME',
  GA_FTP_PASSWORD = 'GA_FTP_PASSWORD',
  CRB_FTP_USERNAME = 'CRB_FTP_USERNAME',
  CRB_FTP_PASSWORD = 'CRB_FTP_PASSWORD',
  FORBRIGHT_FTP_USERNAME = 'FORBRIGHT_FTP_USERNAME',
  FORBRIGHT_FTP_PASSWORD = 'FORBRIGHT_FTP_PASSWORD',
  MT_FTP_USERNAME = 'MT_FTP_USERNAME',
  MT_FTP_PASSWORD = 'MT_FTP_PASSWORD',
  SSO_CLIENT_ID = 'SSO_CLIENT_ID',
  SSO_CLIENT_SECRET = 'SSO_CLIENT_SECRET',
  SSO_TENANT_ID = 'SSO_TENANT_ID',
  POSTGRES_USERNAME = 'POSTGRES_USERNAME',
  POSTGRES_PASSWORD = 'POSTGRES_PASSWORD',
  PAYNET_USER = 'PAYNET_USER',
  PAYNET_PASSWORD = 'PAYNET_PASSWORD',
  DNB_TOKEN = 'DNB_TOKEN',
  TOOLS_DB_URL = 'TOOLS_DB_URL',
  FLASH_SPREAD_TOKEN = 'FLASH_SPREAD_TOKEN',
  API_KEY = 'API_KEY',
  FORM_STACK_KEY = 'FORM_STACK_KEY',
  FORM_STACK_SECRET = 'FORM_STACK_SECRET',
  OPENAI_API_KEY = 'OPENAI_API_KEY',
  OPEN_EI_KEY = 'OPEN_EI_KEY',
  ACR_CALCULATOR_KEY = 'ACR_CALCULATOR_KEY',
  USPS_KEY = 'USPS_KEY',
  USPS_SECRET = 'USPS_SECRET',
  POWERLYTICS_TOKEN = 'POWERLYTICS_TOKEN',
}

export const secrets = new Map();

export const RailResultType: { [key: number]: string } = {
  0: 'NotAttempted',
  1: 'Requested',
  2: 'Success',
  3: 'RequestFailure',
  4: 'Rejected',
  5: 'Returned',
  6: 'Skipped',
  7: 'ValidationFail',
  8: 'CosAchPaymentRejected',
  9: 'CosAchPaymentCanceled',
  10: 'CosAchNocReceived',
  11: 'CosAchHoldEscalated',
  12: 'CosCoreTransactionCompleted',
  13: 'CosCoreTransactionRejected',
  15: 'CosXPaymentReceived',
  16: 'CosXPaymentCanceled',
  17: 'CosXPaymentRejected',
  18: 'CosXPaymentFailed',
  19: 'CosXPaymentCompleted',
  20: 'CosCoreTransactionCompletedReverse',
  24: 'CosXPaymentRejectedReverse',
  25: 'CosXPaymentFailedReverse',
  26: 'CosXPaymentCanceledReverse',
  27: 'CheckCashed',
  28: 'CheckPrinted',
  29: 'DigitalCheckPrinted',
  30: 'CheckMailed',
  31: 'Failed',
};

export const RailType: { [key: number]: string } = {
  4: 'RPPS',
  7: 'NF',
  8: 'COSACH',
  9: 'COSACHSD',
  10: 'COSXPay',
  20: 'AdjustmentAction',
  30: 'Check',
  40: 'RTP',
};

export enum RailStatus {
  READY = 'Ready',
  REQUESTED = 'Requested',
  SKIPPED = 'Skipped',
  SENT = 'Sent',
  FAILED = 'Failed',
  SUCCESS = 'Success',
  NOT_ATTEMPTED = 'NotAttempted',
  REQUEST_FAILURE = 'RequestFailure',
  REJECTED = 'Rejected',
  RETURNED = 'Returned',
  VALIDATION_FAIL = 'ValidationFail',
}

export enum WebHooks {
  LoanStatusUpdated = 'LoanStatusUpdated',
  ComplianceLoanFailed = 'ComplianceLoanFailed',
  RailUpdated = 'RailUpdated',
}

export const USER_COOKIE_KEY = 'user';
export const USER_ID_COOKIE_KEY = 'user_id';
export const USER_ROLE_COOKIE_KEY = 'user_role';
export const USER_GROUP_COOKIE_KEY = 'user_group';
export const USER_NAME_COOKIE_KEY = 'name';

export const stateCodeToState = {
  AA: 'Armed Forces America',
  AE: 'Armed Forces Europe',
  AK: 'Alaska',
  AL: 'Alabama',
  AP: 'Armed Forces Pacific',
  AR: 'Arkansas',
  AS: 'American Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  FM: 'Micronesia',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MP: 'Northern Mariana Islands',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  PW: 'Palau',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
};

export const stateCode = {
  'ARMED FORCES AMERICA': 'AA',
  'ARMED FORCES EUROPE': 'AE',
  ALASKA: 'AK',
  ALABAMA: 'AL',
  'ARMED FORCES PACIFIC': 'AP',
  ARKANSAS: 'AR',
  'AMERICAN SAMOA': 'AS',
  ARIZONA: 'AZ',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  'DISTRICT OF COLUMBIA': 'DC',
  DELAWARE: 'DE',
  FLORIDA: 'FL',
  MICRONESIA: 'FM',
  GEORGIA: 'GA',
  GUAM: 'GU',
  HAWAII: 'HI',
  IOWA: 'IA',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MASSACHUSETTS: 'MA',
  MARYLAND: 'MD',
  MAINE: 'ME',
  'MARSHALL ISLANDS': 'MH',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSOURI: 'MO',
  'NORTHERN MARIANA ISLANDS': 'MP',
  MISSISSIPPI: 'MS',
  MONTANA: 'MT',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  NEBRASKA: 'NE',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  NEVADA: 'NV',
  'NEW YORK': 'NY',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  'PUERTO RICO': 'PR',
  PALAU: 'PW',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VIRGINIA: 'VA',
  'VIRGIN ISLANDS': 'VI',
  VERMONT: 'VT',
  WASHINGTON: 'WA',
  WISCONSIN: 'WI',
  'WEST VIRGINIA': 'WV',
  WYOMING: 'WY',
};

export enum ReportType {
  PAYNET = 'paynet',
  DNB = 'dnb',
  LEXIS = 'lexis',
  EQUIFAX = 'equifax',
  POWERLYTICS = 'powerlytics',
}

export enum ActionType {
  LOAN_SENT = 'LOAN_SENT',
  LOAN_UPDATED = 'LOAN_UPDATED',
  LOAN_FILES_ATTACHED = 'LOAN_FILES_ATTACHED',
  LOAN_CANCELLED = 'LOAN_CANCELED',
  LOC_SENT = 'LOC_SENT',
  LOC_CANCELLED = 'LOC_CANCELLED',
  LOC_FILES_ATTACHED = 'LOC_FILES_ATTACHED',
  LOC_UPDATED = 'LOC_UPDATED',
  RAILS_SENT = 'RAILS_SENT',
  SFTP_FILE_UPLOADED = 'SFTP_FILE_UPLOADED',
  SFTP_FILE_DELETED = 'SFTP_FILE_DELETED',
  SFTP_FILE_DOWNLOADED = 'SFTP_FILE_DOWNLOADED',
  CHECKLIST_NOTE_EDITED = 'CHECKLIST_NOTE_EDITED',
  CHECKLIST_UPDATED = 'CHECKLIST_UPDATED',
  CHECKLIST_DELETED = 'CHECKLIST_DELETED',
  SCORECARD_UPLOADED = 'SCORECARD_UPLOADED',
  INSTALLER_SCORECARD_UPLOADED = 'INSTALLER_SCORECARD_UPLOADED',
  UW_TOOL_UPLOADED = 'UW_TOOL_UPLOADED',
  INSTALLER_TOOL_UPLOADED = 'INSTALLER_TOOL_UPLOADED',
  UW_REPORT_PULLED = 'UW_REPORT_PULLED',
  FILE_MANAGEMENT_REQUIRED_DOC_CREATE = 'FILE_MANAGEMENT_REQUIRED_DOC_CREATE',
  FILE_MANAGEMENT_REQUIRED_DOC_UPDATED = 'FILE_MANAGEMENT_REQUIRED_DOC_UPDATED',
  FILE_MANAGEMENT_REQUIRED_DOC_UPLOAD = 'FILE_MANAGEMENT_REQUIRED_DOC_UPLOAD',
  FILE_MANAGEMENT_DOWNLOAD_ALL = 'FILE_MANAGEMENT_DOWNLOAD_ALL',
  FILE_MANAGEMENT_SOFT_DELETE_FILE = 'FILE_MANAGEMENT_SOFT_DELETE_FILE',
  FILE_MANAGEMENT_MOVE_FILE = 'FILE_MANAGEMENT_MOVE_FILE',
  FILE_MANAGEMENT_REPLACE_CURRENT_FILE = 'FILE_MANAGEMENT_REPLACE_CURRENT_FILE',
  FILE_MANAGEMENT_FILE_UPDATED = 'FILE_MANAGEMENT_FILE_UPDATED',
  CREDIT_MEMO_GENERATED = 'CREDIT_MEMO_GENERATED',
  ACR_CALCULATED = 'ACR_CALCULATED',
}

// mapping of postgres requiredDocument.approval_status to SF requiredDocument.Approval_Status__c
export enum DocumentApprovalStatus {
  APPROVED = 'Approved',
  AWAITING_UPLOAD = 'Awaiting Upload',
  ISSUES_IDENTIFIED = 'Issues Identified',
  MISSING = 'Missing',
  NEEDS_REVIEW = 'Waiting Review', // railway / SF mismatch
  SENT_UPON_REQUEST = 'Sent Upon Request',
  SIGNATURE_NEEDED = 'Signature Needed',
  VERIFIED = 'Verified',
  WAITING_REVIEW = 'Waiting Review',
  SENT_TO_BANK = 'Sent to Bank',
}

export enum DocumentRequiredForStep {
  BORROWER_APP_SUBMISSION = 'Borrower App Submission',
  UNDERWRITING_APPROVAL = 'Underwriting Approval',
  BORROWER_SIGNING = 'Borrower Signing',
  PROJECT_START = 'Project Start',
  NOTICE_TO_PROCEED = 'Notice to Proceed',
  EQUIPMENT_ORDER = 'Equipment Order',
  RACKING_COMPLETION = 'Racking Completion',
  MECHANICAL_COMPLETION = 'Mechanical Completion',
  SUBSTANTIAL_COMPLETION = 'Substantial Completion',
  FINAL_COMPLETION = 'Final Completion',
}

export enum MilestoneRequiredSteps {
  NOTICE_TO_PROCEED = 'Notice to Proceed',
  EQUIPMENT_ORDER = 'Equipment Order',
  RACKING_COMPLETION = 'Racking Completion',
  MECHANICAL_COMPLETION = 'Mechanical Completion',
  SUBSTANTIAL_COMPLETION = 'Substantial Completion',
  FINAL_COMPLETION = 'Final Completion',
}

export enum MilestoneStatus {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  PAID = 'Paid',
  WAIVED = 'Waived',
};

export enum DocumentUserGroup {
  INSTALLER = 'Installer',
  BORROWER = 'Borrower',
  CONSULTANT = 'Consultant',
  INSTALLER_AND_CONSULTANT = 'Installer and Consultant',
  CONSULTANT_AND_BORROWER = 'Consultant and Borrower',
  BORROWER_AND_INSTALLER = 'Borrower and Installer',
  ALL = 'All',
}

export const BankLoanActions = {
  CANCELLED: 'CANCELLED',
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  FILES_ATTACHED: 'FILES_ATTACHED',
  RAILS_SENT: 'RAILS_SENT',
  LOC_SENT: 'LOC_SENT',
  LOC_CANCELLED: 'LOC_CANCELLED',
  LOC_FILES_ATTACHED: 'LOC_FILES_ATTACHED',
  LOC_UPDATED: 'LOC_UPDATED',
};

export enum ScorecardFormulaInputKeys {
  // Debt to Income
  ANNUAL_GROSS_INCOME = 'annual-gross-income',
  TOTAL_MONTHLY_PAYMENTS = 'total-monthly-payments',
  POWERLYTICS_MIN = 'powerlytics-min',
  POWERLYTICS_MAX = 'powerlytics-max',
  BRACKETED_ANNUAL_GROSS_INCOME = 'bracketed-annual-gross-income',

  // Cash Coverage of Payments
  AVERAGE_CASH_BALANCE = 'avg-cash',
  GUARANTOR_NAME = 'guarantor-name',
  FIRST_YEAR_LOAN_PAYMENT = 'first-year-solar-loan-payment',
  THIRD_YEAR_LOAN_PAYMENT = 'third-year-solar-loan-payment',

  // All in 20 year System Benefits to Expense Ratio
  UTILITY_COST_SAVINGS = 'utility-cost-savings',
  SURPLUS_POWER_MONETIZATION = 'surplus-power-monetization',
  INVESTMENT_TAX_CREDIT = 'investment-tax-credit',
  TAX_DEPRECIATION_SAVINGS = 'tax-depreciation-savings',
  REC_PRODUCTION_INCENTIVES = 'rec-production-incentives',
  OTHER_INCENTIVES = 'other-incentives',
  MAINTENANCE_INSURANCE_AND_LEASES = 'maintenance-insurance-and-leases',
  TOTAL_LOAN_PAYMENTS = 'total-loan-payments',

  // Business DSCR
  EBITDA_FOR_BUSINESS_DSCR = 'ebitda-for-business-dscr',
  EBITDA_INTEREST = 'ebitda-interest',
  EBITDA_NET_INCOME = 'ebitda-net-income',
  EBITDA_DEPRECIATION = 'ebitda-depreciation',
  EBITDA_AMORTIZATION = 'ebitda-amortization',
  YEAR_ONE_SAVINGS_FOR_BUSINESS_DSCR = 'year-one-savings-for-business-dscr',
  REC_INCENTIVES_FOR_BUSINESS_DSCR = 'rec-incentives-for-business-dscr',
  BUSINESS_DEBT_OBLIGATIONS_FOR_DSCR = 'business-debt-obligations-for-business-dscr',
  ANNUAL_SOLAR_PAYMENT_FOR_BUSINESS_DSCR = 'annual-solar-payment-for-business-dscr',

  // Personal Liquidity
  APPLICATION_PG_NAME = 'application-pg-name',
  REVOLVING_BALANCES = 'revolving-balances',
  INSTALLMENT_LOAN_TOTAL = 'installment-loan-total',
  CASH_ON_HAND = 'cash-on-hand',
  LIQUID_ASSETS = 'liquid-assets',
  MARKETABLE_SECURITIES_BONDS = 'marketable-securities-bonds',
  LOAN_AMOUNT_FOR_PERSONAL_LIQUIDITY = 'loan-amount-for-personal-liquidity',

  // Property Value
  PROPERTY_VALUE = 'property-value',
  MORTGAGE_BALANCE = 'mortgage-balance',
  SYSTEM_COST = 'system-cost',
}

export enum ScorecardItemKeys {
  BANKRUPTCIES = 'bankruptcies',
  BUSINESS_DSCR = 'business-dscr',
  BUSINESS_TO_DEBT_RATIO = 'business-to-debt-service-ratio',
  BUSINESS_VERIFICATION = 'business-verification',
  BUSINESS_VERIFICATION_SOS = 'business-verification-sos',
  CASH_COVERAGE_OF_PAYMENTS = 'cash-coverage-of-payments',
  CHECKING_ACCOUNT_VERIFICATION = 'checking-account-verification',
  DEBT_TO_INCOME = 'debt-to-income',
  DSCR_AVG = 'dscr-avg',
  DSCR_MIN = 'dscr-min',
  EBITDA = 'ebitda',
  FCCR = 'fccr',
  FCCR_AVERAGE = 'fccr-average',
  FICO_SCORE = 'fico-score',
  HIGH_RISK_BUSINESS = 'high-risk-business',
  LIENS = 'liens',
  LIQUIDITY_TO_SOLAR_AMOUNT = 'personal-liquidity-to-solar-amount',
  LOAN_AMOUNT = 'loan-amount',
  LONGEVITY_OF_BUSINESS = 'longevity-of-business',
  NAICS_ATTRACTIVENESS = 'naics-attractiveness',
  OCCUPATION = 'occupation',
  OWNER_EXPERIENCE = 'owner-experience',
  PAYDEX_SCORE = 'paydex-score',
  PAYMENTS_TO_SOLAR_DEBT_SERVICE_RATIO = 'recurring-incentive-payments-to-solar-debt-service-ratio',
  PAYNET_SCORE = 'paynet-score',
  POWERLYTICS = 'powerlytics',
  PROPERTY_SYSTEM_RATIO = 'property-system-ratio',
  PROPERTY_TO_LOAN_VALUE = 'property-to-loan-value',
  PROPERTY_VALUE = 'property-value',
  PROPERTY_VERIFICATION = 'property-verification',
  SYSTEM_BENEFIT_TO_EXPENSE_RATIO = 'system-benefit-to-expense-ratio',
  SYSTEM_COST = 'system-cost',
}

export enum ScoreCardFormatters {
  CURRENCY = 'currency',
  DEFAULT = 'default',
  INTEGER = 'integer',
  PERCENT = 'percent',
  PERCENT_ONE_DECIMAL = 'percent-one-decimal',
  DEFAULT_STRING = 'default-string',
  VERIFICATION_BOOL = 'verification-bool',
  BANKRUPTCIES = 'bankruptcies',
  LIENS = 'liens',
  RISK = 'risk',
}

export enum ScoreCardEditComponents {
  EDIT_BOOL = 'edit-bool',
  EDIT_NUMERIC = 'edit-numeric',
}

export const QualityIndicators = {
  GOOD: 'Good',
  OK: 'Ok',
  BAD: 'Bad',
  NA: 'N/A',
};

export enum EntityRecord {
  ENTITY_RECORD_TYPE_ID_STAGING = '0127c000000DEe6AAG',
  INDIVIDUAL_RECORD_TYPE_ID_STAGING = '0127c000000DEe7AAG',
  ENTITY_RECORD_TYPE_ID_PROD = '0124X000001uHLQQA2',
  INDIVIDUAL_RECORD_TYPE_ID_PROD = '0124X000001uHLRQA2',
}

export enum OracleInsightType {
  SolarProposal = 'SolarProposal',
  UtilityBills = 'UtilityBills',
  LeaseAgreement = 'LeaseAgreement',
  PowerPurchaseAgreement = 'PowerPurchaseAgreement',
  UtilityBillValidation = 'UtilityBillValidation',
  CostarReport = 'CostarReport',
  TaxReturnValidation = 'TaxReturnValidation',
}

// note: duplicated in /client/src/components/ProjectChecklist/constants.ts
export const ChecklistItemType = {
  DOCUMENT_REQUIREMENT: 'DOCUMENT_REQUIREMENT',
  CALL: 'CALL',
  DOCUSIGN: 'DOCUSIGN',
};

// note: duplicated in /client/src/components/ProjectChecklist/constants.ts
export const ChecklistItemRule = {
  EXIST: 'EXIST',
  EQUAL: 'EQUAL',
  YES_NO: 'YES_NO',
};

export const UserGroups = {
  INSTALLER: 'INSTALLER',
  CONSULTANT: 'CONSULTANT',
  INSTALLER_AND_CONSULTANT: 'INSTALLER_AND_CONSULTANT',
  BORROWER: 'BORROWER',
  ALL: 'ALL',
};

export const NEW_VERSION_ALERT_DAYS = 14;

export const ChecklistNewVersionCheckFields = {
  Loan_Amount__c: 'Loan Amount',
  Loan_Product__c: 'Loan Product',
};

// map Railway Uploaded Documents statuses to Sunstone Document approval statuses
export const approvalStatusMap = {
  VERIFIED: 'Verified',
  NEEDS_REVIEW: 'Waiting Review',
  ISSUES_IDENTIFIED: 'Issues Identified',
  MISSING: 'Missing',
};

export enum ACCOUNT_STATUSES {
  PROSPECTING = 'Prospecting',
  ONBOARDED = 'Onboarded',
  UNQUALIFIED = 'Unqualified',
  DORMANT = 'Dormant',
  FROZEN = 'Frozen',
  CLOSED = 'Closed'
}

export enum ACCOUNT_LAUNCH_STATUSES {
  APPROVED = 'Approved',
  SIGNED = 'Signed',
  LAUNCHED = 'Launched',
}

export enum NOTE_TYPE {
  INTERNAL = 'Internal',
  EXTERNAL = 'External',
}

export enum UWToolValuesHistoryAction {
  CREATE = 'Create',
  UPDATE = 'Update',
  DELETE = 'Delete',
  RESTORE = 'Restore',
}

export enum CurrentFileStatus {
  NONE = 'None',
  VERIFIED = 'Verified',
  DECLINED = 'Declined',
}

export const positionsRequiredForVerification = [
  'Underwriter',
  'Credit Analyst',
]

export enum FileActionType {
  Verify = 'Verify',
  Decline = 'Decline',
  UndoAction = 'UndoAction',
}

export enum ReportKeyPrefix {
  BUSINESS_INSTANT_ID2 = 'BusinessInstantID2',
  BUSINESS_INSTANT_ID2_SYS = 'BusinessInstantID2Sys',
  CONSUMER_INSTANT_ID2 = 'ConsumerInstantID2',
  CONSUMER_CREDIT = 'ConsumerCredit',
  INCOME_REPORT = 'IncomeReport',
  PAYMENT_INSIGHT = 'PaymentInsight',
  PAYNET = 'Paynet',
  PROPERTY_REPORT = 'PropertyReport',
  TOP_BUSINESS_REPORT = 'TopBusinessReport',
}

// Made this a Set for faster search
export const MasterApprovers = new Set([
  'jim.barber@sunstonecredit.com',
  'jack.benzer@sunstonecredit.com',
  'lucy.lyman@sunstonecredit.com',
  'dan.murphy@sunstonecredit.com',
  'prashant.bhavaraju@sunstonecredit.com',
])

export enum UWToolKeys {
  DEAL_ARCHETYPE = 'SCR1060',
  SCORECARD_VERSION = 'COV1007',
  BORROWER_TYPE = 'SCR1016',
  SUNSTONE_SCORE = 'SCR1017',
  SUNSTONE_SCORE_RATING = 'SCR1018',
  FRAUD_SCORE = 'SCR1001',
  FRAUD_SCORE_RATING = 'SCR1002',
  FRAUD_KNOCKOUT = 'SCR1097',
  BORROWER_BUSINESS_NAME_MATCH = 'SCR1005',
  BORROWER_PHYSICAL_ADDRESS_MATCH = 'SCR1006',
  BORROWER_SOLAR_SYSTEM_ADDRESS_MATCH = 'SCR1058',
  BORROWER_OFAC_MATCH = 'SCR1014',
  BORROWER_EIN_TIN_MATCH = 'SCR1007',
  BORROWER_CONFIRMED_BUSINESS_PHONE = 'SCR1008',
  BORROWER_BUSINESS_LICENSE_CONFIRMED = 'SCR1059',
  BORROWER_HIGH_RISK_GEO = 'SCR1009',
  BORROWER_LEXIS_NEXIS_FRAUD_SCORE = 'SCR1010',
  BORROWER_CONFIRMED_OWNED_OR_LEASED = 'SCR1011',
  PRINCIPAL_LEGAL_NAME_MATCH = 'SCR1064',
  PRINCIPAL_PHYSICAL_ADDRESS_MATCH = 'SCR1065',
  PRINCIPAL_OFAC_MATCH = 'SCR1066',
  PRINCIPAL_SSN_MATCH = 'SCR1067',
  PRINCIPAL_DOB_MATCH = 'SCR1068',
  PRINCIPAL_FRAUD_SCORE = 'SCR1069',
  PRINCIPAL_CONTROLLING_PARTY_CONFIRMED = 'SCR1015',
  COMMERCIAL_GUARANTOR = 'SCR1070',
  CG_BUSINESS_NAME_MATCH = 'SCR1071',
  CG_PHYSICAL_ADDRESS_MATCH = 'SCR1072',
  CG_OFAC_MATCH = 'SCR1073',
  CG_EIN_TIN_MATCH = 'SCR1074',
  CG_CONFIRMED_BUSINESS_PHONE = 'SCR1075',
  CG_BUSINESS_LICENSE_CONFIRMED = 'SCR1076',
  CG_HIGH_RISK_GEO = 'SCR1077',
  CG_FRAUD_SCORE = 'SCR1078',
  BUSINESS_DSCR = 'SCR1100',
  BUSINESS_LIQUIDITY = 'SCR1104',
  DEBT_TO_INCOME = 'SCR1101',
  FIXED_CHARGE_COVERAGE_RATIO = 'SCR1037',
  EXISTING_DEBT_PAYMENTS_REVENUE = 'SCR1091',
  SOLAR_LOAN_DEBT_SERVICE_REVENUE = 'SCR1092',
  DAYS_OPERATING_EXPENSES = 'SCR1093',
  OFFTAKER_FIXED_CHARGE_RATIO = 'SCR1116',
  PROPERTY_LTV = 'SCR1105',
  NET_PERSONAL_LIQUIDITY = 'SCR1106',
  EQUITY_NET_ASSETS = 'SCR1038',
  CURRENT_RATIO = 'SCR1040',
  CASH_BALANCE_DEBT_SERVICE = 'SCR1041',
  PROPERTY_EQUITY_VALUE = 'SCR1107',
  OFFTAKER_EQUITY_NET_ASSETS = 'SCR1117',
  OFFTAKER_CURRENT_RATIO = 'SCR1118',
  SYSTEM_BENEFIT_RATIO = 'SCR1053',
  AVERAGE_SOLAR_DSCR = 'SCR1054',
  RECURRING_INCENTIVE_PAYMENTS = 'SCR1056',
  USER_POWER_PRODUCED = 'SCR1055',
  SAVINGS_OVER_PPA = 'SCR1063',
  POWER_CONSUMED_AVERAGE = 'SCR1108',
  POWER_CONSUMED_LARGEST = 'SCR1109',
  FICO_SCORE = 'SCR1098',
  COMMERCIAL_CREDIT_SCORE = 'SCR1034',
  COMMERCIAL_CREDIT_SOURCE = 'SCR1035',
  PROPERTY_VALUE_RATIO = 'SCR1051',
  SUNSTONE_PROPERTY_ASSESSMENT = 'SCR1052',
  LONGEVITY_YEARS = 'SCR1046',
  SENIOR_LEADER_TENURE = 'SCR1094',
  OWNER_EXPERIENCE = 'SCR1047',
  EMPLOYMENT = 'SCR1099',
  TENANT_LEASE_TERM = 'SCR1043',
  TENANT_TIME_IN_LEASE = 'SCR1110',
  TENANT_CREDIT_SCORE = 'SCR1044',
  RESERVE_TARGET_FUNDED = 'SCR1088',
  HOMEOWNER_TIME = 'SCR1095',
  DUES_DELINQUENT = 'SCR1085',
  LIEN_AUTHORITY = 'SCR1086',
  NON_SPONSORED_OWNED = 'SCR1087',
  TOP_PAYORS_REVENUE = 'SCR1089',
  TOP_3_DONORS = 'SCR1081',
  TOP_10_DONORS = 'SCR1082',
  RECURRING_NON_DONATION = 'SCR1083',
  PRIOR_ATTENDANCE_GROWTH = 'SCR1079',
  LARGER_ORG_SUPPORT = 'SCR1084',
  NON_INVESTMENT_TENANT_SCORE = 'SCR1111',
  TENANT_INVESTMENT_GRADE = 'SCR1112',
  SOLAR_MANAGER_YEARS = 'SCR1113',
  SOLAR_PROJECTS_MANAGED = 'SCR1114',
  CG_FIXED_CHARGE_RATIO = 'SCR1115',
  SECONDARY_REVIEW = 'COV1006'
}