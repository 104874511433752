import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';


export const DocTypeExpandIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  function DocTypeExpandIcon(props, ref) {
    return (
      <SvgIcon ref={ref} {...props}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.2388 8.95187C12.4944 8.15881 13.551 8.00789 14.0184 8.69766L19.9422 17.439C20.3922 18.103 19.9165 19 19.1143 19H10.373C9.69461 19 9.2131 18.3389 9.42122 17.6932L12.2388 8.95187Z" fill="#333333" />
          <path d="M12.2421 12.2201C12.5082 12.977 13.5126 13.128 13.9896 12.4828L19.8212 4.59447C20.3092 3.93439 19.838 3 19.0171 3L10.4117 3C9.72219 3 9.23957 3.6813 9.46828 4.33172L12.2421 12.2201Z" fill="#333333" />
          <path d="M14.2421 12.2201C14.5082 12.977 15.5126 13.128 15.9896 12.4828L21.8212 4.59447C22.3092 3.93439 21.838 3 21.0171 3L12.4117 3C11.7222 3 11.2396 3.6813 11.4683 4.33172L14.2421 12.2201Z" fill="#333333" />
          <path d="M14.2388 8.95187C14.4944 8.15881 15.551 8.00789 16.0184 8.69766L21.9422 17.439C22.3922 18.103 21.9165 19 21.1143 19H12.373C11.6946 19 11.2131 18.3389 11.4212 17.6932L14.2388 8.95187Z" fill="#333333" />
          <rect x="3" y="3" width="13" height="16" rx="1" fill="#333333" />
        </svg>
      </SvgIcon>
    );
  }
);