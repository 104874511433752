import axios from "axios";
import { Bank, FileActionType, RequiredDocument, RequiredDocumentWithConventionName, TransmissionReason, UserFileVerification } from "../helpers/types";

/**
 * This module contains the service functions related to the Required Documents. 
 * Some are used within the Context, others are used directly in the components.
 */

export const getApplication = async (appId: string) => {
  return await axios
    .get('/api/v1/loans/application/' + appId)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const getApplicationRequiredDocuments = async (appId: string) => {
  return await axios
    .get('/api/v1/loans/required-documents/' + appId)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const createRequiredDocument = async (document: RequiredDocument) => {
  return await axios
    .post('/api/v1/loans/required-documents/', document)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const getDocumentTypes = async () => {
  return await axios
    .get('/api/v1/loans/document-types/')
    .then((r) => r.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const updateRequiredDocument = (
  updates,
  requiredDocumentId,
  sunstoneDocumentId,
  updateType,
) => {
  return axios.patch(
    `/api/v1/uw/required-documents/${requiredDocumentId}?type=${updateType}&sunstoneDocumentId=${sunstoneDocumentId}`,
    updates,
  );
};

export const getFile = async (fileId: string) => {
  return await axios.get(`/api/v1/loans/file/${fileId}`, 
    { responseType: 'arraybuffer' } // This tells axios to return the response as an ArrayBuffer, which allows us to save the file as a blob, to then be able to be saved as a zip file
  );
};

export const archiveFile = (fileId: string) => {
  return axios.patch(`/api/v1/loans/file/${fileId}`, { is_deleted: true });
};

export const unarchiveFile = (fileId: string) => {
  return axios.patch(`/api/v1/loans/file/${fileId}`, { is_deleted: false });
};

export const replaceCurrentFile = (fileId: string) => {
  return axios.patch(`/api/v1/loans/file/${fileId}`, { is_current: true });
};

export const moveFile = async (fileId: string, requiredDocumentId: string, prevRequiredDocumentId: string) => {
  try {
    const response = await axios.patch(`/api/v1/loans/file/${fileId}`, { required_doc_id: requiredDocumentId, is_current: true, prevRequiredDocumentId });
    return response.data;
  } catch (err: any) {
    console.error("Error moving file:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || "Failed to move file.");
  }
}

export const getDocumentTypesRequiredForAppArchetype = async (appId: string) => {
  try {
    const response = await axios.get(`/api/v1/loans/required-archetype-document-types/${appId}`);
    return response.data;
  } catch (err: any) {
    console.error("Error fetching document types:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || "Failed to fetch required document types.");
  }
};

export const getAllArchetypeDocumentTypes = async () => {
  try {
    const response = await axios.get('/api/v1/loans/archetype-document-types/');
    return response.data;
  } catch (err: any) {
    console.error("Error fetching all archetype document types:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || "Failed to fetch all archetype document types.");
  }
};

export const updateArchetypeDocumentTypeConditions = async (archetypeDocumentTypeId: number, conditions: Record<string, string | boolean> | null) => {
  try {
    const response = await axios.put(`/api/v1/loans/archetype-document-types/${archetypeDocumentTypeId}`, { conditions });
    return response.data;
  } catch (err: any) {
    console.error("Error updating archetype document type:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || "Failed to update archetype document type.");
  }
};

export const deleteRequiredDocument = async (documentId: string) => {
  try {
    const response = await axios.delete(`/api/v1/loans/required-documents/${documentId}`);
    return response.data;
  } catch (err: any) {
    console.error("Error deleting required document:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || "Failed to delete required document.");
  }
};

export const updateFileAction = async (
  fileId: string,
  action: FileActionType,
  userId: number,
  comment?: string,
  actionToDeleteId?: UserFileVerification['id']
) => {
  try {
    const response = await axios.post(`/api/v1/loans/file/${fileId}/file_action`, {
      action,
      userId,
      comment,
      actionToDeleteId,
    });
    return response.data;
  } catch (err: any) {
    console.error("Error updating file action:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || "Failed to update file action.");
  }
}

export const verifyFile = async (fileId: string, userId: number, comment?: string) => {
  try {
    return await updateFileAction(fileId, FileActionType.VERIFY, userId, comment);
  } catch (err: any) {
    console.error("Error verifying file:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || "Failed to verify file.");
  }
};

export const declineFile = async (fileId: string, userId: number, comment?: string) => {
  try {
    return await updateFileAction(fileId, FileActionType.DECLINE, userId, comment);
  } catch (err: any) {
    console.error("Error declining file:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || "Failed to decline file.");
  }
};

export const deleteFileAction = async (fileId: string, userId: number, actionToDeleteId: UserFileVerification['id']) => {
  try {
    // The undefined here is the comment, which is not required for the undo action
    return await updateFileAction(fileId, FileActionType.DELETE, userId, undefined, actionToDeleteId);
  } catch (err: any) {
    console.error("Error deleting file action:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || "Failed to delete file action.");
  }
};

export const getBankLoanHistory = async (appId: string, fetchLast?: boolean) => {
  try {
    const url = fetchLast 
      ? `/api/v1/loans/bank-loan-history/${appId}?fetchLast=${fetchLast}`
      : `/api/v1/loans/bank-loan-history/${appId}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err: any) {
    console.error("Error fetching bank loan history:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || err.message || "Failed to fetch bank loan history.");
  }
}

export const sendFilesToBank = async (
  appId: string, 
  bank: Bank, 
  transmissionReason: TransmissionReason, 
  userId: number, 
  fileIds: string[], 
  selectedDocs: Record<string, RequiredDocumentWithConventionName>, 
  crbId: string, 
  comments?: string
) => {
  try {
    const response = await axios.post('/api/v1/loans/send-files-to-bank/', {
      appId,
      bank,
      transmissionReason,
      userId,
      fileIds,
      selectedDocs,
      crbId,
      comments,
    });
    return response.data;
  } catch (err: any) {
    console.error("Error sending files to bank:", err.response?.data?.message || err.message);
    throw new Error(err.response?.data?.message || err.message || "Failed to send files to bank.");
  }
}
