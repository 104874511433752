import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import {
  ADD_REQUIRED_DOCUMENT,
  DELETE_REQUIRED_DOCUMENT,
  SET_ALL_ARCHETYPE_DOCUMENT_TYPES,
  SET_ALL_ARCHETYPE_DOCUMENT_TYPES_ERROR,
  SET_ALL_ARCHETYPE_DOCUMENT_TYPES_LOADING,
  SET_ARCHETYPE_DOCUMENT_TYPES_ERROR,
  SET_ARCHETYPE_DOCUMENT_TYPES_LOADING,
  SET_ARCHETYPE_DOCUMENT_TYPES_REQUIRED_FOR_APP,
  SET_DOCUMENT_TYPES,
  SET_LOAN_SUMMARY_EXPANDED,
  SET_OPENED_FILES,
  SET_REQUIRED_DOCUMENTS,
  SET_SELECTED_FILE,
  SET_SELECTED_REQUIRED_DOCUMENT,
} from '../actions/requiredDocumentsActions';
import { OLD_ARCHETYPES } from '../helpers/constants';
import { ArchetypeDocumentType, CustomDocumentType, DocumentType, FileType, RequiredDocument, UserFileVerification } from '../helpers/types';
import { initialState, RequiredDocumentsActions, requiredDocumentsReducer, RequiredDocumentsState } from '../reducers/requiredDocumentsReducer';
import {
  archiveFile as archiveFileService,
  createRequiredDocument as createRequiredDocumentService,
  declineFile as declineFileService,
  deleteFileAction as deleteFileActionService,
  deleteRequiredDocument as deleteRequiredDocumentService,
  getAllArchetypeDocumentTypes as getAllArchetypeDocumentTypesService,
  getApplicationRequiredDocuments,
  getApplication as getApplicationService,
  getDocumentTypesRequiredForAppArchetype,
  getDocumentTypes as getDocumentTypesService,
  replaceCurrentFile as replaceCurrentFileService,
  unarchiveFile as unarchiveFileService,
  updateArchetypeDocumentTypeConditions as updateArchetypeDocumentTypeConditionsService,
  verifyFile as verifyFileService,
  moveFile as moveFileService,
} from '../services/requiredDocumentsService';


type RequiredDocumentsProviderProps = {
  children: ReactNode;
}

type RequiredDocumentsContextProps = {
  state: RequiredDocumentsState;
  dispatch: Dispatch<RequiredDocumentsActions>;
  getRequiredDocuments: (appId: string) => Promise<void>;
  createRequiredDocument: (newDocument: RequiredDocument) => Promise<void>;
  getDocumentTypes: () => Promise<void>;
  addFileToPreview: (file: FileType) => void;
  removeFileFromPreview: (file: FileType) => void;
  setSelectedFile: (file: FileType | null) => void;
  setSelectedRequiredDocument: (requiredDocument: RequiredDocument | null) => void;
  resetOpenedFiles: () => void;
  setLoanSummaryExpanded: () => void;
  getArchetypeDocumentTypesRequiredForApp: (appId: string) => Promise<ArchetypeDocumentType[]>;
  getAllArchetypeDocumentTypes: () => Promise<void>;
  updateArchetypeDocumentTypeConditions: (archetypeDocumentTypeId: number, conditions: Record<string, string | boolean> | null) => Promise<void>;
  replaceCurrentFile: (fileId: string, appId: string) => Promise<void>;
  archiveFile: (fileId: string, appId: string) => Promise<void>;
  unarchiveFile: (fileId: string, appId: string) => Promise<void>;
  deleteRequiredDocument: (documentId: string, appId: string) => Promise<void>;
  verifyFile: (fileId: string, appId: string, userId: number, comment?: string) => Promise<void>;
  declineFile: (fileId: string, appId: string, userId: number, comment?: string) => Promise<void>;
  deleteFileAction: (fileId: string, appId: string, userId: number, actionToDeleteId: UserFileVerification['id']) => Promise<void>;
  moveFile: (fileId: string, appId: string, requiredDocumentId: string, prevRequiredDocumentId: string) => Promise<void>;
  shouldFetchArchetypeDocumentTypes: (appId: string) => Promise<boolean>;
}

/**
 * This is the context that will be used to manage the state of the required documents
 * The state is managed using the reducer and the initial state is defined in the reducer file
 * The context will only make the state and dispatch available to the components that are wrapped with the Provider
 */

export const RequiredDocumentsContext = createContext<RequiredDocumentsContextProps>({} as RequiredDocumentsContextProps);

export const RequiredDocumentsProvider = ({ children }: RequiredDocumentsProviderProps) => {

  const [state, dispatch] = useReducer<React.Reducer<RequiredDocumentsState, RequiredDocumentsActions>>(
    requiredDocumentsReducer,
    initialState as RequiredDocumentsState
  );

  const shouldFetchArchetypeDocumentTypes = async (appId: string): Promise<boolean> => {
    const app = await getApplicationService(appId);
    return !OLD_ARCHETYPES.includes(app.Loan_Category__c || '');
  };

  const getRequiredDocuments = async (appId: string) => {
    const requiredDocuments = await getApplicationRequiredDocuments(appId);
    dispatch({ type: SET_REQUIRED_DOCUMENTS, payload: { requiredDocuments } });
    requiredDocuments.forEach((doc) => {
      if (doc.id === state.selectedRequiredDocument?.id) {
            setSelectedRequiredDocument(doc);
      }
    })
    
    if (await shouldFetchArchetypeDocumentTypes(appId)) {
      getArchetypeDocumentTypesRequiredForApp(appId); // This will update the state of the archetype document types after a file is uploaded so it's in sync with any changes to the required documents
    } else {
      // If the archetype document types are not required, we need to set the state to an empty array
      dispatch({ type: SET_ARCHETYPE_DOCUMENT_TYPES_REQUIRED_FOR_APP, payload: { archetypeDocumentTypesRequiredForApp: [] } });
    }
  };

  const createRequiredDocument = async (newDocument: RequiredDocument) => {
    try {
      const newDoc = await createRequiredDocumentService(newDocument);
      dispatch({ type: ADD_REQUIRED_DOCUMENT, payload: { requiredDocument: newDoc } });
    } catch (error: any) {
      throw error;
    }
  };

  const getDocumentTypes = async () => {
    try {
      const documentTypes = await getDocumentTypesService();
      const activeDocs: DocumentType[] = documentTypes.filter((doc: DocumentType) => doc.is_active);
      activeDocs.sort((a, b) => a.name.localeCompare(b.name));

      let dTypes: Record<DocumentType['id'], CustomDocumentType> = {};
      for (const docType of activeDocs) {
        dTypes[docType.id] = {
          name: docType.name,
          isRequired: docType.is_required,
        };
      }

      // inactive documents at the bottom, sorted by name
      let inactiveDocs: DocumentType[] = documentTypes.filter((doc) => !doc.is_active);
      inactiveDocs.sort((a, b) => a.name.localeCompare(b.name));
      for (const docType of inactiveDocs) {
        dTypes[docType.id] = {
          name: `[Inactive] ${docType.name}` as string,
          isRequired: docType.is_required,
        };
      }
      dispatch({ type: SET_DOCUMENT_TYPES, payload: { documentTypes: dTypes } });
    } catch (err) {
      console.error('Failed to fetch document types:', err);
      throw err;
    }
  };

  const setSelectedFile = (file: FileType | null) => {
    dispatch({ type: SET_SELECTED_FILE, payload: { selectedFile: file } });
  };

  const setSelectedRequiredDocument = (requiredDocument: RequiredDocument | null) => {
    dispatch({ type: SET_SELECTED_REQUIRED_DOCUMENT, payload: { selectedRequiredDocument: requiredDocument } });
  };

  const addFileToPreview = (file: FileType) => {
    if (!state.openedFiles.find((f) => f.id === file.id)) {
      dispatch({ type: SET_OPENED_FILES, payload: { openedFiles: [...state.openedFiles, file] } });
    }
  };

  const removeFileFromPreview = (file: FileType) => {
    dispatch({ type: SET_OPENED_FILES, payload: { openedFiles: state.openedFiles.filter((f) => f.id !== file.id) } });
    const defaultSelectedFile = state.openedFiles.length ? state.openedFiles[state.openedFiles.length - 1] : null;
    setSelectedFile(defaultSelectedFile);
  };

  const resetOpenedFiles = () => {
    dispatch({ type: SET_OPENED_FILES, payload: { openedFiles: [] } });
    setSelectedFile(null);
  };

  const setLoanSummaryExpanded = () => {
    dispatch({ type: SET_LOAN_SUMMARY_EXPANDED, payload: { loanSummaryExpanded: state.loanSummaryExpanded } });
  };

  const getArchetypeDocumentTypesRequiredForApp = async (appId: string) => {
    dispatch({ type: SET_ARCHETYPE_DOCUMENT_TYPES_LOADING, payload: { loading: true } });
    try {
      const archetypeDocumentTypesRequiredForApp = await getDocumentTypesRequiredForAppArchetype(appId);
      dispatch({ type: SET_ARCHETYPE_DOCUMENT_TYPES_REQUIRED_FOR_APP, payload: { archetypeDocumentTypesRequiredForApp } });

      return archetypeDocumentTypesRequiredForApp;

    } catch (err: any) {
      dispatch({
        type: SET_ARCHETYPE_DOCUMENT_TYPES_ERROR,
        payload: { errorMessage: err.message },
      })
    }
  };

  const getAllArchetypeDocumentTypes = async () => {
    dispatch({ type: SET_ALL_ARCHETYPE_DOCUMENT_TYPES_LOADING, payload: { loading: true } });
    try {
      const allArchetypeDocumentTypes = await getAllArchetypeDocumentTypesService();
      dispatch({ type: SET_ALL_ARCHETYPE_DOCUMENT_TYPES, payload: { allArchetypeDocumentTypes } });
    } catch (err: any) {
      dispatch({
        type: SET_ALL_ARCHETYPE_DOCUMENT_TYPES_ERROR,
        payload: { errorMessage: err.message }
      });
    }
  };

  const updateArchetypeDocumentTypeConditions = async (archetypeDocumentTypeId: number, conditions: Record<string, string | boolean> | null) => {
    await updateArchetypeDocumentTypeConditionsService(archetypeDocumentTypeId, conditions);
    getAllArchetypeDocumentTypes();
  };

  const replaceCurrentFile = async (fileId: string, appId: string) => {
    await replaceCurrentFileService(fileId);
    getRequiredDocuments(appId);
  };

  const moveFile = async (fileId: string, appId: string, requiredDocumentId: string, prevRequiredDocumentId: string) => {
    await moveFileService(fileId, requiredDocumentId, prevRequiredDocumentId);
    getRequiredDocuments(appId);
  };

  const archiveFile = async (fileId: string, appId: string) => {
    await archiveFileService(fileId);
    getRequiredDocuments(appId);
  };

  const unarchiveFile = async (fileId: string, appId: string) => {
    await unarchiveFileService(fileId);
    getRequiredDocuments(appId);
  };

  const deleteRequiredDocument = async (documentId: string, appId: string) => {
    await deleteRequiredDocumentService(documentId);
    dispatch({ type: DELETE_REQUIRED_DOCUMENT, payload: { documentId } });

    getRequiredDocuments(appId);
  };

  const verifyFile = async (fileId: string, appId: string, userId: number, comment?: string) => {
    await verifyFileService(fileId, userId, comment);
    getRequiredDocuments(appId);
  };

  const declineFile = async (fileId: string, appId: string, userId: number, comment?: string) => {
    await declineFileService(fileId, userId, comment);
    getRequiredDocuments(appId);
  };

  const deleteFileAction = async (fileId: string, appId: string, userId: number, actionToDeleteId: UserFileVerification['id']) => {
    await deleteFileActionService(fileId, userId, actionToDeleteId);
    getRequiredDocuments(appId);
  };

  return (
    <RequiredDocumentsContext.Provider value={{
      state,
      dispatch,
      getRequiredDocuments,
      createRequiredDocument,
      getDocumentTypes,
      addFileToPreview,
      removeFileFromPreview,
      setSelectedFile,
      setSelectedRequiredDocument,
      resetOpenedFiles,
      setLoanSummaryExpanded,
      getArchetypeDocumentTypesRequiredForApp,
      getAllArchetypeDocumentTypes,
      updateArchetypeDocumentTypeConditions,
      replaceCurrentFile,
      archiveFile,
      unarchiveFile,
      deleteRequiredDocument,
      verifyFile,
      declineFile,
      deleteFileAction,
      moveFile,
      shouldFetchArchetypeDocumentTypes,
    }}>
      {children}
    </RequiredDocumentsContext.Provider>
  );
}

export const useRequiredDocuments = () => {
  const context = useContext(RequiredDocumentsContext);
  if (!context) {
    throw new Error('useRequiredDocuments must be used within a RequiredDocumentsProvider');
  }
  return context;
}
