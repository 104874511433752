export const SET_REQUIRED_DOCUMENTS = 'SET_REQUIRED_DOCUMENTS';
export const SET_OPENED_FILES = 'SET_OPENED_FILES';
export const SET_SELECTED_FILE = 'SET_SELECTED_FILE';
export const SET_SELECTED_REQUIRED_DOCUMENT = 'SET_SELECTED_REQUIRED_DOCUMENT';
export const SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES';
export const ADD_REQUIRED_DOCUMENT = 'ADD_REQUIRED_DOCUMENT';
export const SET_LOAN_SUMMARY_EXPANDED = 'SET_LOAN_SUMMARY_EXPANDED';
export const SET_ARCHETYPE_DOCUMENT_TYPES_REQUIRED_FOR_APP = 'SET_ARCHETYPE_DOCUMENT_TYPES_REQUIRED_FOR_APP';
export const SET_ARCHETYPE_DOCUMENT_TYPES_ERROR = 'SET_ARCHETYPE_DOCUMENT_TYPES_ERROR';
export const SET_ARCHETYPE_DOCUMENT_TYPES_LOADING = 'SET_ARCHETYPE_DOCUMENT_TYPES_LOADING';
export const SET_ALL_ARCHETYPE_DOCUMENT_TYPES = 'SET_ALL_ARCHETYPE_DOCUMENT_TYPES';
export const SET_ALL_ARCHETYPE_DOCUMENT_TYPES_ERROR = 'SET_ALL_ARCHETYPE_DOCUMENT_TYPES_ERROR';
export const SET_ALL_ARCHETYPE_DOCUMENT_TYPES_LOADING = 'SET_ALL_ARCHETYPE_DOCUMENT_TYPES_LOADING';
export const DELETE_REQUIRED_DOCUMENT = 'DELETE_REQUIRED_DOCUMENT';