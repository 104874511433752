import { FraudItemStatus } from '@backend/types/FraudScorecard';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { niceDate } from '../../../helpers/converters';
import {
  hasManualOverride,
} from '../../../helpers/scorecardHelper';
import { updateFraudItem } from '../../../services/fraudScorecardService';
import FraudItemEdit from './FraudItemEdit';
import FraudScorecardStatusChip from './FraudScorecardStatusChip';
// group and report will be used in future iterations

const FraudScorecardItem = ({
  item,
  user,
}) => {

  // Show overrides by default if they exist, value or formula input
  const [showOverride, setShowOverride] = useState(hasManualOverride(item));
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isUpdatingItem, setIsUpdatingItem] = useState(false);

  useEffect(() => {
    setShowOverride(!!item.valueOverride);
  }, [item]);

  const getItemStyles = () => {
    return {
      border: `1px solid #DCE1ED`,
      backgroundColor: '#FFFFFF',
      borderRadius: '9px',
      padding: '9px 13px 9px 13px',
      marginTop: '9px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    } as React.CSSProperties;
  };

  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };

  const getOverrideTooltip = () => {
    let tooltip = '';
    let prefix = '';
    if (item.overrideSource) {
      tooltip += `Source: ${item.overrideSource}`;
      prefix = '\n';
    }
    if (item.overrideComment) {
      tooltip += `${prefix}Comments: ${item.overrideComment}`;
      prefix = '\n';
    }
    tooltip += `${prefix}Updated: ${niceDate(item.updatedAt)}\nUpdated By: ${item.updatedByUser?.name || ''}`;
    return tooltip;
  };

  const getStatusForChip = (): FraudItemStatus => {

    // only need dynamic status if the item can be overridden
    if (!item.fraudItemTemplate.canOverride) {
      return item.status;
    }

    const checkValue = showOverride ? item.valueOverride : item.value;
    if (checkValue === 'Yes') {
      return FraudItemStatus.PASSED;
    } else if (checkValue === 'No') {
      return FraudItemStatus.KNOCKOUT;
    } else {
      return FraudItemStatus.NO_VALUE;
    }
  };

  const removeOverride = async () => {
    setIsUpdatingItem(true);
    item.valueOverride = null;
    item.overrideSource = null;
    item.overrideComment = null;
    await updateFraudItem(item);
    setIsUpdatingItem(false);
  };

  useEffect(() => {
    setShowOverride(!!item.valueOverride);
  }, [item.valueOverride]);

  return (
    <>
      <Box style={getItemStyles()}>
        {/* title and source */}
        <Box
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          alignItems="flex-start"
        >
          <Typography variant="body1" sx={{ flexGrow: 1 }}>{item.fraudItemTemplate.title}</Typography>
          <Box display="flex" flexDirection="row" sx={{ marginLeft: 'auto', flexShrink: 0 }}>
            <Box
              onClick={() => {
                setShowOverride(false);
              }}
              style={{
                backgroundColor: showOverride ? '#DCE1ED' : '#AACCAA',
                borderRadius: '4px',
                padding: '3px 8px 3px 8px',
                border: '1px solid #DCE1ED',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                cursor: 'pointer',
              }}
            >
              <Typography variant="body2">Automatic</Typography>
            </Box>
            {!!item.valueOverride && (
              <Box
                onClick={() => {
                  setShowOverride(true);
                }}
                style={{
                  borderRadius: '4px',
                  padding: '3px 8px 3px 8px',
                  border: '1px solid #DCE1ED',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  backgroundColor: showOverride ? '#DDAAAA' : '#DCE1ED',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="body2">Override</Typography>
              </Box>
            )}
          </Box>
        </Box>
        {item.fraudItemTemplate.subTitle && (
          <Box
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body2">{item.fraudItemTemplate.subTitle}</Typography>
          </Box>
        )}

        {/* result and status */}
        <Box
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          {showOverride ? (
            <Tooltip
              title={getOverrideTooltip()}
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: '800px',
                    fontSize: '1.2rem',
                    whiteSpace: 'pre-line'
                  },
                },
              }}
            >
              <Typography variant="subtitle2">{showOverride ? item.valueOverride : item.value}</Typography>
            </Tooltip>
          ) : (
            <Typography variant="subtitle2">{showOverride ? item.valueOverride : item.value}</Typography>
          )}
          {item.fraudItemTemplate.canOverride && (
            <Edit
              sx={{ fontSize: '16px', marginTop: '11px', marginLeft: '10px' }}
              onClick={() => {
                toggleEditModal();
              }}
            />
          )}
          {showOverride && (
            <>
              {isUpdatingItem ? (
                <Typography variant="body2" sx={{ fontSize: '16px', marginTop: '11px', marginLeft: '10px' }}>Updating...</Typography>
              ) : (
                <Delete
                  onClick={() => {
                    removeOverride();
                  }}
                  sx={{ fontSize: '16px', marginTop: '11px', marginLeft: '10px' }}
                />
              )}
            </>
          )}
          <Box sx={{ marginLeft: 'auto' }}>
            <FraudScorecardStatusChip value={getStatusForChip()} />
          </Box>
        </Box>
        {/* comparison value */}
        {item.fraudItemTemplate.field && (
          <>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
              Current SFDC Value:
            </Typography>
            <Box
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'baseline',
                marginTop: '3px',
              }}
            >
              <Typography variant="body1">
                {item.currentValue}
              </Typography>
              {/* show if value has changed since the report was run */}
              {item.verificationValue !== item.currentValue && (
                <Tooltip
                  title={`Original Value:\n${item.verificationValue}`}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: '800px',
                        fontSize: '1.2rem',
                        whiteSpace: 'pre-line'
                      },
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ marginLeft: '8px', color: 'red' }}>
                    *
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </>
        )}
      </Box>
      {editModalOpen && (
        <FraudItemEdit
          item={item}
          user={user}
          open={editModalOpen}
          onClose={toggleEditModal}
        />
      )}
    </>
  );
};

export default FraudScorecardItem;
