import { SSOUser } from "./Auth";
import { SFLoanApplication, SFRelatedParty } from "./Loan";
import { UwReport } from "./Underwriting";

export interface FraudScorecardTemplate {
  id: number;
  version: string;
  name: string;
  fraudGroupTemplates: FraudGroupTemplate[];
  createdAt: Date;
}

export interface FraudScorecard {
  id?: number;
  applicationId: string;
  loanVersionId: string;
  fraudScorecardTemplateId: number;
  fraudScorecardTemplate: FraudScorecardTemplate;
  fraudGroups: FraudGroup[];
  createdAt?: Date;
  createdBy: number;
  createdByUser: SSOUser | null;
}

export enum FraudGroupType {
  BORROWER = 'Borrower',
  PERSONAL_GUARANTOR = 'Personal_Guarantor',
  COMMERCIAL_GUARANTOR = 'Commercial_Guarantor',
  BENEFICIAL_OWNER = 'Beneficial_Owner',
}

export interface FraudGroupTemplate {
  id: number;
  fraudScorecardTemplateId: number;
  index: number;
  type: FraudGroupType;
  fraudReportTemplates: FraudReportTemplate[];
  createdAt: Date;
}

export interface FraudGroup {
  id?: number;
  fraudScorecardId?: number;
  fraudGroupTemplateId: number;
  fraudGroupTemplate: FraudGroupTemplate;
  parentId: string;
  name: string;
  fraudReports: FraudReport[];
  createdAt?: Date;
  parent: SFLoanApplication | SFRelatedParty | null;
}

export enum FraudReportType {
  COMPLY_ADVANTAGE = 'Comply_Advantage',
  BUSINESS_INSTANT_ID = 'Business_Instant_ID',
  BUSINESS_INSTANT_ID_SYS = 'Business_Instant_ID_Sys',
  CONSUMER_INSTANT_ID = 'Consumer_Instant_ID',
  SECRETARY_OF_STATE_FILINGS = 'Secretary_Of_State_Filings',
  REAL_PROPERTY = 'Real_Property',
  PROPERTY_REPORT = 'Property_Report',
  OTHER = 'Other',
}

export interface FraudReportTemplate {
  id: number;
  fraudGroupTemplateId: number;
  index: number;
  name: string;
  report: FraudReportType;
  fraudItemTemplates: FraudItemTemplate[];
  createdAt: Date;
}

export enum UwReportStatus {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}

export interface FraudReport {
  id?: number;
  uwReportId: number | null;
  uwReport: UwReport | null;
  uwReportStatus: UwReportStatus | null;
  fraudGroupId?: number;
  fraudReportTemplateId: number;
  fraudReportTemplate: FraudReportTemplate;
  name: string;
  fraudItems: FraudItem[];
  createdAt?: Date;
}

export enum FraudItemValueType {
  CURRENCY = 'currency',
  STRING = 'string',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
}

export enum FraudItemTemplateTitle {
  BUSINESS_NAME_MATCH = 'Business Name Match',
  PHYSICAL_BUSINESS_ADDRESS_MATCH = 'Physical Business Address Match',
  SOLAR_SYSTEM_ADDRESS_MATCH = 'Solar System Address Match',
  OFAC_PASS = 'OFAC Pass',
  EIN_TIN_MATCH = 'EIN (TIN) Match',
  CONFIRMED_BUSINESS_PHONE = 'Confirmed Business Phone',
  BUSINESS_LICENSE_CONFIRMED = 'Business License Confirmed',
  HIGH_RISK_BUSINESS_PASS = 'High Risk Business Pass',
  FRAUD_SCORE_BUSINESS = 'Fraud Score (Business)',
  ADDRESS_CONFIRMED_OWNED_OR_LEASED_BY_BORROWER = 'Address Confirmed Owned or Leased by Borrower',
  LEGAL_NAME_MATCH = 'Legal Name Match',
  PHYSICAL_RESIDENTIAL_ADDRESS_MATCH = 'Physical Residential Address Match',
  SSN_MATCH = 'SSN Match',
  DOB_MATCH = 'DoB Match',
  FRAUD_SCORE_CONSUMER = 'Fraud Score (Consumer)',
}

export interface FraudItemTemplate {
  id: number;
  title: string;
  subTitle: string;
  field: string;
  valueType: FraudItemValueType;
  canOverride: boolean;
  requireOverrideComment: boolean;
  verificationField: string;
  createdAt: Date;
}

export enum FraudItemOverrideSource {
  ARTICLES_OF_ORGANIZATION = 'Articles_of_Organization',
  BANK_STATEMENT = 'Bank_Statement',
  COMMERCIAL_CREDIT_REPORT = 'Commercial_Credit_Report',
  COMPLY_ADVANTAGE = 'Comply_Advantage',
  CONSUMER_CREDIT_REPORT = 'Consumer_Credit_Report',
  DRIVERS_LICENSE = 'Drivers_License',
  MARRIAGE_LICENSE = 'Marriage_License',
  OFAC_SANCTIONS_LIST = 'OFAC_Sanctions_List',
  OTHER_TRUSTED_STATE_DOCUMENT = 'Other_Trusted_State_Document',
  SECURITY_OF_STATE = 'Security_of_State',
  SS_4 = 'SS_4',
  TAX_RETURN = 'Tax_Return',
  TRUSTED_THIRD_PARTY_SOURCE = 'Trusted_Third_Party_Source',
  UTILITY_BILL = 'Utility_Bill',
  OTHER = 'Other',
}

export const FraudItemOverrideSourceOptions = {
  [FraudItemOverrideSource.ARTICLES_OF_ORGANIZATION]: {
    label: 'Articles of Organization',
    value: FraudItemOverrideSource.ARTICLES_OF_ORGANIZATION,
  },
  [FraudItemOverrideSource.BANK_STATEMENT]: {
    label: 'Bank Statement',
    value: FraudItemOverrideSource.BANK_STATEMENT,
  },
  [FraudItemOverrideSource.COMMERCIAL_CREDIT_REPORT]: {
    label: 'Commercial Credit Report',
    value: FraudItemOverrideSource.COMMERCIAL_CREDIT_REPORT,
  },
  [FraudItemOverrideSource.COMPLY_ADVANTAGE]: {
    label: 'Comply Advantage',
    value: FraudItemOverrideSource.COMPLY_ADVANTAGE,
  },
  [FraudItemOverrideSource.CONSUMER_CREDIT_REPORT]: {
    label: 'Consumer Credit Report',
    value: FraudItemOverrideSource.CONSUMER_CREDIT_REPORT,
  },
  [FraudItemOverrideSource.DRIVERS_LICENSE]: {
    label: 'Drivers License',
    value: FraudItemOverrideSource.DRIVERS_LICENSE,
  },
  [FraudItemOverrideSource.MARRIAGE_LICENSE]: {
    label: 'Marriage License',
    value: FraudItemOverrideSource.MARRIAGE_LICENSE,
  },
  [FraudItemOverrideSource.OFAC_SANCTIONS_LIST]: {
    label: 'OFAC Sanctions List',
    value: FraudItemOverrideSource.OFAC_SANCTIONS_LIST,
  },
  [FraudItemOverrideSource.OTHER_TRUSTED_STATE_DOCUMENT]: {
    label: 'Other Trusted State Document',
    value: FraudItemOverrideSource.OTHER_TRUSTED_STATE_DOCUMENT,
  },
  [FraudItemOverrideSource.SECURITY_OF_STATE]: {
    label: 'Security of State',
    value: FraudItemOverrideSource.SECURITY_OF_STATE,
  },
  [FraudItemOverrideSource.SS_4]: {
    label: 'SS-4',
    value: FraudItemOverrideSource.SS_4,
  },
  [FraudItemOverrideSource.TAX_RETURN]: {
    label: 'Tax Return',
    value: FraudItemOverrideSource.TAX_RETURN,
  },
  [FraudItemOverrideSource.TRUSTED_THIRD_PARTY_SOURCE]: {
    label: 'Trusted Third Party Source',
    value: FraudItemOverrideSource.TRUSTED_THIRD_PARTY_SOURCE,
  },
  [FraudItemOverrideSource.UTILITY_BILL]: {
    label: 'Utility Bill',
    value: FraudItemOverrideSource.UTILITY_BILL,
  },
  [FraudItemOverrideSource.OTHER]: {
    label: 'Other',
    value: FraudItemOverrideSource.OTHER,
  },
};

export const FraudItemOverrideSourceGroups = {
  [FraudItemTemplateTitle.BUSINESS_NAME_MATCH]: [
    FraudItemOverrideSource.ARTICLES_OF_ORGANIZATION,
    FraudItemOverrideSource.SECURITY_OF_STATE,
    FraudItemOverrideSource.TAX_RETURN,
    FraudItemOverrideSource.OTHER,
  ],
  [FraudItemTemplateTitle.PHYSICAL_BUSINESS_ADDRESS_MATCH]: [
    FraudItemOverrideSource.ARTICLES_OF_ORGANIZATION,
    FraudItemOverrideSource.BANK_STATEMENT,
    FraudItemOverrideSource.COMMERCIAL_CREDIT_REPORT,
    FraudItemOverrideSource.TRUSTED_THIRD_PARTY_SOURCE,
    FraudItemOverrideSource.UTILITY_BILL,
    FraudItemOverrideSource.OTHER,
  ],
  [FraudItemTemplateTitle.SOLAR_SYSTEM_ADDRESS_MATCH]: [
    FraudItemOverrideSource.ARTICLES_OF_ORGANIZATION,
    FraudItemOverrideSource.BANK_STATEMENT,
    FraudItemOverrideSource.COMMERCIAL_CREDIT_REPORT,
    FraudItemOverrideSource.TRUSTED_THIRD_PARTY_SOURCE,
    FraudItemOverrideSource.UTILITY_BILL,
    FraudItemOverrideSource.OTHER,
  ],
  [FraudItemTemplateTitle.BUSINESS_LICENSE_CONFIRMED]: [
    FraudItemOverrideSource.SECURITY_OF_STATE,
    FraudItemOverrideSource.OTHER,
  ],
  [FraudItemTemplateTitle.EIN_TIN_MATCH]: [
    FraudItemOverrideSource.SS_4,
    FraudItemOverrideSource.TAX_RETURN,
    FraudItemOverrideSource.OTHER,
  ],
  [FraudItemTemplateTitle.LEGAL_NAME_MATCH]: [
    FraudItemOverrideSource.CONSUMER_CREDIT_REPORT,
    FraudItemOverrideSource.MARRIAGE_LICENSE,
    FraudItemOverrideSource.OTHER_TRUSTED_STATE_DOCUMENT,
    FraudItemOverrideSource.OTHER,
  ],
  [FraudItemTemplateTitle.OFAC_PASS]: [
    FraudItemOverrideSource.COMPLY_ADVANTAGE,
    FraudItemOverrideSource.OFAC_SANCTIONS_LIST,
    FraudItemOverrideSource.OTHER,
  ],
  [FraudItemTemplateTitle.PHYSICAL_RESIDENTIAL_ADDRESS_MATCH]: [
    FraudItemOverrideSource.BANK_STATEMENT,
    FraudItemOverrideSource.CONSUMER_CREDIT_REPORT,
    FraudItemOverrideSource.DRIVERS_LICENSE,
    FraudItemOverrideSource.TAX_RETURN,
    FraudItemOverrideSource.UTILITY_BILL,
    FraudItemOverrideSource.OTHER,
  ],
  [FraudItemTemplateTitle.SSN_MATCH]: [
    FraudItemOverrideSource.CONSUMER_CREDIT_REPORT,
    FraudItemOverrideSource.TAX_RETURN,
    FraudItemOverrideSource.OTHER,
  ],
  [FraudItemTemplateTitle.DOB_MATCH]: [
    FraudItemOverrideSource.CONSUMER_CREDIT_REPORT,
    FraudItemOverrideSource.DRIVERS_LICENSE,
    FraudItemOverrideSource.TAX_RETURN,
    FraudItemOverrideSource.OTHER,
  ],
};

export enum FraudItemStatus {
  NO_VALUE = 'No_Value',
  KNOCKOUT = 'Knockout',
  WARNING = 'Warning',
  PASSED = 'Passed',
}

export interface FraudItem {
  id?: number;
  fraudReportId?: number;
  fraudItemTemplateId: number;
  fraudItemTemplate: FraudItemTemplate;
  index: number;
  value: string;
  valueOverride: string | null;
  overrideSource: FraudItemOverrideSource | null;
  overrideComment: string | null;
  verificationValue: string;
  currentValue: string;  // not in db
  status: FraudItemStatus;
  createdAt?: Date;
  updatedAt?: Date | null;
  updatedBy?: number | null;
  updatedByUser?: SSOUser | null;
}
