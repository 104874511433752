import styled from "@emotion/styled";
import { AccordionDetails, AccordionSummary, Typography } from "@mui/material";

export const AccordionActionTypography = styled(Typography)({
    textTransform: 'none',
    cursor: 'pointer',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    textDecoration: 'underline',
    color: '#2196F3',
});


export const DocumentGroupAccordion = styled(AccordionSummary)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    margin: 0,
    zIndex: 2,
    '& .Mui-expanded': {
        margin: 0,
    },
    '& .MuiAccordionSummary-root': {
        margin: 0,
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        transition: 'none',
        transform: 'none'
    },
    paddingTop: '12px',
    paddingBottom: '12px',
});



export const AccordionDocumentDetails = styled(AccordionDetails)({
    backgroundColor: '#F5F5F5',
    padding: 0,
    zIndex: 1,
    borderBottom: '1px solid #E0E0E0',
});


export const GroupedDocumentsStatusTypography = styled(Typography)<{ color?: string, backgroundColor?: string }>(({ color, backgroundColor }) => ({
    fontFamily: 'Lato, sans-serif',
    backgroundColor: backgroundColor ?? 'transparent',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '40px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 12px',
    marginRight: '.5rem',
    color: color ?? 'white',
}));

export const AccordionDetailsNoDocuments = styled(AccordionDetails)({
    borderBottom: '1px solid #E0E0E0',
    borderTop: '1px solid #E0E0E0',
    backgroundColor: '#F9FBFD',
    p: '16px',
});
