{
  "v": "4.8.0",
  "meta": {
    "g": "LottieFiles AE 3.1.1",
    "a": "",
    "k": "",
    "d": "",
    "tc": ""
  },
  "fr": 29.9700012207031,
  "ip": 0,
  "op": 130.000005295009,
  "w": 400,
  "h": 300,
  "nm": "Get Started",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "solarpanel Outlines",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [200, 225, 0],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [49, 25, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [242, 242, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-36.223, -3.424],
                    [-44.993, 9.359],
                    [-19.337, 9.359],
                    [-15.034, -3.424]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 1,
              "ty": "sh",
              "ix": 2,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-11.711, -13.355],
                    [-29.305, -13.355],
                    [-34.262, -6.175],
                    [-14.108, -6.175]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 2",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 2,
              "ty": "sh",
              "ix": 3,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-23.423, -21.804],
                    [-27.399, -16.153],
                    [-10.785, -16.153],
                    [-8.879, -21.804]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 3",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 3,
              "ty": "sh",
              "ix": 4,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [6.373, -21.804],
                    [-5.992, -21.804],
                    [-7.898, -16.153],
                    [8.279, -16.153]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 4",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 4,
              "ty": "sh",
              "ix": 5,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [19.72, 9.351],
                    [44.992, 9.351],
                    [36.168, -3.432],
                    [15.415, -3.432]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 5",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 5,
              "ty": "sh",
              "ix": 6,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [12.583, -3.432],
                    [-12.202, -3.432],
                    [-16.45, 9.351],
                    [16.831, 9.351]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 6",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 6,
              "ty": "sh",
              "ix": 7,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.741, 12.094],
                    [-4.737, 12.094],
                    [-4.737, 21.805],
                    [4.741, 21.805]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 7",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 7,
              "ty": "sh",
              "ix": 8,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [9.198, -13.363],
                    [-8.824, -13.363],
                    [-11.221, -6.175],
                    [11.657, -6.175]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 8",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 8,
              "ty": "sh",
              "ix": 9,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [29.297, -13.363],
                    [12.092, -13.363],
                    [14.487, -6.175],
                    [34.26, -6.175]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 9",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 9,
              "ty": "sh",
              "ix": 10,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [9.26, -21.811],
                    [11.166, -16.161],
                    [27.398, -16.161],
                    [23.422, -21.811]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 10",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 10,
              "ty": "sh",
              "ix": 11,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [-0.02, -0.25],
                    [0.107, -0.227],
                    [0.508, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.167, -0.067],
                    [-0.127, -0.128],
                    [-0.004, -0.364],
                    [0.067, -0.161],
                    [0.125, -0.12],
                    [0.167, -0.075],
                    [0.183, -0.003],
                    [0, 0],
                    [0.249, 0.25],
                    [0.033, 0.352],
                    [-0.075, 0.168],
                    [-0.134, 0.125],
                    [-0.159, 0.067],
                    [-0.173, -0.002],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.325, 0.513],
                    [-0.019, 0.25],
                    [-0.141, 0.207],
                    [0, 0],
                    [-0.191, 0.096],
                    [-0.213, -0.01],
                    [0, 0],
                    [-0.19, -0.096],
                    [-0.12, -0.177]
                  ],
                  "o": [
                    [0, 0],
                    [0.141, 0.207],
                    [0.019, 0.25],
                    [-0.328, 0.512],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.18, -0.004],
                    [0.167, 0.067],
                    [0.246, 0.266],
                    [0.002, 0.174],
                    [-0.067, 0.16],
                    [-0.124, 0.136],
                    [-0.167, 0.075],
                    [0, 0],
                    [-0.35, -0.034],
                    [-0.249, -0.25],
                    [0.004, -0.184],
                    [0.075, -0.168],
                    [0.12, -0.125],
                    [0.159, -0.067],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.508, 0],
                    [-0.108, -0.227],
                    [0.019, -0.25],
                    [0, 0],
                    [0.12, -0.178],
                    [0.191, -0.096],
                    [0, 0],
                    [0.213, -0.009],
                    [0.19, 0.096],
                    [0, 0]
                  ],
                  "v": [
                    [25.274, -24.006],
                    [48.751, 9.892],
                    [48.995, 10.59],
                    [48.862, 11.318],
                    [47.607, 12.086],
                    [7.515, 12.086],
                    [7.515, 21.805],
                    [15.467, 21.805],
                    [15.994, 21.9],
                    [16.44, 22.197],
                    [16.829, 23.177],
                    [16.731, 23.684],
                    [16.44, 24.109],
                    [15.999, 24.43],
                    [15.467, 24.548],
                    [-15.581, 24.548],
                    [-16.507, 24.109],
                    [-16.942, 23.177],
                    [-16.824, 22.642],
                    [-16.507, 22.197],
                    [-16.084, 21.905],
                    [-15.581, 21.805],
                    [-7.519, 21.805],
                    [-7.519, 12.094],
                    [-47.609, 12.094],
                    [-48.86, 11.326],
                    [-48.995, 10.598],
                    [-48.751, 9.9],
                    [-25.274, -23.998],
                    [-24.801, -24.416],
                    [-24.185, -24.547],
                    [24.185, -24.547],
                    [24.799, -24.415],
                    [25.272, -23.998]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 11",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "mm",
              "mm": 1,
              "nm": "Merge Paths 1",
              "mn": "ADBE Vector Filter - Merge",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.21888517492, 0.188891137815, 0.193429221359, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [48.999, 25.452],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 15,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [-0.232, 0.123],
                    [-0.262, 0],
                    [0, 0],
                    [-0.233, -0.125],
                    [-0.148, -0.22],
                    [0, 0],
                    [-0.015, -0.294],
                    [0.137, -0.26],
                    [0.251, -0.152],
                    [0.292, 0],
                    [0, 0],
                    [0.251, 0.153],
                    [0.137, 0.262],
                    [-0.017, 0.295],
                    [-0.166, 0.244]
                  ],
                  "o": [
                    [0, 0],
                    [0.149, -0.217],
                    [0.232, -0.123],
                    [0, 0],
                    [0.264, 0],
                    [0.233, 0.125],
                    [0, 0],
                    [0.163, 0.244],
                    [0.015, 0.294],
                    [-0.137, 0.26],
                    [-0.251, 0.152],
                    [0, 0],
                    [-0.293, 0],
                    [-0.251, -0.153],
                    [-0.137, -0.261],
                    [0.017, -0.295],
                    [0, 0]
                  ],
                  "v": [
                    [-47.076, 15.198],
                    [-25.01, -16.952],
                    [-24.431, -17.47],
                    [-23.679, -17.657],
                    [23.842, -17.739],
                    [24.6, -17.55],
                    [25.181, -17.024],
                    [47.084, 15.208],
                    [47.357, 16.031],
                    [47.17, 16.878],
                    [46.577, 17.507],
                    [45.747, 17.74],
                    [-45.745, 17.74],
                    [-46.578, 17.506],
                    [-47.171, 16.873],
                    [-47.355, 16.022],
                    [-47.076, 15.198]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [1, 0.999998863071, 1, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [49.302, 19.039],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 2",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 900.000036657751,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "sun Outlines 2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [200, 168, 0],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [17.5, 17.5, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [242, 242, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0.122, -0.021],
                    [0.12, 0.03],
                    [0.649, -0.264],
                    [0.368, -0.591],
                    [0.477, 0.204],
                    [0, 0],
                    [0.066, 0.096],
                    [0.016, 0.115],
                    [-0.114, 0.2],
                    [-0.99, 0.416],
                    [-1.045, -0.244],
                    [-0.092, -0.066],
                    [-0.055, -0.099],
                    [-0.029, -0.119],
                    [0.024, -0.121],
                    [0.065, -0.092],
                    [0.098, -0.055]
                  ],
                  "o": [
                    [-0.103, 0.069],
                    [-0.122, 0.021],
                    [-0.688, -0.126],
                    [-0.643, 0.259],
                    [-0.268, 0.452],
                    [0, 0],
                    [-0.102, -0.056],
                    [-0.066, -0.096],
                    [-0.058, -0.223],
                    [0.562, -0.92],
                    [0.99, -0.416],
                    [0.111, 0.02],
                    [0.092, 0.066],
                    [0.076, 0.096],
                    [0.029, 0.119],
                    [-0.02, 0.112],
                    [-0.065, 0.092],
                    [0, 0]
                  ],
                  "v": [
                    [2.791, -0.184],
                    [2.451, -0.047],
                    [2.085, -0.061],
                    [0.037, 0.15],
                    [-1.518, 1.459],
                    [-2.636, 1.83],
                    [-2.753, 1.741],
                    [-3.008, 1.511],
                    [-3.133, 1.19],
                    [-3.045, 0.53],
                    [-0.657, -1.525],
                    [2.472, -1.79],
                    [2.779, -1.659],
                    [3.001, -1.407],
                    [3.159, -1.08],
                    [3.167, -0.716],
                    [3.037, -0.407],
                    [2.789, -0.183]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [1, 0.999998863071, 1, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [14.703, 11.333],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [1.227, -3.131],
                    [0.32, -0.515],
                    [2.381, -1.03],
                    [3.106, 1.236],
                    [0.691, 0.411],
                    [0.925, 2.232],
                    [-0.581, 2.547],
                    [-0.233, 0.558],
                    [-3.05, 1.304],
                    [0, 0],
                    [-0.673, 0.186],
                    [-1.576, -0.373],
                    [-0.724, -0.309],
                    [-1.328, -3.084]
                  ],
                  "o": [
                    [1.339, 3.048],
                    [-0.221, 0.565],
                    [-1.305, 2.255],
                    [-3.03, 1.353],
                    [-0.74, -0.315],
                    [-2.036, -1.281],
                    [-1.043, -2.392],
                    [0.139, -0.589],
                    [1.241, -3.165],
                    [0, 0],
                    [0.643, -0.274],
                    [1.579, -0.361],
                    [0.776, 0.131],
                    [3.072, 1.222],
                    [0, 0]
                  ],
                  "v": [
                    [11.061, -4.895],
                    [11.229, 4.374],
                    [10.416, 5.998],
                    [4.74, 11.057],
                    [-4.463, 11.232],
                    [-6.612, 10.141],
                    [-11.164, 4.741],
                    [-11.874, -2.864],
                    [-11.314, -4.588],
                    [-4.877, -11.291],
                    [-4.596, -11.416],
                    [-2.62, -12.107],
                    [2.175, -12.088],
                    [4.433, -11.426],
                    [11.034, -4.967]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.262970539168, 0.629775282916, 0.483337043313, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [17.237, 17.559],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 8",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 900.000036657751,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "sun Outlines",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [0.833],
                "y": [0.833]
              },
              "o": {
                "x": [0.167],
                "y": [0.167]
              },
              "t": 0,
              "s": [0]
            },
            {
              "t": 206.000008390552,
              "s": [360]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [199.5, 168, 0],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [17.5, 17.5, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [242, 242, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0.122, -0.021],
                    [0.12, 0.03],
                    [0.649, -0.264],
                    [0.368, -0.591],
                    [0.477, 0.204],
                    [0, 0],
                    [0.066, 0.096],
                    [0.016, 0.115],
                    [-0.114, 0.2],
                    [-0.99, 0.416],
                    [-1.045, -0.244],
                    [-0.092, -0.066],
                    [-0.055, -0.099],
                    [-0.029, -0.119],
                    [0.024, -0.121],
                    [0.065, -0.092],
                    [0.098, -0.055]
                  ],
                  "o": [
                    [-0.103, 0.069],
                    [-0.122, 0.021],
                    [-0.688, -0.126],
                    [-0.643, 0.259],
                    [-0.268, 0.452],
                    [0, 0],
                    [-0.102, -0.056],
                    [-0.066, -0.096],
                    [-0.058, -0.223],
                    [0.562, -0.92],
                    [0.99, -0.416],
                    [0.111, 0.02],
                    [0.092, 0.066],
                    [0.076, 0.096],
                    [0.029, 0.119],
                    [-0.02, 0.112],
                    [-0.065, 0.092],
                    [0, 0]
                  ],
                  "v": [
                    [2.791, -0.184],
                    [2.451, -0.047],
                    [2.085, -0.061],
                    [0.037, 0.15],
                    [-1.518, 1.459],
                    [-2.636, 1.83],
                    [-2.753, 1.741],
                    [-3.008, 1.511],
                    [-3.133, 1.19],
                    [-3.045, 0.53],
                    [-0.657, -1.525],
                    [2.472, -1.79],
                    [2.779, -1.659],
                    [3.001, -1.407],
                    [3.159, -1.08],
                    [3.167, -0.716],
                    [3.037, -0.407],
                    [2.789, -0.183]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [1, 0.999998863071, 1, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [14.703, 11.333],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [-0.015, -0.185],
                    [0.059, -0.176],
                    [0.124, -0.128],
                    [0.166, -0.061],
                    [0, 0],
                    [0.184, -0.016],
                    [0.175, 0.058],
                    [0.136, 0.129],
                    [0.06, 0.178],
                    [0.007, 0.182],
                    [-0.065, 0.17],
                    [-0.114, 0.13],
                    [-0.156, 0.074],
                    [0, 0],
                    [-0.343, -0.119],
                    [-0.129, -0.115],
                    [-0.073, -0.157]
                  ],
                  "o": [
                    [0.087, 0.164],
                    [0.015, 0.185],
                    [-0.057, 0.169],
                    [-0.124, 0.128],
                    [0, 0],
                    [-0.163, 0.087],
                    [-0.184, 0.016],
                    [-0.18, -0.05],
                    [-0.136, -0.129],
                    [-0.077, -0.165],
                    [-0.007, -0.182],
                    [0.054, -0.165],
                    [0.114, -0.13],
                    [0, 0],
                    [0.333, -0.143],
                    [0.163, 0.054],
                    [0.129, 0.115],
                    [0, 0]
                  ],
                  "v": [
                    [2.422, -1.164],
                    [2.578, -0.635],
                    [2.511, -0.087],
                    [2.237, 0.364],
                    [1.796, 0.65],
                    [-0.613, 1.781],
                    [-1.138, 1.936],
                    [-1.681, 1.871],
                    [-2.161, 1.598],
                    [-2.459, 1.132],
                    [-2.586, 0.607],
                    [-2.498, 0.074],
                    [-2.242, -0.373],
                    [-1.833, -0.682],
                    [0.624, -1.795],
                    [1.675, -1.832],
                    [2.118, -1.576],
                    [2.424, -1.164]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.262970539168, 0.629775282916, 0.483337043313, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [3.188, 24.323],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 2",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-0.352, -0.146],
                    [0, 0],
                    [-0.124, -0.12],
                    [-0.066, -0.16],
                    [-0.008, -0.185],
                    [0.065, -0.173],
                    [0.322, -0.141],
                    [0.338, 0.099],
                    [0, 0],
                    [0.138, 0.136],
                    [0.073, 0.18],
                    [0.003, 0.174],
                    [-0.066, 0.161],
                    [-0.327, 0.16]
                  ],
                  "o": [
                    [0, 0],
                    [0.161, 0.061],
                    [0.124, 0.121],
                    [0.079, 0.167],
                    [0.008, 0.185],
                    [-0.159, 0.316],
                    [-0.323, 0.141],
                    [0, 0],
                    [-0.18, -0.07],
                    [-0.138, -0.136],
                    [-0.07, -0.159],
                    [-0.003, -0.174],
                    [0.134, -0.34],
                    [0.358, -0.13]
                  ],
                  "v": [
                    [-0.718, -1.741],
                    [1.772, -0.754],
                    [2.205, -0.479],
                    [2.493, -0.053],
                    [2.625, 0.482],
                    [2.538, 1.025],
                    [1.792, 1.732],
                    [0.771, 1.797],
                    [-1.72, 0.812],
                    [-2.202, 0.5],
                    [-2.522, 0.021],
                    [-2.632, -0.482],
                    [-2.537, -0.989],
                    [-1.821, -1.766]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.262970539168, 0.629775282916, 0.483337043313, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [2.633, 11.642],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 3",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0.002, 0.173],
                    [-0.066, 0.16],
                    [-0.123, 0.134],
                    [-0.166, 0.073],
                    [-0.181, -0.001],
                    [-0.165, -0.074],
                    [-0.133, -0.124],
                    [-0.073, -0.167],
                    [0, 0],
                    [-0.007, -0.185],
                    [0.066, -0.173],
                    [0.132, -0.13],
                    [0.175, -0.06],
                    [0.184, -0.008],
                    [0.172, 0.067],
                    [0.121, 0.129],
                    [0.06, 0.166]
                  ],
                  "o": [
                    [-0.07, -0.158],
                    [-0.003, -0.173],
                    [0.06, -0.172],
                    [0.123, -0.134],
                    [0.166, -0.073],
                    [0.181, 0.001],
                    [0.171, 0.06],
                    [0.133, 0.124],
                    [0, 0],
                    [0.079, 0.167],
                    [0.007, 0.185],
                    [-0.064, 0.175],
                    [-0.132, 0.13],
                    [-0.166, 0.081],
                    [-0.184, 0.008],
                    [-0.161, -0.071],
                    [-0.12, -0.129],
                    [0, 0]
                  ],
                  "v": [
                    [-1.807, -0.756],
                    [-1.918, -1.258],
                    [-1.822, -1.764],
                    [-1.545, -2.228],
                    [-1.106, -2.54],
                    [-0.58, -2.649],
                    [-0.055, -2.536],
                    [0.405, -2.257],
                    [0.717, -1.816],
                    [1.782, 0.676],
                    [1.913, 1.211],
                    [1.825, 1.754],
                    [1.527, 2.218],
                    [1.059, 2.507],
                    [0.529, 2.642],
                    [-0.011, 2.553],
                    [-0.438, 2.25],
                    [-0.712, 1.802]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.262970539168, 0.629775282916, 0.483337043313, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [10.997, 2.987],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 4",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0.143, 0.13],
                    [0.081, 0.177],
                    [0.01, 0.173],
                    [-0.059, 0.163],
                    [0, 0],
                    [-0.121, 0.148],
                    [-0.171, 0.084],
                    [-0.19, 0.004],
                    [-0.174, -0.077],
                    [-0.129, -0.115],
                    [-0.073, -0.157],
                    [-0.012, -0.194],
                    [0.065, -0.183],
                    [0, 0],
                    [0.317, -0.155],
                    [0.342, 0.085]
                  ],
                  "o": [
                    [-0.183, -0.062],
                    [-0.143, -0.13],
                    [-0.077, -0.155],
                    [-0.01, -0.173],
                    [0, 0],
                    [0.048, -0.185],
                    [0.121, -0.148],
                    [0.171, -0.084],
                    [0.19, -0.004],
                    [0.164, 0.054],
                    [0.129, 0.115],
                    [0.087, 0.173],
                    [0.012, 0.194],
                    [0, 0],
                    [-0.145, 0.323],
                    [-0.317, 0.155],
                    [0, 0]
                  ],
                  "v": [
                    [-0.873, 2.657],
                    [-1.368, 2.364],
                    [-1.707, 1.898],
                    [-1.838, 1.401],
                    [-1.764, 0.891],
                    [-0.869, -1.699],
                    [-0.612, -2.205],
                    [-0.168, -2.557],
                    [0.381, -2.689],
                    [0.935, -2.579],
                    [1.379, -2.322],
                    [1.686, -1.909],
                    [1.836, -1.352],
                    [1.756, -0.78],
                    [0.861, 1.802],
                    [0.146, 2.541],
                    [-0.873, 2.649]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.262970539168, 0.629775282916, 0.483337043313, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [12.154, 32.307],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 5",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 5,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0.124, -0.128],
                    [0.166, -0.062],
                    [0.175, -0.018],
                    [0.168, 0.052],
                    [0.144, 0.13],
                    [0.081, 0.177],
                    [0, 0],
                    [0.01, 0.173],
                    [-0.06, 0.163],
                    [-0.117, 0.139],
                    [-0.163, 0.079],
                    [-0.181, 0.007],
                    [-0.168, -0.067],
                    [-0.129, -0.115],
                    [-0.073, -0.157],
                    [0, 0],
                    [-0.015, -0.185],
                    [0.058, -0.176]
                  ],
                  "o": [
                    [-0.057, 0.169],
                    [-0.124, 0.127],
                    [-0.154, 0.086],
                    [-0.175, 0.018],
                    [-0.183, -0.062],
                    [-0.144, -0.13],
                    [0, 0],
                    [-0.078, -0.155],
                    [-0.01, -0.173],
                    [0.052, -0.175],
                    [0.117, -0.139],
                    [0.163, -0.079],
                    [0.181, -0.007],
                    [0.164, 0.054],
                    [0.129, 0.115],
                    [0, 0],
                    [0.087, 0.164],
                    [0.015, 0.185],
                    [0, 0]
                  ],
                  "v": [
                    [1.898, 1.699],
                    [1.622, 2.149],
                    [1.182, 2.436],
                    [0.684, 2.594],
                    [0.164, 2.543],
                    [-0.332, 2.252],
                    [-0.672, 1.787],
                    [-1.84, -0.662],
                    [-1.972, -1.16],
                    [-1.896, -1.669],
                    [-1.639, -2.145],
                    [-1.214, -2.476],
                    [-0.692, -2.606],
                    [-0.163, -2.514],
                    [0.281, -2.258],
                    [0.586, -1.846],
                    [1.812, 0.617],
                    [1.967, 1.147],
                    [1.901, 1.695]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.262970539168, 0.629775282916, 0.483337043313, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [24.133, 31.637],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 6",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 6,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [-0.015, -0.185],
                    [0.059, -0.176],
                    [0.124, -0.128],
                    [0.166, -0.062],
                    [0.185, -0.015],
                    [0.173, 0.067],
                    [0, 0],
                    [0.134, 0.127],
                    [0.073, 0.17],
                    [0.01, 0.173],
                    [-0.059, 0.163],
                    [-0.118, 0.139],
                    [-0.163, 0.079],
                    [-0.181, 0.007],
                    [-0.168, -0.067],
                    [0, 0],
                    [-0.137, -0.118],
                    [-0.079, -0.163]
                  ],
                  "o": [
                    [0.087, 0.164],
                    [0.015, 0.185],
                    [-0.057, 0.169],
                    [-0.124, 0.128],
                    [-0.16, 0.094],
                    [-0.185, 0.015],
                    [0, 0],
                    [-0.172, -0.064],
                    [-0.134, -0.127],
                    [-0.077, -0.155],
                    [-0.01, -0.173],
                    [0.052, -0.174],
                    [0.117, -0.139],
                    [0.163, -0.079],
                    [0.181, -0.007],
                    [0, 0],
                    [0.172, 0.054],
                    [0.137, 0.118],
                    [0, 0]
                  ],
                  "v": [
                    [2.539, -0.146],
                    [2.694, 0.383],
                    [2.628, 0.93],
                    [2.353, 1.381],
                    [1.912, 1.669],
                    [1.388, 1.835],
                    [0.844, 1.757],
                    [-1.78, 0.838],
                    [-2.244, 0.549],
                    [-2.558, 0.099],
                    [-2.689, -0.399],
                    [-2.615, -0.908],
                    [-2.357, -1.383],
                    [-1.932, -1.713],
                    [-1.411, -1.844],
                    [-0.881, -1.753],
                    [1.743, -0.834],
                    [2.211, -0.572],
                    [2.539, -0.146]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.262970539168, 0.629775282916, 0.483337043313, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [32.141, 22.628],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 7",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 7,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [-0.337, -0.134],
                    [-0.158, -0.329],
                    [-0.011, -0.178],
                    [0.066, -0.165],
                    [0.132, -0.13],
                    [0.175, -0.061],
                    [0, 0],
                    [0.173, -0.003],
                    [0.157, 0.074],
                    [0.132, 0.124],
                    [0.072, 0.167],
                    [-0.001, 0.182],
                    [-0.073, 0.167],
                    [-0.327, 0.16]
                  ],
                  "o": [
                    [0, 0],
                    [0.339, -0.131],
                    [0.337, 0.134],
                    [0.087, 0.156],
                    [0.011, 0.178],
                    [-0.064, 0.175],
                    [-0.132, 0.13],
                    [0, 0],
                    [-0.155, 0.079],
                    [-0.173, 0.003],
                    [-0.171, -0.06],
                    [-0.133, -0.124],
                    [-0.072, -0.167],
                    [0.001, -0.182],
                    [0.135, -0.34],
                    [0, 0]
                  ],
                  "v": [
                    [-1.787, -0.714],
                    [0.636, -1.815],
                    [1.688, -1.811],
                    [2.458, -1.091],
                    [2.606, -0.584],
                    [2.522, -0.062],
                    [2.223, 0.4],
                    [1.757, 0.69],
                    [-0.737, 1.82],
                    [-1.236, 1.944],
                    [-1.739, 1.836],
                    [-2.199, 1.557],
                    [-2.509, 1.115],
                    [-2.616, 0.585],
                    [-2.504, 0.057],
                    [-1.787, -0.72]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.262970539168, 0.629775282916, 0.483337043313, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [31.582, 11.201],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 9",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 8,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0.129, -0.122],
                    [0.169, -0.055],
                    [0.177, -0.011],
                    [0.164, 0.068],
                    [0.138, 0.136],
                    [0.073, 0.18],
                    [0.003, 0.173],
                    [-0.066, 0.16],
                    [0, 0],
                    [-0.327, 0.16],
                    [-0.351, -0.148],
                    [-0.124, -0.12],
                    [-0.067, -0.16],
                    [-0.008, -0.185],
                    [0.065, -0.173]
                  ],
                  "o": [
                    [0, 0],
                    [-0.064, 0.166],
                    [-0.129, 0.122],
                    [-0.154, 0.088],
                    [-0.177, 0.011],
                    [-0.18, -0.069],
                    [-0.138, -0.136],
                    [-0.071, -0.158],
                    [-0.003, -0.173],
                    [0, 0],
                    [0.134, -0.34],
                    [0.357, -0.131],
                    [0.161, 0.061],
                    [0.124, 0.12],
                    [0.079, 0.167],
                    [0.008, 0.185],
                    [0, 0]
                  ],
                  "v": [
                    [1.795, -0.765],
                    [0.793, 1.789],
                    [0.5, 2.227],
                    [0.048, 2.496],
                    [-0.455, 2.647],
                    [-0.973, 2.561],
                    [-1.456, 2.25],
                    [-1.776, 1.771],
                    [-1.887, 1.268],
                    [-1.791, 0.762],
                    [-0.789, -1.789],
                    [-0.073, -2.567],
                    [1.028, -2.541],
                    [1.461, -2.267],
                    [1.75, -1.842],
                    [1.882, -1.308],
                    [1.795, -0.765]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.262970539168, 0.629775282916, 0.483337043313, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [22.94, 2.81],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 10",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 9,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 900.000036657751,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
