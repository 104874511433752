import cookie from 'cookie';
import { USER_ID_COOKIE_KEY } from './constants';

export const UserGroup = {
  ADMIN: 'admin',
  SSC_USER: 'ssc_user',
  FIN_OPS: 'fin_ops',
  LOAN_OPS: 'loan_ops',
  UW: 'uw',
  LOAN_OPS_UW: 'loan_ops_uw',
  LOAN_OPS_UW_PLUS: 'loan_ops_uw_plus',
  BIZ_DEV: 'bd',
  BORROWER_SUCCESS: 'borrower_success',
};

export const getGroup = () => {
  const cookies = cookie.parse(document.cookie);
  return cookies['user_group']?.split('.')[0];
};
export const isLoggedIn = () => {
  return document.cookie.indexOf('user=') >= 0;
};

export const getUserId = () => {
  const cookies = cookie.parse(document.cookie);
  const userId = cookies[USER_ID_COOKIE_KEY]?.split('.')[0];
  if (!userId) {
    console.error('User ID is not set');
    return 0;
  }
  return parseInt(userId);
};

export const getUserName = () => {
  const cookies = cookie.parse(document.cookie);
  const name = cookies['name'];
  return name ? decodeURI(name.split('.')[0]) : undefined;
};

export const isGroupsUser = (groups: string[]) => {
  const group = getGroup() || '';
  if (group === UserGroup.ADMIN || group === UserGroup.SSC_USER) return true;

  return groups.includes(group);
};

export const isLoanOpsUser = () => {
  return isGroupsUser([UserGroup.LOAN_OPS, UserGroup.LOAN_OPS_UW, UserGroup.LOAN_OPS_UW_PLUS]);
};

export const isLoanOpsUwPlusUser = () => {
  return isGroupsUser([UserGroup.LOAN_OPS_UW_PLUS]);
};

export const isFinOpsUser = () => {
  return isGroupsUser([UserGroup.FIN_OPS]);
};

export const isFinOpsOnly = () => {
  const group = getGroup();
  return group ? group.includes(UserGroup.FIN_OPS) : false;
};

export const isUwUser = () => {
  return isGroupsUser([UserGroup.UW, UserGroup.LOAN_OPS_UW, UserGroup.LOAN_OPS_UW_PLUS]);
};

export const isUWOrBDUser = () => {
  return isGroupsUser([
    UserGroup.UW,
    UserGroup.LOAN_OPS_UW,
    UserGroup.LOAN_OPS_UW_PLUS,
    UserGroup.BIZ_DEV,
    UserGroup.ADMIN,
  ]);
};
export const isUnderwriter = () =>
  isGroupsUser([UserGroup.UW, UserGroup.LOAN_OPS_UW, UserGroup.LOAN_OPS_UW_PLUS]);

export const isSscUser = () =>
  getGroup() === UserGroup.ADMIN || getGroup() === UserGroup.SSC_USER;

export const isAdmin = () => getGroup() === UserGroup.ADMIN;

export const logout = () => {
  const path = 'path=/';
  const expires = 'expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = `user=0;${path};${expires}`;
  document.cookie = `user_group=0;${path};${expires}`;
  document.cookie = `name=0;${path};${expires}`;
};
