import { SFLoanApplication } from '@backend/types/Loan';
import { Alert, Box, Button, Checkbox, CircularProgress, FormControlLabel, MenuItem, Modal, Paper, TextField, Typography, Tooltip } from '@mui/material';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { ShowIf } from '../../components/ConditionalRender/ShowIf';
import { FilePreviewDrawer } from '../../components/FilePreviewDrawer';
import { SelectRequireDocumentCard } from '../../components/SelectRequireDocumentCard';
import { useRequiredDocuments } from '../../contexts/RequiredDocumentsContext';
import { getUserId, isLoanOpsUser } from '../../helpers/auth';
import { RequiredDocumentApprovalStatus } from '../../helpers/constants';
import { setCRBFileName, setNonCRBFileName } from '../../helpers/helpers';
import { Bank, BankLoanHistory, RequiredDocument, RequiredDocumentWithConventionName, TransmissionReason } from '../../helpers/types';
import { useWatchHeight } from '../../hooks/useWatchHeight';
import { getBankLoanHistory, getFile, sendFilesToBank } from '../../services/requiredDocumentsService';



export const SendFilesToBank = () => {
  const app = useOutletContext() as SFLoanApplication;

  const appId = app.Id;

  const userId = getUserId();

  const [bankLoanGUID, setBankLoanGUID] = useState<BankLoanHistory['bank_loan_guid']>('');
  const [loading, setLoading] = useState(false);

  const [sortBy, setSortBy] = useState<string>('default');

  const [bankName, setBankName] = useState<Bank | ''>('');
  const [transmissionReason, setTransmissionReason] = useState<TransmissionReason | ''>('');
  const [comments, setComments] = useState<string | undefined>(undefined);

  const [error, setError] = useState<React.ReactNode | undefined>(undefined);
  const [sendingFiles, setSendingFiles] = useState(false);

  const {
    state: {
      requiredDocuments,
      loanSummaryExpanded,
      openedFiles,
    },
    getRequiredDocuments,
    resetOpenedFiles,
  } = useRequiredDocuments();

  const [filePreviewDrawerOpen, setFilePreviewDrawerOpen] = useState(false);
  const loanSummaryHeight = useWatchHeight('bankLoanSummaryHeader', 100, [loanSummaryExpanded]);

  const docsForSendingToBank = useMemo(() => {
    return requiredDocuments.filter((doc) => doc.files.length > 0 && [RequiredDocumentApprovalStatus.VERIFIED, RequiredDocumentApprovalStatus.SENT_TO_BANK].includes(doc.approval_status));
  }, [requiredDocuments]);

  const docsForSendingToBankWithConventionName = useMemo(() => {
    const crbAbbrevCount: Record<string, number> = {};
    const relatedPartyIndex: Record<string, number> = {};

    return docsForSendingToBank.map((doc) => {
      const crbAbbrev = doc.document_type.crb_abbrev;
      const docTypeName = doc.document_type.name;
      const currentCount = crbAbbrevCount[crbAbbrev] || 0;
      const subType = doc.sub_type; // This will determine if the document is PG, CG, or Borrower, which we will use to increment +1 the file name if different parent_id
      // Each different parent_id is a different related party (Expected result example: PG1_PID, PG2_PID -PID = Principal ID-)
      if (!(doc.parent_id in relatedPartyIndex)) {
        relatedPartyIndex[doc.parent_id] = Object.keys(relatedPartyIndex).length + 1;
      }
      const currentRelatedPartyIndex = relatedPartyIndex[doc.parent_id];

      crbAbbrevCount[crbAbbrev] = currentCount + 1;

      const file = doc.files?.find((file) => file?.is_current) || doc.files[0];
      const count = currentCount > 0 ? currentCount : undefined;

      const conventionName = setNonCRBFileName(app.Name, docTypeName, crbAbbrev, file, count, subType, currentRelatedPartyIndex);

      let crbConventionName = '';
      if (bankLoanGUID) {
        crbConventionName = setCRBFileName(bankLoanGUID, crbAbbrev, file, count, subType, currentRelatedPartyIndex);
      }

      return {
        ...doc,
        convention_name: conventionName,
        crb_convention_name: crbConventionName
      };
    });
  }, [docsForSendingToBank, bankLoanGUID]);

  const docsForSendingToBankSorted = useMemo(() => {
    switch (sortBy) {
      case 'default':
        return docsForSendingToBankWithConventionName;
      case 'document_approval_source':
        return [...docsForSendingToBankWithConventionName].sort((a, b) =>
          a.document_approval_source.localeCompare(b.document_approval_source)
        );

      case 'document_type':
        return [...docsForSendingToBankWithConventionName].sort((a, b) =>
          a.document_type.name.localeCompare(b.document_type.name)
        );
      case 'approval_status':
        return docsForSendingToBankWithConventionName.sort((a, b) =>
          a.approval_status.localeCompare(b.approval_status)
        );
      default:
        return docsForSendingToBankWithConventionName;
    }
  }, [docsForSendingToBankWithConventionName, sortBy]);

  const [selectedDocs, setSelectedDocs] = useState<Record<RequiredDocument['id'], RequiredDocumentWithConventionName>>({});

  const [fileIds, setFileIds] = useState<string[]>([]);

  const handleSelectDoc = (doc: RequiredDocumentWithConventionName) => {
    setSelectedDocs(prev => {
      const isSelected = !!prev[doc.id];
      const updatedDocs = isSelected
        ? Object.fromEntries(Object.entries(prev).filter(([key]) => key !== doc.id.toString()))
        : { ...prev, [doc.id]: doc };

      setFileIds(prevFileIds => {
        const currentFile = doc.files.find(file => file?.is_current) || doc.files[0];
        if (!currentFile) return prevFileIds;

        return isSelected
          ? prevFileIds.filter(fileId => fileId !== currentFile.id)
          : [...prevFileIds, currentFile.id];
      });

      return updatedDocs;
    });
  };

  const clearFields = () => {
    setSelectedDocs({});
    setFileIds([]);
    setBankName('');
    setTransmissionReason('');
    setComments('');
  }

  const handleCRBError = () => {
    setError(
      <>
        A new loan to CRB must be created first.{' '}
        <Link to={`/loans/application/${appId}`} style={{ textDecoration: 'underline', color: 'blue' }}>
          Create a Loan here.
        </Link>
      </>
    );
  };


  const handleSendFilesToBank = async () => {

    setError(undefined);
    setSendingFiles(true)

    if (!bankName || !transmissionReason || fileIds.length === 0) {
      setError('Please fill in all fields (Bank, Transmission Reason, and at least one document selected is required).');
      setSendingFiles(false);
      return;
    };

    try {
      if (bankName === Bank.CRB && !bankLoanGUID) {
        handleCRBError()
        setSendingFiles(false);
        return;
      }

      await sendFilesToBank(
        appId,
        bankName,
        transmissionReason,
        userId,
        fileIds,
        selectedDocs,
        bankLoanGUID,
        comments
      );

      // Refresh the required documents
      await getRequiredDocuments(appId);

    } catch (error: any) {
      setError(error.message || 'Failed to send files to bank.');
      setSendingFiles(false);
    }


    if (bankName !== Bank.CRB) {
      handleDownloadDocuments()
    }

    // Reset fields
    clearFields();

    setSendingFiles(false);
  };


  const handleDownloadDocuments = async () => {
    const zip = new JSZip();
    try {
      const fetchFilePromises = Object.values(selectedDocs).map(async (doc) => {
        const currentFile = doc.files.find(file => file.is_current) || doc.files[0];

        if (!fileIds.includes(currentFile.id)) {
          setError('Please select at least one document to download.');
          return;
        };

        const response = await getFile(currentFile.id);
        const blob = new Blob([response.data]);
        zip.file(doc.convention_name, blob);

      });

      await Promise.all(fetchFilePromises);
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, `${app.Name}_documents.zip`);
    } catch (error) {
      console.error("Failed to download documents:", error);
      setError("Error downloading files.");
    }
  }


  useEffect(() => {

    getRequiredDocuments(appId);

    const fetchBankLoanGUID = async () => {
      setLoading(true);
      try {
        const history = await getBankLoanHistory(appId, true); // This boolean is 'fetchLast' which prevents from fetching the one with action: CREATED, and fetches the whole history in desc order
        setBankLoanGUID(history[0]?.bank_loan_guid); // This will always get the bank loan GUID from the bank loan history with the latest date cause they are sorted in desc order
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBankLoanGUID();
  }, [appId]);

  useEffect(() => {
    if (openedFiles.length > 0) {
      setFilePreviewDrawerOpen(true)
    }
  }, [openedFiles])

  useEffect(() => {
    // this acts as a destructor of the component
    return () => {
      resetOpenedFiles();
      setFilePreviewDrawerOpen(false);
    }
  }, [])

  useEffect(() => {
    // Initializing the selectedDocs and fileIds with the verified documents
    const verifiedDocs = docsForSendingToBankSorted.filter(
      (doc) => doc.approval_status === RequiredDocumentApprovalStatus.VERIFIED
    );

    setSelectedDocs(verifiedDocs.reduce((acc, doc) => ({ ...acc, [doc.id]: doc }), {}));
    setFileIds(verifiedDocs.map(doc => doc.files.find(file => file.is_current)!.id));
  }, [requiredDocuments]);

  useEffect(() => {
    setError(undefined)
  }, [bankName, transmissionReason, fileIds])


  return (
    <>
      <ShowIf every={[loading]}>
        <Box className="content"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
          }}
        >
          <CircularProgress />
        </Box>
      </ShowIf>
      <ShowIf every={[!loading]}>
        <>
          {error && (
            <Box sx={{ px: '2rem', pb: '1rem', display: 'flex', justifyContent: 'center', pt: 2 }}>
              <Box sx={{
                width: 'fit-content',
                border: '2px solid red',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Alert severity="error"
                  onClose={() => setError(undefined)}
                >{error}</Alert>
              </Box>
            </Box>
          )}
          <Box className="content"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              px: '2rem',
              width: filePreviewDrawerOpen ? '55%' : '95.5%',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h5">Send to Bank</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', my: 1 }}>
                <TextField
                  select
                  label="Sort by"
                  id="sort-by"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  fullWidth
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                    width: '12rem',
                    '& .MuiSelect-select': {
                      py: '5px',
                    },
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true, // This ensures the label is always at the top
                  }}
                >
                  <MenuItem value="default">Default</MenuItem>
                  <MenuItem value="updated_at">Verification Date</MenuItem>
                  <MenuItem value="document_type">Document Type</MenuItem>
                  <MenuItem value="approval_status">Status</MenuItem>
                </TextField>
              </Box>
            </Box>
            <Paper variant="outlined" className="content flex-column flex-gap-2">
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 1 }}>
                <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>All Documents</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Object.keys(selectedDocs).length === docsForSendingToBankSorted.length}
                      onChange={() => {
                        if (Object.keys(selectedDocs).length === docsForSendingToBankSorted.length) {
                          setSelectedDocs({});
                          setFileIds([]);
                        } else {
                          setSelectedDocs(docsForSendingToBankSorted.reduce((acc, doc) => ({ ...acc, [doc.id]: doc }), {}));
                          setFileIds(docsForSendingToBankSorted.map(doc => doc.files.find(file => file.is_current)!.id));
                        }
                      }}
                      sx={{ p: 0 }}
                    />
                  }
                  label={<Typography sx={{ fontWeight: 700, fontSize: '14px', }}>Select All</Typography>}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, m: 0, }}
                />
              </Box>
              {docsForSendingToBankSorted.map((doc) => (
                doc.files.length > 0 && (
                  <SelectRequireDocumentCard
                    key={doc.id}
                    document={doc}
                    file={doc.files.find(file => file.is_current) || doc.files[0]}
                    selected={!!selectedDocs[doc.id]}
                    onSelect={() => handleSelectDoc(doc)}
                  />
                )
              ))}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <TextField
                    label="Bank"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value as Bank)}
                    select
                    sx={{
                      width: .5,
                    }}
                    disabled={sendingFiles}
                  >
                    <MenuItem key={'none'} value={''} disabled>Select Bank</MenuItem>
                    {Object.values(Bank).map((bank) => (
                      <MenuItem key={bank} value={bank}>{bank}</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Transmission Reason"
                    value={transmissionReason}
                    onChange={(e) => setTransmissionReason(e.target.value as TransmissionReason)}
                    select
                    sx={{
                      width: .5,
                    }}
                    disabled={sendingFiles}
                  >
                    <MenuItem key={'none'} value={''} disabled>Select Transmission Reason</MenuItem>
                    {Object.values(TransmissionReason).map((reason) => (
                      <MenuItem key={reason} value={reason}>{reason}</MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box>
                  <TextField
                    label="Description"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    multiline
                    rows={4}
                    sx={{
                      width: 1,
                    }}
                    disabled={sendingFiles}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <Button variant="outlined" onClick={handleDownloadDocuments} disabled={sendingFiles || fileIds.length === 0}>Download Documents</Button>
                  <Tooltip title={!isLoanOpsUser() ? 'Only LoanOps users can send files to bank.' : ''}>
                    <Typography>
                      <Button
                        variant="contained"
                        onClick={handleSendFilesToBank}
                        disabled={!bankName || !transmissionReason || fileIds.length === 0 || sendingFiles || !isLoanOpsUser()}
                        sx={{
                          width: 'fit-content',
                        }}
                      >
                        {[Bank.CRB, ''].includes(bankName) ? 'Send to Bank' : 'Send & Download documents'}
                      </Button>
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
          </Box>
          {filePreviewDrawerOpen && (
            <FilePreviewDrawer
              isDrawerOpen={filePreviewDrawerOpen}
              toggleDrawer={() => setFilePreviewDrawerOpen(!filePreviewDrawerOpen)}
              customSx={{
                height: `calc(100% - ${loanSummaryHeight + 60}px)`,
                position: 'fixed',
                top: 0,
                bottom: 'unset',
                zIndex: 20,
                marginTop: `${loanSummaryHeight + 60}px`,
              }}
            />
          )}
        </>
      </ShowIf>
      <Modal
        open={sendingFiles}
        aria-labelledby="sending-files-modal"
        aria-describedby="sending-files-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <CircularProgress />
          <Typography id="sending-files-description" sx={{ mt: 2 }}>
            Sending files to Bank. Please wait...
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
