import { DocumentApprovalStatus, CurrentFileStatus } from "@backend/constants";
import { UserGroups, RequiredFor, RequiredDocumentSubType, FileUploadSource } from "./constants";

export interface RequiredDocument {
    id: string;
    approval_date?: Date;
    approval_status: DocumentApprovalStatus;
    approved_by_user_username?: string;
    approved_by_user?: SsoUsers;
    can_upload?: keyof typeof UserGroups;
    can_view?: keyof typeof UserGroups;
    current_file_status: CurrentFileStatus;
    created_at: Date;
    created_by: string;
    created_by_user_id?: number;
    created_by_user?: SsoUsers;
    document_approval_source: DocumentApprovalSource;
    document_type_id: string;
    document_type: DocumentType;
    files: FileType[];
    is_deleted: boolean;
    is_gallery: boolean;
    is_required?: boolean;
    is_single_file: boolean;
    month?: number;
    notes: Note[];
    owner_user_id?: number;
    owner?: SsoUsers;
    parent_id: string;
    required_for_step?: keyof typeof RequiredFor;
    requirement_description: string;
    requirement_name: string;
    requires_approval: boolean;
    sub_type?: keyof typeof RequiredDocumentSubType;
    sunstone_document_id: string;
    updated_at: Date;
    updated_by: string;
    year?: number;
}

export interface RequiredDocumentWithConventionName extends RequiredDocument {
  convention_name: string;
  crb_convention_name: string;
}

interface UwManualOverrides {
    id: number;
    parent_id: string;
    parent_type: string;
    name: string;
    value?: number;
    bool_value?: boolean;
    user_id: number;
    user: SsoUsers;
    created_at: Date;
}

interface SsoUsers {
    id: number;
    login?: LoginPassword;
    username: string;
    name: string;
    group: string;
    active: boolean;
    required_documents: RequiredDocument[];
    required_documents_created: RequiredDocument[];
    required_documents_owner: RequiredDocument[];
    overrides: UwManualOverrides[];
    file_actions_history: FileActionHistory[];
    user_file_verifications: UserFileVerification[];
    position: Position;
}

export enum DocumentApprovalSource {
  DOCUSIGN = 'DOCUSIGN',
  UPLOAD = 'UPLOAD',
}

interface LoginPassword {
    id: number;
    userId: number;
    user: SsoUsers;
    password: string;
  allowed: boolean;
}
    
export interface Note {
  id: number;
  body?: string;
  note_type: 'Internal' | 'External';
  required_document: RequiredDocument;
  created_by_user: SsoUsers;
  created_date: Date;
  updated_date?: string;
  updated_by_user?: {
      name: string;
  };
  is_deleted: boolean;
  deleted_date?: Date;
}

export interface Template {
  id: number;
  name: string;
  documentTypes: TemplateDocumentType[];
}

export interface TemplateDocumentType {
  id: number;
  template_id: number;
  template: Template;
  document_type_id: string;
  document_type: DocumentType;
  internal_notes: string;
  external_notes: string;
}

export interface DocumentType {
  id: string;
  name: string;
  crb_abbrev: string;
  system_name: string;
  required_documents: RequiredDocument[];
  is_required: boolean;
  is_active: boolean;
  templateDocumentTypes: TemplateDocumentType[];
  sd_category: SDCategory;
  archetypeDocumentTypes: ArchetypeDocumentType[];
}

export interface DocumentTypeWithFileUploadStatus extends DocumentType {
  file_status: DocumentApprovalStatus | null;
}

export enum SDCategory {
  SOLAR_DOCUMENTS = 'Solar Documents',
  KYC_DOCUMENTS = 'KYC Documents',
  BORROWER_FINANCIALS = 'Borrower Financials',
  PG_DOCUMENTS = 'PG Documents',
  BORROWER_BANK_STATEMENTS = 'Borrower Bank Statements',
  PROPERTY_DOCUMENTS = 'Property Documents',
  UW_OUTCOME = 'UW Outcome',
  UW_RESEARCH = 'UW Research',
  NPO_DOCUMENTS = 'NPO Documents',
}

export interface CustomDocumentType {
  name: DocumentType['name'] | string;
  isRequired: DocumentType['is_required'];
}

export interface OracleInsights {
  id: number;
  application_id: string;
  type: string;
  source: string;
  output: any;
  processed_date: Date;
  file_id?: string;
  file?: FileType;
}

export interface FileType {
  id: string;
  required_doc_id: string;
  required_document: RequiredDocument;
  s3_url: string;
  version_id: string;
  file_name: string;
  is_current: boolean;
  is_deleted: boolean;
  is_public: boolean;
  created_at: Date;
  created_by: string;
  upload_source: FileUploadSource;
  OracleInsights: OracleInsights[];
  file_actions_history: FileActionHistory[];
  user_file_verifications: UserFileVerification[];
  bank_transmission_files: BankTransmissionFile[];
}
    
export enum TemplateNames {
  DOCUMENT_TYPES = 'Document Types',
}

export interface Archetype {
  id: number;
  sf_api_name: string;
  display_name: string;
  archetypeDocumentTypes: ArchetypeDocumentType[];
  description: string;
}

export interface ArchetypeDocumentType {
  id: number;
  document_type_id: string;
  document_type: DocumentType;
  archetype_id: number;
  archetype: Archetype;
  conditions?: Record<string, any>;
}

export const archetypesMapping = {
  'express_opco': 'Express - OpCo',
  'express_propco': 'Express - PropCo',
  'express_plus_opco': 'Express+ - OpCo',
  'express_plus_propco': 'Express+ - PropCo',
  'core_opco': 'Core - OpCo',
  'core_propco': 'Core - PropCo',
  'npo_fbo': 'Non-Profit - FBO',
  'npo_hoa': 'Non-Profit - HOA',
  'npo_educational_institute': 'Non-Profit - Edu',
  'npo_other': 'Non-Profit - Other',
  'industry_agriculture_opco': 'Agriculture - OpCo',
  'industry_agriculture_propco': 'Agriculture - PropCo',
  'ppa_finance': 'PPA Finance',
}

export interface FileActionHistory {
  id: number;
  file_id: string;
  file: FileType;
  action: FileActionType;
  comment: string;
  user_id: number;
  user: SsoUsers;
  created_at: Date;
}

export interface UserFileVerification {
  id: number;
  user_id: number;
  user: SsoUsers;
  file_id: string;
  file: FileType;
  action: FileActionType;
  comment?: string;
  created_at: Date;
  updated_at: Date;
}

export enum FileActionType {
  VERIFY = 'Verify',
  DECLINE = 'Decline',
  DELETE = 'Delete',
}

export interface Position {
  id: number;
  display_name: string;
  description: string;
  created_at: Date;
  sso_users: SsoUsers[];
}

export const fileActionTakenMapping = {
  [FileActionType.VERIFY]: {display_name: 'Verified', color: '#2E9C7F', backgroundColor: '#D9EAE6'},
  [FileActionType.DECLINE]: {display_name: 'Declined', color: '#EB5757', backgroundColor: '#F9D4D4'},
}

export interface BankLoanHistory {
  id: string;
  application_id: string;
  action: string;
  created_at: Date;
  bank_loan_guid: string;
  loan_data: any;
  date: Date;
}

export enum Bank {
  CRB = 'CRB',
  DLL = 'DLL',
  MandT = 'MandT',
  Forbright = 'Forbright',
}

export enum TransmissionReason {
  InitialPackage = 'InitialPackage',
  ChangeOrder = 'ChangeOrder',
  UWRefresh = 'UWRefresh',
  PricingExpiration = 'PricingExpiration',
}

export interface BankTransmission {
  id: number;
  bank: Bank;
  transmission_reason: TransmissionReason;
  comments: string;
  user_id: number;
  user: SsoUsers;
  bank_transmission_files: BankTransmissionFile[];
  created_at: Date;
}

export interface BankTransmissionFile {
  id: number;
  file_id: string;
  file: FileType;
  bank_transmission_id: number;
  bank_transmission: BankTransmission;
  created_at: Date;
}
