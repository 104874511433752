import { Box, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const DataUploadersSideNav = () => {
  const path = useLocation().pathname;
  return (
    <Box
      component="nav"
      sx={{
        bgcolor: '#fff',
        display: 'flex',
        borderRight: '1px solid lightgrey',
        flexDirection: 'column',
        gap: '1em',
      }}
    >
      <List
        sx={{ p: '0', display: 'flex', flexDirection: 'column', width: '12em' }}
      >
        <Link
          to={`uw-tool-browser`}
          style={{ color: 'black', textDecoration: 'none' }}
        >
          <ListItem
            className={`side-nav-link ${path.includes('uw-tool-browser') ? 'side-nav-active' : ''
              }`}
          >
            <Typography variant="caption">UW Tool Browser</Typography>
          </ListItem>
        </Link>
        <Link
          to={`installer-scorecard`}
          style={{ color: 'black', textDecoration: 'none' }}
        >
          <ListItem
            className={`side-nav-link ${path.includes('installer-scorecard') ? 'side-nav-active' : ''
              }`}
          >
            <Typography variant="caption">Installer Scorecard</Typography>
          </ListItem>
        </Link>

      </List>
    </Box>
  );
};
