import { SSOUser } from '@backend/types/Auth';
import { FraudGroupType, FraudReport, FraudScorecard as FraudScorecardModel } from '@backend/types/FraudScorecard';
import { SFLoanApplication, SFRelatedParty } from '@backend/types/Loan';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { BetterAccordion } from '../../../components/BetterAccordion';
import { Loading } from '../../../components/Loading';
import { niceDate } from '../../../helpers/converters';
import { isOther } from '../../../helpers/fraudScorecardHelper';
import { generateFraudScorecard, regenerateFraudReport } from '../../../services/fraudScorecardService';
import { getUser } from '../../../services/usersService';
import FraudScorecardItem from './FraudScorecardItem';

interface FraudScorecardProps {
  scorecard: FraudScorecardModel;
  application: SFLoanApplication;
}

export const FraudScorecard: React.FC<FraudScorecardProps> = ({ scorecard, application }) => {

  const [user, setUser] = useState<SSOUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [generatingMessage, setGeneratingMessage] = useState('');
  const [currentScorecard, setCurrentScorecard] = useState<FraudScorecardModel | null>(scorecard);
  const [areYouSure, setAreYouSure] = useState(false);
  const [reportToRegenerate, setReportToRegenerate] = useState<FraudReport | null>(null);

  useEffect(() => {
    setCurrentScorecard(scorecard);
  }, [scorecard]);

  useEffect(() => {
    setLoading(true);
    getUser().then((ssoUser) => {
      setUser(ssoUser);
      setLoading(false);
    });
  }, []);

  const getItemStyles = () => {
    return {
      border: `1px solid #DCE1ED`,
      backgroundColor: '#FFFFFF',
      borderRadius: '9px',
      padding: '9px 13px 9px 13px',
      marginTop: '9px',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '110px',
      justifyContent: 'space-between',
    } as React.CSSProperties;
  };

  const getReportTitle = (report: FraudReport) => {
    if (isOther(report)) {
      return report.fraudReportTemplate.name
    } else if (report.uwReport) {
      return `${report.fraudReportTemplate.name} - ${niceDate(report.uwReport.generatedDate)} (${report.uwReportStatus})`;
    } else {
      return `${report.fraudReportTemplate.name} -  (${report.uwReportStatus})`;
    }
  };

  const getParentName = (parent: SFRelatedParty | null) => {
    return `${parent?.First_Name__c} ${parent?.Last_Name__c}`;
  };

  const generateScorecard = async () => {
    setGenerating(true);
    const result = await generateFraudScorecard(application, setGeneratingMessage);
    if (result) {
      setCurrentScorecard(result);
    }
    setGeneratingMessage('');
    setGenerating(false);
  };

  const regenerateReport = async (fraudScorecard: FraudScorecardModel, report: FraudReport) => {
    setGenerating(true);
    const result = await regenerateFraudReport(fraudScorecard, report);
    if (result) {
      setCurrentScorecard(result);
    }
    setGeneratingMessage('');
    setGenerating(false);
  };

  const handleYes = async () => {
    setAreYouSure(false);
    if (!currentScorecard || !reportToRegenerate) {
      throw new Error('No fraud scorecard or report to regenerate found');
    }
    await regenerateReport(currentScorecard, reportToRegenerate);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <BetterAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="fraud-scorecard-content"
        id="fraud-scorecard-header"
      >
        <Box className="flex-row-end flex-gap-0_5">
          <Typography variant="subtitle1">Fraud Scorecard{currentScorecard?.createdAt ? ` - ${niceDate(currentScorecard.createdAt, true)}` : ''}{currentScorecard?.createdByUser?.name ? ` - ${currentScorecard.createdByUser.name}` : ''}</Typography>
        </Box>
      </AccordionSummary>
      {currentScorecard === null ? (
        <AccordionDetails>
          <Box style={{ flexDirection: 'row', display: 'flex', alignItems: 'baseline' }}>
            <Button
              onClick={generateScorecard}
              sx={{ border: '2px solid #1976d2', borderRadius: '10px' }}
              disabled={generating}
            >
              {generating ? 'Generating...' : 'Generate Scorecard'}
            </Button>
            {generatingMessage && <Typography variant="body2" sx={{ marginLeft: '10px', color: '#1976d2' }}>{generatingMessage}</Typography>}
          </Box>
        </AccordionDetails>
      ) : (
        <AccordionDetails>
          {currentScorecard.fraudGroups.map((group) => (
            <Grid item xs={12} style={{ marginBottom: '15px' }}>
              {group.fraudGroupTemplate.type === FraudGroupType.BORROWER ? (
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Borrower: {(group.parent as SFLoanApplication)?.Borrower_Business_Name__c}</Typography>
              ) : group.fraudGroupTemplate.type === FraudGroupType.PERSONAL_GUARANTOR ? (
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Personal Guarantor: {getParentName(group.parent as SFRelatedParty)}</Typography>
              ) : group.fraudGroupTemplate.type === FraudGroupType.COMMERCIAL_GUARANTOR ? (
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Commercial Guarantor: {(group.parent as SFRelatedParty)?.Business_Name__c}</Typography>
              ) : (
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Beneficial Owner: {getParentName(group.parent as SFRelatedParty)}</Typography>
              )}
              {group.fraudReports.map((report, index) => (
                <Box style={getItemStyles()}>
                  <Box
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body1">{getReportTitle(report)}</Typography>
                    <Button
                      sx={{ border: '2px solid #1976d2', marginLeft: '15px' }}
                      onClick={() => {
                        setAreYouSure(true);
                        setReportToRegenerate(report);
                      }}
                    >
                      {generating ? 'Regenerating...' : 'Regenerate Report'}
                    </Button>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    {report.fraudItems.map((item, itemIndex) => (
                      <Box
                        key={`${index}-${itemIndex}`}
                        sx={{
                          boxSizing: 'border-box',
                          width: {
                            sm: '99.9%',
                            md: '49.7%',
                            lg: '33%'
                          },
                        }}
                      >
                        <FraudScorecardItem item={item} user={user} />
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Grid>
          ))}
        </AccordionDetails>
      )}
      <Dialog open={areYouSure} onClose={() => setAreYouSure(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>Confirm that you want to regenerate this report.<br />Regenerating will incur charges.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAreYouSure(false)} variant="outlined">
            No
          </Button>
          <Button onClick={handleYes} variant="contained" color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </BetterAccordion>
  );
}
