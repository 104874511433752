import { FraudGroup, FraudGroupTemplate, FraudGroupType, FraudItem, FraudItemTemplate, FraudItemTemplateTitle, FraudReport, FraudReportTemplate, FraudReportType } from "@backend/types/FraudScorecard";


// fraud group helpers

const isFraudGroup = (group: FraudGroup | FraudGroupTemplate): group is FraudGroup => {
  return 'fraudGroupTemplateId' in group;
};

const isSpecificGroup = (group: FraudGroup | FraudGroupTemplate, type: FraudGroupType): boolean => {
  if (isFraudGroup(group)) {
    return group.fraudGroupTemplate.type === type;
  }
  return group.type === type;
}

export const isBorrower = (group: FraudGroup | FraudGroupTemplate): boolean => {
  return isSpecificGroup(group, FraudGroupType.BORROWER);
}

export const isPersonalGuarantor = (group: FraudGroup | FraudGroupTemplate): boolean => {
  return isSpecificGroup(group, FraudGroupType.PERSONAL_GUARANTOR);
}

export const isCommercialGuarantor = (group: FraudGroup | FraudGroupTemplate): boolean => {
  return isSpecificGroup(group, FraudGroupType.COMMERCIAL_GUARANTOR);
}

export const isBeneficialOwner = (group: FraudGroup | FraudGroupTemplate): boolean => {
  return isSpecificGroup(group, FraudGroupType.BENEFICIAL_OWNER);
}

// fraud report helpers

const isFraudReport = (report: FraudReport | FraudReportTemplate): report is FraudReport => {
  return 'fraudReportTemplateId' in report;
};

const isSpecificReport = (report: FraudReport | FraudReportTemplate, reportType: FraudReportType): boolean => {
  if (isFraudReport(report)) {
    return report.fraudReportTemplate.report === reportType;
  }
  return report.report === reportType;
}

export const isComplyAdvantage = (report: FraudReport | FraudReportTemplate): boolean => {
  return isSpecificReport(report, FraudReportType.COMPLY_ADVANTAGE);
}

export const isBusinessInstantId = (report: FraudReport | FraudReportTemplate): boolean => {
  return isSpecificReport(report, FraudReportType.BUSINESS_INSTANT_ID);
}

export const isBusinessInstantIdSys = (report: FraudReport | FraudReportTemplate): boolean => {
  return isSpecificReport(report, FraudReportType.BUSINESS_INSTANT_ID_SYS);
}

export const isConsumerInstantId = (report: FraudReport | FraudReportTemplate): boolean => {
  return isSpecificReport(report, FraudReportType.CONSUMER_INSTANT_ID);
}

export const isSecretaryOfStateFilings = (report: FraudReport | FraudReportTemplate): boolean => {
  return isSpecificReport(report, FraudReportType.SECRETARY_OF_STATE_FILINGS);
}

export const isRealProperty = (report: FraudReport | FraudReportTemplate): boolean => {
  return isSpecificReport(report, FraudReportType.REAL_PROPERTY);
}

export const isPropertyReport = (report: FraudReport | FraudReportTemplate): boolean => {
  return isSpecificReport(report, FraudReportType.PROPERTY_REPORT);
}

export const isOther = (report: FraudReport | FraudReportTemplate): boolean => {
  return isSpecificReport(report, FraudReportType.OTHER);
}

// fraud item helpers

const isFraudItem = (item: FraudItem | FraudItemTemplate): item is FraudItem => {
  return 'fraudItemTemplateId' in item;
};

const isSpecificItem = (item: FraudItem | FraudItemTemplate, title: string) => {
  if (isFraudItem(item)) {
    return item.fraudItemTemplate.title === title;
  }
  return item.title === title;
}

export const isBusinessNameMatch = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.BUSINESS_NAME_MATCH);
}

export const isPhysicalBusinessAddressMatch = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.PHYSICAL_BUSINESS_ADDRESS_MATCH);
}

export const isSolarSystemAddressMatch = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.SOLAR_SYSTEM_ADDRESS_MATCH);
}

export const isOfacPass = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.OFAC_PASS);
}

export const isEinTinMatch = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.EIN_TIN_MATCH);
}

export const isConfirmedBusinessPhone = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.CONFIRMED_BUSINESS_PHONE);
}

export const isBusinessLicenseConfirmed = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.BUSINESS_LICENSE_CONFIRMED);
}

export const isHighRiskBusinessPass = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.HIGH_RISK_BUSINESS_PASS);
}

export const isFraudScoreBusiness = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.FRAUD_SCORE_BUSINESS);
}

export const isAddressConfirmedOwnedOrLeasedByBorrower = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.ADDRESS_CONFIRMED_OWNED_OR_LEASED_BY_BORROWER);
}

export const isLegalNameMatch = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.LEGAL_NAME_MATCH);
}

export const isPhysicalResidentialAddressMatch = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.PHYSICAL_RESIDENTIAL_ADDRESS_MATCH);
}

export const isSsnMatch = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.SSN_MATCH);
}

export const isDobMatch = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.DOB_MATCH);
}

export const isFraudScoreConsumer = (item: FraudItem | FraudItemTemplate): boolean => {
  return isSpecificItem(item, FraudItemTemplateTitle.FRAUD_SCORE_CONSUMER);
}

