import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionSummary,
  Box,
  Grid,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { RequiredDocumentApprovalStatus } from '../helpers/constants';
import { applicationWorkflowSteps, documentStatusPickerChoices, WorkflowStep } from '../helpers/documentTypeMappings';
import { areAllDocsSentToBank, hasNonVerifiedDocs } from '../helpers/helpers';
import { RequiredDocument as RequiredDocumentType } from '../helpers/types';
import { BetterAccordion } from './BetterAccordion';
import { DocTypeExpandIcon } from './Icons/DocTypeExpandIcon';
import { FolderClosedIcon } from './Icons/FolderClosedIcon';
import { FolderOpenedIcon } from './Icons/FolderOpenedIcon';
import { RequiredDocument } from './Underwriting/RequiredDocument';
import { AccordionActionTypography, AccordionDetailsNoDocuments, AccordionDocumentDetails, DocumentGroupAccordion, GroupedDocumentsStatusTypography } from './StyledComponents';
import { ShowIf } from './ConditionalRender/ShowIf';


const DocumentsByWorkflowStepList = ({ applicationId, docs, users, expandAllClicked, collapseAllClicked, isFiltering, }) => {

  const documentGroups = [
    ...new Set(Object.values(applicationWorkflowSteps)),
    'Other'
  ];
  // This is to determine which accordions (steps) are expanded (WorkflowStep[]). We are starting with all of them expanded
  const [expandedSteps, setExpandedSteps] = useState<WorkflowStep[]>(documentGroups);
  const [expandedDocuments, setExpandedDocuments] = useState<Record<string, boolean>>({});

  // This is needed to prevent the 'Collapse All' button from collapsing the accordions when the component is first mounted
  const hasMounted = useRef(false);

  // Required Documents with the same document_type.name will be grouped together in a folder
  const groupedDocs: Record<WorkflowStep, { name: string; status: string; docs: RequiredDocumentType[]; step: WorkflowStep }[]> = {};
  const ungroupedDocs: Record<WorkflowStep, RequiredDocumentType[]> = {};

  const toggleAllDocuments = (isExpanded: boolean, group: string) => {
    let documents = {}
    const docTypeGroups = groupedDocs[group] || [];
    const singleDocs = ungroupedDocs[group] || [];
    docTypeGroups.forEach((docGroup) => {
      documents[docGroup.step] = documents[docGroup.step] || {};
      documents[docGroup.step][docGroup.name] = isExpanded;
      docGroup.docs.forEach((doc) => {
        documents[doc.id] = isExpanded;
      });
    });
    singleDocs.forEach((doc) => {
      documents[doc.id] = isExpanded;
    });
    setExpandedDocuments((prev) => ({...prev, ...documents}));
  }

  const handleAccordionChange = (panel: WorkflowStep) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpandedSteps((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, panel]
        : prevExpanded.filter((key) => key !== panel)
    );
  };

  const handleFolderExpand = (step: WorkflowStep, folderName: string) => {
    setExpandedDocuments((prevExpanded) => {
      const expandedForStep = prevExpanded[step] || {} as any;
      return {
        ...prevExpanded,
        [step]: {
          ...expandedForStep,
          [folderName]: !expandedForStep[folderName],
        },
      };
    });
  };

  // Group documents based on the workflow step and then by document_type.name
  docs.forEach((doc) => {
    const requiredForStep = doc.required_for_step;
    const mappingGroup = requiredForStep ? applicationWorkflowSteps[requiredForStep] : 'Other';
    const docTypeName = doc.document_type?.name || 'Other';
    
    // Check if other documents of the same type exist
    const sameTypeAndRequiredForStepDocs = docs.filter((d) => d.document_type?.name === docTypeName && d.required_for_step === requiredForStep);
    
    if (!groupedDocs[mappingGroup]) {
      groupedDocs[mappingGroup] = [];
      ungroupedDocs[mappingGroup] = [];
    }
    
    // If there are multiple documents of the same type and required_for_step, group them together
    if (sameTypeAndRequiredForStepDocs.length > 1) {
      // Group documents with the same document_type.name
      let existingGroup = groupedDocs[mappingGroup].find((group) => group.name === docTypeName);
      
      if (existingGroup) {
        existingGroup.docs.push(doc);
      } else {
        groupedDocs[mappingGroup].push({
          name: docTypeName,
          status: doc.approval_status,
          docs: [doc],
          step: applicationWorkflowSteps[requiredForStep],
        });
      }
    } else {
      // Here we keep documents with single document_type.name as ungrouped
      ungroupedDocs[mappingGroup].push(doc);
    }
  });
  
  // Since 'Folder level docs' can contain Required Documents with different statuses, we need to establish priority for grouped documents
  Object.keys(groupedDocs).forEach((step) => {
    groupedDocs[step].forEach((docGroup) => {
      docGroup.status = areAllDocsSentToBank(docGroup) ? RequiredDocumentApprovalStatus.SENT_TO_BANK : hasNonVerifiedDocs(docGroup) ? RequiredDocumentApprovalStatus.NEEDS_REVIEW : RequiredDocumentApprovalStatus.VERIFIED;
      
      // Sort grouped documents alphabetically by requirement_name
      docGroup.docs.sort((a, b) => a.requirement_name.localeCompare(b.requirement_name));
    });
      // Sort grouped documents alphabetically by requirement_name
      groupedDocs[step].sort((a, b) => a.name.localeCompare(b.name));
  });
  
  
  useEffect(() => {
    if (hasMounted.current) {
      // Set the expanded steps to none when the parent's 'Collapse All' button is clicked
      setExpandedSteps([]);
      setExpandedDocuments({});
    } else {
      // This is to prevent the 'Collapse All' button from collapsing the accordions when the component is first mounted
      hasMounted.current = true;
    }
  }, [collapseAllClicked]);

  useEffect(() => {
    // Set the expanded steps to all of them when the parent's 'Expand All' button is clicked
    setExpandedSteps(documentGroups);
    documentGroups.forEach((group) => {
      toggleAllDocuments(true, group);
    })
  }, [expandAllClicked]);

  
  return (
    <>
      {documentGroups.map((docGroup, index) => {
        const docTypeGroups = groupedDocs[docGroup] || [];
        const singleDocs = ungroupedDocs[docGroup] || [];
  
        // Total document count for step
        const totalDocs = docTypeGroups.reduce((acc, group) => acc + group.docs.length, 0) + singleDocs.length;
  
        const accordionLabel = `${docGroup} (${totalDocs})`;

        return (
          // Show if not filtering or if filtering and there are documents in the group
          (!isFiltering || (isFiltering && totalDocs > 0)) && (
            <BetterAccordion
              key={docGroup}
              expanded={expandedSteps.includes(docGroup)}
              defaultExpanded={true}
              onChange={handleAccordionChange(docGroup)}
              sx={{
                boxShadow: 'none',
                '&.Mui-expanded': {
                  margin: 0,
                },
                '&::before': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <DocumentGroupAccordion
                aria-controls={`${docGroup}-content`}
                id={`${docGroup}-header`}
                className="flex-gap-0_5 flex-row-start-reverse"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '.5rem',
                      pl: '.3rem',
                    }}
                  >
                    <DocTypeExpandIcon />
                    <Typography variant="h6"
                      sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 400, fontSize: '18px' }}
                    >
                      {accordionLabel}
                    </Typography>
                  </Box>
                  {totalDocs > 0 && (
                    <Box
                      sx={{ display: expandedSteps.includes(docGroup) ? 'flex' : 'none', alignItems: 'center', gap: '.5rem', justifyContent: 'space-between' }}
                    >
                      <AccordionActionTypography
                        color={"text.primary"}
                        onClick={(event) => {
                          event.stopPropagation()
                          toggleAllDocuments(true, docGroup);
                        }}
                    
                      >
                        Expand all docs
                      </AccordionActionTypography>
                      <AccordionActionTypography
                        color={"text.primary"}
                        onClick={(event) => {
                          event.stopPropagation()
                          toggleAllDocuments(false, docGroup);
                        }}
                      >
                        Collapse all docs
                      </AccordionActionTypography>
                    </Box>
                  )}
                </Box>
              </DocumentGroupAccordion>
              <ShowIf every={[totalDocs > 0]}>
                <AccordionDocumentDetails>
                {/* Render grouped documents */}
                {docTypeGroups.map((docGroup) => {
                  return (
                  <BetterAccordion
                    key={docGroup.name}
                    expanded={expandedDocuments[docGroup.step]?.[docGroup.name] ?? false}
                    onChange={() => handleFolderExpand(docGroup.step, docGroup.name)}
                    sx={{
                      boxShadow: 'none',
                      '&.Mui-expanded': {
                        margin: 0,
                      },
                      '&::before': {
                        backgroundColor: 'transparent',
                      },
                      borderBottom: expandedDocuments[docGroup.step]?.[docGroup.name] ? 'none' : '1px solid #E0E0E0',
                    }}
                  >
                  <AccordionSummary
                    aria-controls={`${docGroup.name}-content`}
                    id={`${docGroup.name}-header`}
                    className="flex flex-row-start-reverse"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      margin: 0,
                      zIndex: 2,
                      my: 0,
                      '& .Mui-expanded': {
                        my: 0,
                      },
                      '& .MuiAccordionSummary-root': {
                        my: 0,
                      },
                      '& .MuiAccordionSummary-content': {
                        my: 0,
                      },
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        transition: 'none',
                        transform: 'none'
                      },
                      borderBottom: expandedDocuments[docGroup.step]?.[docGroup.name] ? '1px solid #E0E0E0' : 'none',
                      pl: '1.7rem',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          '& .icon': {
                            transition: 'transform 0.3s ease',
                            transform: expandedDocuments[docGroup.step]?.[docGroup.name] ? 'rotate(0deg)' : 'rotate(-90deg)',
                          }
                        }}
                      >
                       
                        <ExpandMoreIcon className="icon" />
                        {expandedDocuments[docGroup.step]?.[docGroup.name] ? <FolderOpenedIcon /> : <FolderClosedIcon />}
                        <Typography
                          sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 400, fontSize: '14px', marginLeft: '.5rem' }}
                        >
                          {docGroup.name}
                        </Typography>
                      </Box>
                      <GroupedDocumentsStatusTypography
                        aria-label="Status"
                        backgroundColor={documentStatusPickerChoices.find(status => status.value === docGroup.status)?.backgroundColor}
                        color={documentStatusPickerChoices.find(status => status.value === docGroup.status)?.color}
                      >
                        {docGroup.status ? documentStatusPickerChoices.find(status => status.value === docGroup.status)?.label : ''}
                      </GroupedDocumentsStatusTypography>
                    </Box>
                  </AccordionSummary>
              
                  <AccordionDocumentDetails>
                    <Grid sx={{margin: 0}}>
                      {docGroup.docs.map((doc) => (
                        <Grid item xs={12} key={doc.id} sx={{p: 0}}>
                          <RequiredDocument
                            applicationId={applicationId}
                            key={doc.approval_status}
                            document={doc}
                            users={users}
                            onChange={(isExpanded: boolean) => setExpandedDocuments((data) => ({ ...data, [doc.id]: isExpanded }))}
                            isExpanded={expandedDocuments[doc.id] || false}
                            isSubGroup={true}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDocumentDetails>
                  </BetterAccordion>
                  )
                })}

                {/* Render ungrouped (individual) documents */}
                {singleDocs.map((doc) => (
                  <Grid container key={doc.id} sx={{ borderBottom: '1px solid #E0E0E0' }}>
                    <Grid item xs={12}>
                      <RequiredDocument
                        applicationId={applicationId}
                        key={doc.approval_status}
                        document={doc}
                        users={users}
                        isExpanded={expandedDocuments[doc.id] || false}
                        onChange={(isExpanded: boolean) => {
                          setExpandedDocuments((data) => ({ ...data, [doc.id]: isExpanded }))
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
                </AccordionDocumentDetails>
              </ShowIf>
              <ShowIf every={[totalDocs <= 0]}>
                <AccordionDetailsNoDocuments>
                  <Typography variant="body2" sx={{ fontFamily: 'Lato, sans-serif', pl: '2.5rem', backgroundColor: '#F9FBFD', color: '#868795' }}>
                    No documents in this step.
                  </Typography>
                </AccordionDetailsNoDocuments>
              </ShowIf>
            </BetterAccordion>
          )
        );
      })}
    </>
  );
};


export default DocumentsByWorkflowStepList;
