import { Box, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isLoanOpsUser, isUWOrBDUser, isUwUser, isAdmin } from '../../helpers/auth';

export const BankLoanSideNav = () => {
  const path = useLocation().pathname;
  return (
    <Box
      component="nav"
      sx={{
        bgcolor: '#fff',
        display: 'flex',
        borderRight: '1px solid lightgrey',
        flexDirection: 'column',
        gap: '1em',
      }}
    >
      <List
        sx={{ p: '0', display: 'flex', flexDirection: 'column', width: '12em' }}
      >
        <Link
          to={`documents`}
          style={{ color: 'black', textDecoration: 'none' }}
        >
          <ListItem
            className={`side-nav-link ${
              path.includes('documents') ? 'side-nav-active' : ''
            }`}
          >
            <Typography variant="caption">File management</Typography>
          </ListItem>
        </Link>
          <Link
            to={`send-files-to-bank`}
            style={{ color: 'black', textDecoration: 'none' }}
        >
          <ListItem
            className={`side-nav-link ${
              path.includes('send-files-to-bank') ? 'side-nav-active' : ''
            }`}
          >
            <Typography variant="caption">Send Files to Bank</Typography>
          </ListItem>
        </Link>
        

        {(isLoanOpsUser() || isUwUser()) && (
          <Link
            to={`score-card`}
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <ListItem
              className={`side-nav-link ${
                path.includes('score-card') ? 'side-nav-active' : ''
              }`}
            >
              <Typography variant="caption">Scorecard</Typography>
            </ListItem>
          </Link>
        )}

        <Link to="uw-tool" style={{ color: 'black', textDecoration: 'none' }}>
          <ListItem
            className={`side-nav-link ${
              path.includes('uw-tool') ? 'side-nav-active' : ''
            }`}
          >
            <Typography variant="caption">UW Tool & Credit Memo</Typography>
          </ListItem>
        </Link>

        {isLoanOpsUser() && (
          <Link to="" style={{ color: 'black', textDecoration: 'none' }}>
            <ListItem
              className={`side-nav-link ${
                !path.includes('documents') &&
                !path.includes('pre-screen') &&
                !path.includes('app-details') &&
                !path.includes('score-card') &&
                !path.includes('uw-tool') &&
                !path.includes('send-files-to-bank')
                  ? 'side-nav-active'
                  : ''
              }`}
            >
              <Typography variant="caption">Bank Loan</Typography>
            </ListItem>
          </Link>
        )}
        {isUWOrBDUser() && (
          <Link
            to={`app-details`}
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <ListItem
              className={`side-nav-link ${
                path.includes('app-details') ? 'side-nav-active' : ''
              }`}
            >
              <Typography variant="caption">Application Details</Typography>
            </ListItem>
          </Link>
        )}
      </List>
    </Box>
  );
};
