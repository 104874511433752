import { FraudItemOverrideSource, FraudItemOverrideSourceGroups, FraudItemStatus, FraudItemTemplateTitle } from '@backend/types/FraudScorecard';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { updateFraudItem } from '../../../services/fraudScorecardService';
const FraudItemEdit = ({
  item,
  user,
  open,
  onClose,
}) => {

  const [canSave, setCanSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [comments, setComments] = useState('');
  const [itemValue, setItemValue] = useState(item.valueOverride || item.value || '');
  const [selectedOverrideSource, setSelectedOverrideSource] = useState<FraudItemOverrideSource | null>(null);
  const [requiresOverrideSource, setRequiresOverrideSource] = useState(false);
  const [requiresComment, setRequiresComment] = useState(false);
  const [overrideSources, setOverrideSources] = useState<FraudItemOverrideSource[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (FraudItemOverrideSourceGroups.hasOwnProperty(item.fraudItemTemplate.title)) {
      setRequiresOverrideSource(true);
      setOverrideSources(FraudItemOverrideSourceGroups[item.fraudItemTemplate.title]);
    }
  }, [item]);

  const handleSave = async () => {
    setIsSaving(true);

    item.valueOverride = itemValue;
    item.overrideSource = selectedOverrideSource;
    item.overrideComment = comments;
    item.updatedAt = new Date();
    item.updatedBy = user.id;
    item.updatedByUser = user;

    if (item.fraudItemTemplate.title === FraudItemTemplateTitle.BUSINESS_LICENSE_CONFIRMED && item.valueOverride.toLowerCase() === 'active') {
      item.status = FraudItemStatus.PASSED;
    } else if (item.fraudItemTemplate.title !== FraudItemTemplateTitle.BUSINESS_LICENSE_CONFIRMED && item.valueOverride.toLowerCase() === 'yes') {
      item.status = FraudItemStatus.PASSED;
    } else {
      item.status = FraudItemStatus.KNOCKOUT;
    }

    try {
      item = await updateFraudItem(item);
      onClose();
    } catch (error) {
      console.error('Error saving fraud item', error);
      setError('Error saving fraud item');
    }
  };

  const updateCanSave = () => {
    let pass = true;
    if (itemValue.length === 0) {
      pass = false;
    } else if (requiresOverrideSource && !selectedOverrideSource) {
      pass = false;
    } else if (requiresComment && comments.length === 0) {
      pass = false;
    }
    setCanSave(pass);
  };

  useEffect(() => {
    updateCanSave();
  }, [selectedOverrideSource, comments, requiresOverrideSource, requiresComment, itemValue]);

  const handleValueChange = (event) => {
    setItemValue(event.target.value);
  };

  const handleOverrideSourceChange = (event) => {
    const value = event.target.value as FraudItemOverrideSource;
    setSelectedOverrideSource(value);
    setRequiresComment(value === FraudItemOverrideSource.OTHER);
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  return (
    <Dialog open={open} sx={{ '& .MuiDialog-paper': { width: '800px', maxHeight: 600 } }}>
      <DialogTitle>Edit Fraud Item</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{item.fraudItemTemplate.title}</Typography>

        <Box>
          <Select
            labelId="override-source-select-label"
            id="override-source-select"
            value={itemValue}
            onChange={handleValueChange}
            sx={{ width: '100px' }}
          >
            <MenuItem value={'Yes'} key={'Yes'}>
              Yes
            </MenuItem>
            <MenuItem value={'No'} key={'No'}>
              No
            </MenuItem>
          </Select>
        </Box>
        {requiresOverrideSource && (
          <FormControl sx={{ width: '300px', marginTop: '10px' }}>
            <InputLabel id="override-source-select-label">{selectedOverrideSource ? 'Override Source' : 'Select an Override Source'}</InputLabel>
            <Select
              labelId="override-source-select-label"
              id="override-source-select"
              value={selectedOverrideSource}
              label="Override Source"
              onChange={handleOverrideSourceChange}
              sx={{ width: '300px' }}
            >
              {overrideSources.map((source) => (
                <MenuItem value={source} key={source}>
                  {source}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          label={requiresComment && comments.length === 0 ? 'Comments are Required' : 'Comments'}
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          sx={{ marginTop: '16px' }}
          value={comments}
          onChange={handleCommentsChange}
        />
        {error && <Typography color="error" sx={{ marginTop: '16px' }}>{error}</Typography>}
        {isSaving && <Typography color="info" sx={{ marginTop: '16px' }}>Saving...</Typography>}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">Cancel</Button>
        <Button onClick={handleSave} disabled={!canSave} variant="outlined">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FraudItemEdit;