import { Error } from '@mui/icons-material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRequiredDocuments } from '../contexts/RequiredDocumentsContext';
import { getUserId } from '../helpers/auth';
import {
  MONTHS,
  RequiredDocumentApprovalStatus,
  RequiredDocumentSubType,
  RequiredDocumentUserGroups,
  RequiredFor,
} from '../helpers/constants';
import { CustomSwitch } from './CustomSwitch';
import { Loading } from './Loading';

const userId = getUserId();

const defaultDocumentState = {
  approval_status: RequiredDocumentApprovalStatus.AWAITING_UPLOAD,
  document_approval_source: 'UPLOAD',
  can_upload: RequiredDocumentUserGroups.ALL,
  can_view: RequiredDocumentUserGroups.ALL,
  is_required: false,
  owner_user_id: userId,
};

const RequiredDocumentModal = ({
  open,
  onClose,
  onSubmit,
  initialDocument = null,
  editMode = false,
  users,
}) => {
  const { state: { documentTypes }, getDocumentTypes } = useRequiredDocuments();
  const [requiredDocument, setRequiredDocument] = useState<Record<string, any>>(
    initialDocument ? initialDocument : defaultDocumentState,
  );
  const [error, setError] = useState(null);
  const [isInactiveDocumentType, setIsInactiveDocumentType] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [addMonthField, setAddMonthField] = useState(false);
  const [addYearField, setAddYearField] = useState(false);
  const [addSubTypeField, setAddSubTypeField] = useState(false);

  useEffect(() => {
    setIsInactiveDocumentType(
      !editMode && requiredDocument?.document_type?.includes('Inactive'),
    );
  }, [requiredDocument.document_type]);

  const handleDocumentTypeChange = (event) => {
    const docType = documentTypes[event.target.value];
    // auto-fill requirement name the doc is new
    const requirementName = editMode
      ? requiredDocument.requirement_name
      : docType.name;
    setRequiredDocument((prev) => ({
      ...prev,
      document_type_id: event.target.value,
      is_required: docType.isRequired,
      requirement_name: requirementName,
    }));
  };

  const handleChange = (event) => {
    let value = event.target.value;
    if (event.target.name === 'month' || event.target.name === 'year') {
      value = parseInt(value);
    }
    setRequiredDocument((prev) => ({
      ...prev,
      [event.target.name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    setRequiredDocument((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleClose = () => {
    if (!editMode) {
      setRequiredDocument(defaultDocumentState);
      setAddYearField(false);
      setAddMonthField(false);
      setAddSubTypeField(false);
    }
    onClose();
  };

  const handleSubmit = () => {
    setIsSaving(true);
    setAddMonthField(false);
    setAddYearField(false);
    setAddSubTypeField(false);
    let {
      approved_by_user_username,
      created_by_user_id,
      owner_user_id,
      document_type_id,
      ...rest
    } = requiredDocument;
    let payload = {
      ...rest,
    };

    if (!editMode) {
      created_by_user_id = userId;
    }

    // so we can send this to Salesforce without making a db call
    payload['document_type_name'] = documentTypes[document_type_id].name;

    // handle relations
    if (created_by_user_id) {
      payload['created_by_user'] = {
        connect: { id: created_by_user_id },
      };
    } else {
      if (editMode) {
        payload['created_by_user'] = {
          disconnect: true,
        };
      } else {
        delete payload.created_by_user_id;
      }
    }

    if (owner_user_id) {
      payload['owner'] = {
        connect: { id: owner_user_id },
      };
    } else {
      if (editMode) {
        payload['owner'] = {
          disconnect: true,
        };
      } else {
        delete payload.owner_user_id;
      }
    }

    if (document_type_id) {
      payload['document_type'] = {
        connect: { id: document_type_id },
      };
    } else {
      if (editMode) {
        payload['document_type'] = {
          disconnect: true,
        };
      } else {
        delete payload.document_type_id;
      }
    }

    onSubmit(payload)
      .then(() => {
        onClose();
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsSaving(false);
        if (!editMode) {
          setRequiredDocument(defaultDocumentState);
        }
      });
  };

  useEffect(() => {
    if (initialDocument) {
      for (const key in documentTypes) {
        if (documentTypes[key] == initialDocument['document_type_id']) {
          setRequiredDocument((prev) => {
            // add document_type
            return {
              ...prev,
              document_type: key,
            };
          });
          break;
        }
      }
    }
  }, [initialDocument]);

  useEffect(() => {
    setCanSave(
      !isInactiveDocumentType &&
      requiredDocument.document_type_id &&
      requiredDocument.requirement_name &&
      requiredDocument.approval_status &&
      requiredDocument.document_approval_source &&
      requiredDocument.required_for_step,
      // TODO: Add these back when we expose the fields again
      // requiredDocument.can_upload &&
      // requiredDocument.can_view,
    );
  }, [isInactiveDocumentType, requiredDocument]);

  if (!requiredDocument) {
    return <div>Loading...</div>;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!editMode) {
          setRequiredDocument(defaultDocumentState);
        }
        onClose();
      }}
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth="sm"
      sx={{
        fontFamily: 'Lato, sans-serif',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <DialogTitle id="alert-dialog-title"
          sx={{
            fontFamily: 'Lato, sans-serif',
          }}
        >
          {editMode ? 'Edit Requirement' : 'Create Requirement'}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            padding: '1rem 1.2rem',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {editMode && !requiredDocument ? (
          <DialogContent>
            <Loading />{' '}
          </DialogContent>
        ) : (
          <DialogContent>
            <Grid container spacing={2.5}>
              {error ? (
                <Grid item md={12}>
                  <Box className="flex-row-end flex-gap-0_5">
                    <Error color="error" />
                    <Typography variant="subtitle1" sx={{ fontFamily: 'Lato, sans-serif' }}>{error}</Typography>
                  </Box>
                </Grid>
              ) : null}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Document Type"
                  name="document_type"
                  onChange={handleDocumentTypeChange}
                  select
                  required
                  value={requiredDocument.document_type_id || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true, // This ensures the label is always at the top
                  }}
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}
                >
                  {Object.keys(documentTypes).map((key) => (
                    <MenuItem value={key} key={key} sx={{ fontFamily: 'Lato, sans-serif' }}>
                      {documentTypes[key].name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {isInactiveDocumentType && (
                <Grid item xs={12} md={12}>
                  <Typography variant="caption" sx={{ color: 'red', fontFamily: 'Lato, sans-serif' }}>
                    You cannot create new requirements for inactive document
                    types.
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requirement Name"
                  name="requirement_name"
                  onChange={handleChange}
                  required
                  value={requiredDocument.requirement_name || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true, // This ensures the label is always at the top
                  }}
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="requirement_description"
                  onChange={handleChange}
                  variant="outlined"
                  multiline
                  rows={1}
                  defaultValue={
                    requiredDocument?.requirement_description?.length > 0
                      ? requiredDocument.requirement_description
                      : null
                  }
                  required={requiredDocument.document_type === 'Other'}
                  InputLabelProps={{
                    shrink: true, // This ensures the label is always at the top
                  }}
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {addMonthField ? (
                  <TextField
                    fullWidth
                    label="Month"
                    name="month"
                    onChange={handleChange}
                    select
                    value={requiredDocument?.month || ''}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true, // This ensures the label is always at the top
                    }}
                    sx={{
                      fontFamily: 'Lato, sans-serif',
                    }}
                  >
                    {MONTHS.map((value, index) => (
                      <MenuItem value={index} key={index} sx={{ fontFamily: 'Lato, sans-serif' }}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <IconButton onClick={() => setAddMonthField(true)}
                    sx={{
                      fontFamily: 'Lato, sans-serif',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      borderRadius: '4px',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5rem', color: 'info.main' }}>
                      <AddCircleOutlineRoundedIcon />
                      <Typography sx={{ fontFamily: 'Lato, sans-serif', color: 'info.main' }}>
                        Add Month
                      </Typography>
                    </Box>
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={6} md={4}>
                {addYearField ? (
                  <TextField
                    fullWidth
                    label="Year"
                    name="year"
                    onChange={handleChange}
                    type="number"
                    value={requiredDocument?.year || ''}
                    variant="outlined"
                    inputProps={{
                      min: 2020,
                      max: new Date().getFullYear(),
                    }}
                    InputLabelProps={{
                      shrink: true, // This ensures the label is always at the top
                    }}
                    sx={{
                      fontFamily: 'Lato, sans-serif',
                    }}
                  />
                ) : (
                  <IconButton onClick={() => setAddYearField(true)}
                    sx={{
                      fontFamily: 'Lato, sans-serif',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      borderRadius: '4px',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5rem', color: 'info.main' }}>
                      <AddCircleOutlineRoundedIcon />
                      <Typography sx={{ fontFamily: 'Lato, sans-serif', color: 'info.main' }}>
                        Add Year
                      </Typography>
                    </Box>
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={6} md={4}>
                {addSubTypeField ? (
                  <TextField
                    fullWidth
                    label="Sub Type"
                    name="sub_type"
                    onChange={handleChange}
                    select
                    value={requiredDocument?.sub_type || ''}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true, // This ensures the label is always at the top
                    }}
                    sx={{
                      fontFamily: 'Lato, sans-serif',
                    }}
                  >
                    {Object.values(RequiredDocumentSubType).map((value) => (
                      <MenuItem value={value} key={value} sx={{ fontFamily: 'Lato, sans-serif' }}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <IconButton onClick={() => setAddSubTypeField(true)}
                    sx={{ fontFamily: 'Lato, sans-serif', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: '4px' }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5rem', color: 'info.main' }}>
                      <AddCircleOutlineRoundedIcon />
                      <Typography sx={{ fontFamily: 'Lato, sans-serif', color: 'info.main' }}>
                        Add Sub Type
                      </Typography>
                    </Box>
                  </IconButton>
                )}
              </Grid>

              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  label="Status"
                  name="approval_status"
                  onChange={handleChange}
                  required
                  select
                  value={
                    requiredDocument?.approval_status ??
                    RequiredDocumentApprovalStatus.AWAITING_UPLOAD
                  }
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true, // This ensures the label is always at the top
                  }}
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}
                >
                  {Object.values(RequiredDocumentApprovalStatus).map(
                    (value) => (
                      <MenuItem value={value} key={value} sx={{ fontFamily: 'Lato, sans-serif' }}>
                        {value}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              </Grid>
              <Grid item xs={6} md={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '.5rem',
                }}
              >
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={requiredDocument?.is_required || false}
                      onChange={handleCheckboxChange}
                      name="is_required"
                    />
                  }
                  label="Is Required"
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '.5rem',
                    '& .MuiFormControlLabel-label': {
                      fontSize: '12px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Source"
                  name="document_approval_source"
                  onChange={handleChange}
                  required
                  select
                  value={requiredDocument?.document_approval_source || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true, // This ensures the label is always at the top
                  }}
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}
                >
                  <MenuItem value="DOCUSIGN" sx={{ fontFamily: 'Lato, sans-serif' }}>DOCUSIGN</MenuItem>
                  <MenuItem value="UPLOAD" sx={{ fontFamily: 'Lato, sans-serif' }}>WEB APP UPLOAD</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Required For Step"
                  name="required_for_step"
                  onChange={handleChange}
                  required
                  select
                  value={requiredDocument?.required_for_step || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true, // This ensures the label is always at the top
                  }}
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}
                >
                  {Object.values(RequiredFor).map((value) => (
                    <MenuItem value={value} key={value} sx={{ fontFamily: 'Lato, sans-serif' }}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* TODO: Add these back when we start using them */}
              {/* 
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Users Who Can Upload"
                  name="can_upload"
                  onChange={handleChange}
                  required
                  select
                  value={requiredDocument?.can_upload || ''}
                  variant="outlined"
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}
                >
                  {Object.values(RequiredDocumentUserGroups).map((value) => (
                    <MenuItem value={value} key={value} sx={{ fontFamily: 'Lato, sans-serif' }}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Users Who Can View"
                  name="can_view"
                  onChange={handleChange}
                  required
                  select
                  value={requiredDocument?.can_view || ''}
                  variant="outlined"
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}
                >
                  {Object.values(RequiredDocumentUserGroups).map((value) => (
                    <MenuItem value={value} key={value} sx={{ fontFamily: 'Lato, sans-serif' }}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Owner"
                  name="owner_user_id"
                  onChange={handleChange}
                  select
                  value={requiredDocument?.owner_user_id || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true, // This ensures the label is always at the top
                  }}
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}
                >
                  {Object.keys(users).map((key) => (
                    <MenuItem value={users[key].id} key={key} sx={{ fontFamily: 'Lato, sans-serif' }}>
                      {key}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            color="inherit"
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            type="submit"
            variant="contained"
            color="primary"
            disabled={!canSave || isSaving}
          >
            {isSaving
              ? 'Saving...'
              : editMode
                ? 'Save Changes'
                : 'Create Required Document'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RequiredDocumentModal;
