import AddIcon from '@mui/icons-material/Add';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getUserId } from '../../helpers/auth';
import { NoteType } from '../../helpers/constants';
import { TrashIcon } from '../Icons/TrashIcon';
import { NoteField } from './NoteField';

export const NotesSection = ({
    requiredDocument
}) => {
    const [externalNoteState, setExternalNoteState] = useState<any>(null);
    const [internalNoteState, setInternalNoteState] = useState<any>(null);
    const [showExternalNoteField, setShowExternalNoteField] = useState(false);
    const [showInternalNoteField, setShowInternalNoteField] = useState(false);
    const [newExternalNote, setNewExternalNote] = useState('');
    const [newInternalNote, setNewInternalNote] = useState('');
    const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState<NoteType | null>(null);
    const [isLoading, setIsLoading] = useState({
        noteCreate: false,
        noteDelete: false,
    });

    const userId = getUserId();

    useEffect(() => {
        if (requiredDocument.notes && requiredDocument.notes.length > 0) {
            setExternalNoteState(requiredDocument.notes.find(note => note.note_type === NoteType.External));
            setInternalNoteState(requiredDocument.notes.find(note => note.note_type === NoteType.Internal));
        }
    }, [requiredDocument]);

    const handleNoteCreate = async (noteType: NoteType, noteContent: string) => {
        setIsLoading(prev => ({ ...prev, noteCreate: true }));
        try {
            const noteResponse = await axios.post('/api/v1/uw/note', {
                required_document_id: requiredDocument.id,
                note_type: noteType,
                body: noteContent,
                created_by_user_id: userId,
                updated_by_user_id: userId,
            });

            if (noteType === NoteType.External) {
                setExternalNoteState(noteResponse.data);
                setShowExternalNoteField(false);
                setNewExternalNote('');
            } else {
                setInternalNoteState(noteResponse.data);
                setShowInternalNoteField(false);
                setNewInternalNote('');
            }
        } catch (error) {
            console.error('Error saving note:', error);
        } finally {
            setIsLoading(prev => ({ ...prev, noteCreate: false }));
        }
    };

    const handleNoteDelete = async (noteType: NoteType) => {
        setIsLoading(prev => ({ ...prev, noteDelete: true }));
        try {
            const isExternalNote = noteType === NoteType.External;
            const noteUpdates = { is_deleted: true };
            const noteId = isExternalNote ? externalNoteState.id : internalNoteState.id;

            await axios.patch(`/api/v1/uw/note/${noteId}`, noteUpdates);

            if (isExternalNote) {
                setExternalNoteState(null);
            } else {
                setInternalNoteState(null);
            }
        } catch (error) {
            console.error('Error deleting note:', error);
        } finally {
            setIsLoading(prev => ({ ...prev, noteDelete: false }));
            setNoteDeleteConfirmation(null);
        }
    };

    return (
        <Box sx={{ pl: '5rem', pr: '1rem', py: 1, backgroundColor: '#F7F7F7' }}>
            <Grid container gap={1}>
                {/* External Note */}
                <Grid item xs={externalNoteState ? 5 : 'auto'} sx={{ backgroundColor: '#FFFFFF', border: '1px solid #ECECEC', borderRadius: externalNoteState || showExternalNoteField ? '6px' : '28px', height: 'fit-content', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ padding: externalNoteState ? '1rem' : '6px 12px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: externalNoteState ? 1 : 0 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <ChatBubbleOutlineIcon sx={{ fontSize: 14, color: '#4dadf9', fontWeight: 'bold' }} />
                                <Typography variant="body2" fontWeight={700} color={'#4dadf9'} sx={{ fontSize: '12px' }}>External Note</Typography>
                            </Box>
                            {externalNoteState ? (
                                noteDeleteConfirmation === NoteType.External ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {isLoading.noteDelete ? (
                                            <CircularProgress size={16} />
                                        ) : (
                                            <>
                                                <Typography variant="body2" color="error" sx={{ fontSize: '12px' }}>
                                                    Delete note?
                                                </Typography>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleNoteDelete(NoteType.External)}
                                                    sx={{ padding: 0, color: 'success.main' }}
                                                >
                                                    <CheckIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setNoteDeleteConfirmation(null)}
                                                    sx={{ padding: 0, color: 'error.main' }}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>
                                ) : (
                                    <IconButton
                                        size="small"
                                        onClick={() => setNoteDeleteConfirmation(NoteType.External)}
                                        sx={{
                                            padding: 0,
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '14px'
                                            }
                                        }}
                                    >
                                        <TrashIcon fontSize="small" sx={{ color: '#4dadf9', fontSize: '12px' }} />
                                    </IconButton>
                                )
                            ) : (
                                <IconButton
                                    size="small"
                                    onClick={() => setShowExternalNoteField(true)}
                                    sx={{ padding: 0 }}
                                >
                                    <AddIcon fontSize="small" sx={{ color: '#4dadf9', fontSize: '14px' }} />
                                </IconButton>
                            )}
                        </Box>
                        {externalNoteState ? (
                            <NoteField initialNoteState={externalNoteState} />
                        ) : showExternalNoteField && (
                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    value={newExternalNote}
                                    onChange={(e) => setNewExternalNote(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        fontSize: '12px',
                                        '& .MuiInputBase-input': {
                                            fontSize: '12px'
                                        }
                                    }}
                                />
                                <Box sx={{ mt: 1, display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                                    <Button
                                        size="small"
                                        startIcon={<CloseIcon />}
                                        onClick={() => {
                                            setShowExternalNoteField(false);
                                            setNewExternalNote('');
                                        }}
                                        sx={{
                                            fontSize: '10px',
                                            '& .MuiButton-startIcon': {
                                                fontSize: '10px'
                                            },
                                            '& .MuiButtonBase-root': {
                                                fontSize: '10px'
                                            },
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        startIcon={isLoading.noteCreate ? <CircularProgress size={10} /> : <SaveIcon sx={{ fontSize: '10px' }} />}
                                        onClick={() => handleNoteCreate(NoteType.External, newExternalNote)}
                                        disabled={isLoading.noteCreate}
                                        sx={{ fontSize: '10px' }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Grid>

                {/* Internal Note */}
                <Grid item xs={internalNoteState ? 5 : 'auto'} sx={{ backgroundColor: '#FFFFFF', border: '1px solid #ECECEC', borderRadius: internalNoteState || showInternalNoteField ? '6px' : '28px', height: 'fit-content', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ padding: internalNoteState ? '1rem' : '6px 12px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: internalNoteState ? 1 : 0 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <ChatBubbleOutlineIcon sx={{ fontSize: 14, color: '#4dadf9', fontWeight: 'bold' }} />
                                <Typography variant="body2" fontWeight={700} color={'#4dadf9'} sx={{ fontSize: '12px' }}>Internal Note</Typography>
                            </Box>
                            {internalNoteState ? (
                                noteDeleteConfirmation === NoteType.Internal ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {isLoading.noteDelete ? (
                                            <CircularProgress size={16} />
                                        ) : (
                                            <>
                                                <Typography variant="body2" color="error" sx={{ fontSize: '12px' }}>
                                                    Delete note?
                                                </Typography>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleNoteDelete(NoteType.Internal)}
                                                    sx={{ padding: 0, color: 'success.main' }}
                                                >
                                                    <CheckIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setNoteDeleteConfirmation(null)}
                                                    sx={{ padding: 0, color: 'error.main' }}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>
                                ) : (
                                    <IconButton
                                        size="small"
                                        onClick={() => setNoteDeleteConfirmation(NoteType.Internal)}
                                        sx={{
                                            padding: 0,
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '14px'
                                            }
                                        }}
                                    >
                                        <TrashIcon fontSize="small" sx={{ color: '#4dadf9', fontSize: '12px' }} />
                                    </IconButton>
                                )
                            ) : (
                                <IconButton
                                    size="small"
                                    onClick={() => setShowInternalNoteField(true)}
                                    sx={{ padding: 0, fontSize: '14px' }}
                                >
                                    <AddIcon fontSize="small" sx={{ color: '#4dadf9', fontSize: '14px' }} />
                                </IconButton>
                            )}
                        </Box>
                        {internalNoteState ? (
                            <NoteField initialNoteState={internalNoteState} />
                        ) : showInternalNoteField && (
                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    value={newInternalNote}
                                    onChange={(e) => setNewInternalNote(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        fontSize: '12px',
                                        '& .MuiInputBase-input': {
                                            fontSize: '12px'
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            fontSize: '12px'
                                        }
                                    }}
                                />
                                <Box sx={{ mt: 1, display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                                    <Button
                                        size="small"
                                        startIcon={<CloseIcon sx={{ fontSize: '10px' }} />}
                                        onClick={() => {
                                            setShowInternalNoteField(false);
                                            setNewInternalNote('');
                                        }}
                                        sx={{
                                            fontSize: '10px',
                                            '& .MuiButton-startIcon': {
                                                fontSize: '10px'
                                            },
                                            '& .MuiButtonBase-root': {
                                                fontSize: '10px'
                                            },
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        startIcon={isLoading.noteCreate ? <CircularProgress size={10} /> : <SaveIcon sx={{ fontSize: '10px' }} />}
                                        onClick={() => handleNoteCreate(NoteType.Internal, newInternalNote)}
                                        disabled={isLoading.noteCreate}
                                        sx={{
                                            fontSize: '10px',
                                            '& .MuiButton-startIcon': {
                                                fontSize: '10px'
                                            },
                                            '& .MuiButtonBase-root': {
                                                fontSize: '10px'
                                            },
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NotesSection; 