import axios from "axios";
import { getUserId } from "../helpers/auth";
export const getUsers = async () => {
  return await axios
    .get('/api/v1/internal/users')
    .then((r) => r.data)
    .catch((err) => {
      console.error(`Error getting users`, err);
      throw err;
    });
};

export const getUser = async () => {
  const id = getUserId();
  return await axios
    .get(`/api/v1/internal/user/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      console.error(`Error getting user`, err);
      throw err;
    });
};
