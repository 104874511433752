import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Link as MuiLink,
  SwipeableDrawer,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { UwChklstDrawerIcon } from './Icons/UwChklstDrawerIcon';
import { useRequiredDocuments } from '../contexts/RequiredDocumentsContext';
import { CheckIcon } from './Icons/CheckIcon';
import { ShowIf } from './ConditionalRender/ShowIf';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { groupBySDCategory, } from '../helpers/helpers';
import { SDCategory } from '../helpers/types';
import { DocumentApprovalStatus } from '@backend/constants';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AssuredWorkloadRoundedIcon from '@mui/icons-material/AssuredWorkloadRounded';


interface UnderwritingChecklistDrawerProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  checklistItems: any[];
  customSx?: SxProps<Theme>;
  btnDesktopSx?: SxProps<Theme>;
  drawerHeight?: string;
}

export const UnderwritingChecklistDrawer = ({
  isDrawerOpen,
  toggleDrawer,
  checklistItems,
  customSx,
  btnDesktopSx,
  drawerHeight,
}: UnderwritingChecklistDrawerProps) => {

  const {
    state: { archetypeDocumentTypesRequiredForApp }
  } = useRequiredDocuments();


  const sdDocGroups = groupBySDCategory(archetypeDocumentTypesRequiredForApp.data)

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        variant="permanent" // This is ABSOLUTELY necessary in order for the component to be always open and allow opening, closing, and interacting with the page
        PaperProps={{
          sx: {
            width: 290,
            overflow: 'visible', // Necessary to allow the button to overflow and see it
            transform: isDrawerOpen
              ? 'translateX(0px)'
              : 'translateX(280px)', // Here we are handling the custom open and close actions
            transition: 'none',
            boxShadow: '-3px 3px 4px 0px #0000000A',
            marginTop: drawerHeight,
          },
          'data-testid': 'drawer-paper',
          ...customSx,
        }}
        ModalProps={{
          keepMounted: true,
          BackdropProps: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }}
      >
        <IconButton
          onClick={toggleDrawer} // Open or close the Drawer (translate the drawer)
          sx={{
            position: 'absolute',
            top: '0px',
            right: '290px', // Right outside the drawer
            backgroundColor: 'white',
            color: 'white',
            '&:hover': {
              backgroundColor: '#DDD',
            },
            width: '40px',
            height: '40px',
            borderRadius: '6px 0 0 6px',
            boxShadow: '-3px 3px 4px 0px #0000000A',
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
            ...btnDesktopSx,
          }}
        >
          <UwChklstDrawerIcon
            style={{
              width: '100%',
              color: checklistItems.length > 0 ? '#4476AD' : 'black',
              display: 'flex',
              alignItems: 'center',
              fontSize: '25px',
            }}
            sx={{}}
          />
        </IconButton>

        <Box sx={{ padding: 2 }} role="presentation">
          <Typography sx={{ fontSize: '16px', fontWeight: 700, fontFamily: 'Lato, sans-serif' }}>Underwriting Checklist</Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto', // Enable scrolling inside the content area
            maxHeight: `calc(100% - ${drawerHeight})`, // This is to give it a height of a 100%, substracting the navbar and announcements title height
          }}
        >
          <Box sx={{ padding: 2, height: '80%', overflowY: 'auto', py: 0, }}>

            {archetypeDocumentTypesRequiredForApp?.data?.length > 0 ? (
              Object.entries(sdDocGroups).map(([category, docs]) => (
                <Box key={category} sx={{ mb: 1 }}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Lato, sans-serif', px: '10px', pt: '10px' }}>
                    {SDCategory[category] || category}
                  </Typography>
                  {docs.map((doc) => (
                    <Box
                      key={doc.id}
                      sx={{
                        py: '10px',
                        pr: '10px',
                        pl: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 1,
                      }}
                    >
                      <ShowIf every={[doc.file_status === DocumentApprovalStatus.VERIFIED]}>
                        <Tooltip title="Verified">
                          <CheckIcon />
                        </Tooltip>
                      </ShowIf>
                      <ShowIf every={[doc.file_status === DocumentApprovalStatus.NEEDS_REVIEW]}>
                        <Tooltip title="Needs Review">
                          <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#deeeff', borderRadius: '50%', p: '5px' }}>
                            <DescriptionOutlinedIcon sx={{ color: '#376ca6', fontSize: '16px' }} />
                          </Box>
                        </Tooltip>
                      </ShowIf>
                      <ShowIf every={[doc.file_status === DocumentApprovalStatus.AWAITING_UPLOAD || !doc.file_status]}>
                        <Tooltip title="Awaiting Upload">
                          <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#e6e9ed', borderRadius: '50%', p: '5px' }}>
                            <InsertDriveFileOutlinedIcon sx={{ color: '#646f7a', fontSize: '16px' }} />
                          </Box>
                        </Tooltip>
                      </ShowIf>
                      <ShowIf every={[doc.file_status === DocumentApprovalStatus.ISSUES_IDENTIFIED || doc.file_status === DocumentApprovalStatus.MISSING]}>
                        <Tooltip title="Issues Identified or Document Missing">
                          <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#fff5d6', borderRadius: '50%', p: '5px' }}>
                            <WarningAmberIcon sx={{ color: '#c49300', fontSize: '16px' }} />
                          </Box>
                        </Tooltip>
                      </ShowIf>
                      <ShowIf every={[doc.file_status === DocumentApprovalStatus.SENT_TO_BANK]}>
                        <Tooltip title="Document Sent to Bank">
                          <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#333333', borderRadius: '50%', p: '5px' }}>
                            <AssuredWorkloadRoundedIcon sx={{ color: '#FFFFFF', fontSize: '16px' }} />
                          </Box>
                        </Tooltip>
                      </ShowIf>
                      <Typography sx={{ fontSize: '14px', fontWeight: 400, fontFamily: 'Lato, sans-serif' }}>
                        {doc.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))
            ) : (
              <>
                <ShowIf every={[archetypeDocumentTypesRequiredForApp?.data?.length === 0 && !!archetypeDocumentTypesRequiredForApp?.error]}>
                  <Box sx={{ px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, height: '100%', justifyContent: 'center' }}>
                    <PortableWifiOffIcon sx={{ fontSize: '65px', color: '#7ea3cc' }} />
                    <Typography sx={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Lato, sans-serif', color: '#7ea3cc', textAlign: 'center' }}>
                      {archetypeDocumentTypesRequiredForApp?.error}
                    </Typography>
                  </Box>
                </ShowIf>
                
                <ShowIf every={[!archetypeDocumentTypesRequiredForApp?.data.length]}>
                  <Box sx={{ px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, height: '100%', justifyContent: 'center' }}>
                    <PortableWifiOffIcon sx={{ fontSize: '65px', color: '#7ea3cc' }} />
                    <Typography sx={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Lato, sans-serif', color: '#7ea3cc', textAlign: 'center' }}>
                      Can't fetch required document types for this archetype.
                    </Typography>
                  </Box>
                </ShowIf>

                <ShowIf every={[archetypeDocumentTypesRequiredForApp?.data?.length === 0 && archetypeDocumentTypesRequiredForApp?.loading]}>
                  <Box sx={{ px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, height: '100%', justifyContent: 'center' }}>
                    <CircularProgress sx={{ color: '#4476AD' }} />
                  </Box>
                </ShowIf>
              </>
            )}
          </Box>
          <Box
            sx={{
              padding: 2,
              pr: 3,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Link
              to={`/underwriting/tools/archetype-document-types`}
              style={{ color: 'black', textDecoration: 'none' }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Lato, sans-serif', color: '#4476AD' }}>
                View Requirements
              </Typography>
            </Link>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};
