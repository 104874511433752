import { RequiredDocument, FileType, DocumentType, CustomDocumentType, DocumentTypeWithFileUploadStatus, ArchetypeDocumentType, UserFileVerification } from "../helpers/types";
import { 
  SET_REQUIRED_DOCUMENTS, 
  SET_OPENED_FILES, 
  SET_SELECTED_FILE, 
  SET_DOCUMENT_TYPES, 
  ADD_REQUIRED_DOCUMENT, 
  SET_LOAN_SUMMARY_EXPANDED, 
  SET_ARCHETYPE_DOCUMENT_TYPES_REQUIRED_FOR_APP, 
  SET_ARCHETYPE_DOCUMENT_TYPES_ERROR,
  SET_ARCHETYPE_DOCUMENT_TYPES_LOADING,
  SET_ALL_ARCHETYPE_DOCUMENT_TYPES,
  SET_ALL_ARCHETYPE_DOCUMENT_TYPES_ERROR,
  SET_ALL_ARCHETYPE_DOCUMENT_TYPES_LOADING,
  SET_SELECTED_REQUIRED_DOCUMENT,
  DELETE_REQUIRED_DOCUMENT,
} from "../actions/requiredDocumentsActions";
import { DocumentApprovalStatus } from "@backend/constants";

// These actions are used to update the state of the required documents. 
// They are used in the RequiredDocumentsProvider (Context) component, which dispatches the actions with the payload.
export type RequiredDocumentsActions =
  | { type: typeof SET_REQUIRED_DOCUMENTS; payload: { requiredDocuments: RequiredDocument[] } }
  | { type: typeof ADD_REQUIRED_DOCUMENT; payload: { requiredDocument: RequiredDocument } }
  | { type: typeof SET_DOCUMENT_TYPES; payload: { documentTypes: Record<DocumentType['id'], CustomDocumentType> } }
  | { type: typeof SET_OPENED_FILES; payload: { openedFiles: FileType[] } }
  | { type: typeof SET_SELECTED_FILE; payload: { selectedFile: FileType | null } }
  | { type: typeof SET_SELECTED_REQUIRED_DOCUMENT; payload: { selectedRequiredDocument: RequiredDocument | null } }
  | { type: typeof SET_LOAN_SUMMARY_EXPANDED; payload: { loanSummaryExpanded: boolean } }
  | { type: typeof SET_ARCHETYPE_DOCUMENT_TYPES_REQUIRED_FOR_APP; payload: { archetypeDocumentTypesRequiredForApp: DocumentTypeWithFileUploadStatus[] } }
  | { type: typeof SET_ARCHETYPE_DOCUMENT_TYPES_ERROR; payload: { errorMessage: string } }
  | { type: typeof SET_ARCHETYPE_DOCUMENT_TYPES_LOADING; payload: { loading: boolean } }
  | { type: typeof SET_ALL_ARCHETYPE_DOCUMENT_TYPES; payload: { allArchetypeDocumentTypes: ArchetypeDocumentType[] } }
  | { type: typeof SET_ALL_ARCHETYPE_DOCUMENT_TYPES_ERROR; payload: { errorMessage: string } }
  | { type: typeof SET_ALL_ARCHETYPE_DOCUMENT_TYPES_LOADING; payload: { loading: boolean } }
  | { type: typeof DELETE_REQUIRED_DOCUMENT; payload: { documentId: string } }

// This is the state of the Required Documents context. It is used in the RequiredDocumentsProvider (Context) component.
export type RequiredDocumentsState = {
  requiredDocuments: RequiredDocument[]
  openedFiles: FileType[]
  selectedFile: FileType | null
  selectedRequiredDocument: RequiredDocument | null
  documentTypes: Record<DocumentType['id'], CustomDocumentType>
  loanSummaryExpanded: boolean
  archetypeDocumentTypesRequiredForApp: {
    data: DocumentTypeWithFileUploadStatus[]
    loading: boolean
    error: string | null
  }
  allArchetypeDocumentTypes: {
    data: ArchetypeDocumentType[]
    loading: boolean
    error: string | null
  }
}

// This is the state with we initialize the context with.
export const initialState: RequiredDocumentsState = {
  requiredDocuments: [],
  openedFiles: [],
  selectedFile: null,
  selectedRequiredDocument: null,
  documentTypes: {},
  loanSummaryExpanded: true,
  archetypeDocumentTypesRequiredForApp: {
    data: [],
    loading: false,
    error: null,
  },
  allArchetypeDocumentTypes: {
    data: [],
    loading: false,
    error: null,
  },
}

// This is the reducer function that updates the state of the Required Documents context based on the action dispatched.
export const requiredDocumentsReducer = (
  state: RequiredDocumentsState = initialState,
  action: RequiredDocumentsActions
) => {

  const { type, payload } = action;

  switch (type) {
    case SET_REQUIRED_DOCUMENTS:
      return {
        ...state,
        requiredDocuments: payload.requiredDocuments,
      };
    case SET_OPENED_FILES:
      return {
        ...state,
        openedFiles: payload.openedFiles
      };
    case SET_SELECTED_FILE:
      return {
        ...state,
        selectedFile: payload.selectedFile
      };
    case SET_SELECTED_REQUIRED_DOCUMENT:
      return {
        ...state,
        selectedRequiredDocument: payload.selectedRequiredDocument
      };
    case SET_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: payload.documentTypes
      };
    case ADD_REQUIRED_DOCUMENT:
      return {
        ...state,
        requiredDocuments: [...state.requiredDocuments, payload.requiredDocument]
      };
    case SET_LOAN_SUMMARY_EXPANDED:
      return {
        ...state,
        loanSummaryExpanded: !payload.loanSummaryExpanded
      };
    case SET_ARCHETYPE_DOCUMENT_TYPES_LOADING:
      return {
        ...state,
        archetypeDocumentTypesRequiredForApp: {
          data: [],
          loading: true,
          error: null,
        },
      };
    case SET_ARCHETYPE_DOCUMENT_TYPES_REQUIRED_FOR_APP:
      return {
        ...state,
        archetypeDocumentTypesRequiredForApp: {
          data: payload.archetypeDocumentTypesRequiredForApp.map((doc) => {
            const status = state.requiredDocuments.find(
              ({ document_type }) => document_type.name === doc.name
            )?.approval_status || null;
        
            return {
              ...doc,
              file_status: status ? (DocumentApprovalStatus[status as unknown as keyof typeof DocumentApprovalStatus]) : null,
            };
          }),
          error: null,
          loading: false,
        }
      }
      case SET_ARCHETYPE_DOCUMENT_TYPES_ERROR:
        return {
          ...state,
          archetypeDocumentTypesRequiredForApp: {
            data: [],
            error: payload.errorMessage,
            loading: false,
          },
        };
      case SET_ALL_ARCHETYPE_DOCUMENT_TYPES:
        return {
          ...state,
          allArchetypeDocumentTypes: {
            data: payload.allArchetypeDocumentTypes,
            loading: false,
            error: null,
          },
        };
      case SET_ALL_ARCHETYPE_DOCUMENT_TYPES_ERROR:
        return {
          ...state,
          allArchetypeDocumentTypes: {
            data: [],
            error: payload.errorMessage,
            loading: false,
          },
        };
      case SET_ALL_ARCHETYPE_DOCUMENT_TYPES_LOADING:
        return {
          ...state,
          allArchetypeDocumentTypes: {
            data: [],
            loading: true,
            error: null,
          },
        };
      case DELETE_REQUIRED_DOCUMENT:
        return {
          ...state,
          requiredDocuments: state.requiredDocuments.filter((doc) => doc.id !== payload.documentId),
        };
    default:
      return state;
  }

}