import { ReportKeyPrefix, ReportType } from '@backend/constants';
import { FraudGroup, FraudGroupTemplate, FraudItem, FraudItemStatus, FraudItemTemplateTitle, FraudReport, FraudScorecard, UwReportStatus } from '@backend/types/FraudScorecard';
import { SFLoanApplication, SFRelatedParty } from '@backend/types/Loan';
import axios from 'axios';
import { getUserId } from '../helpers/auth';
import {
  isBeneficialOwner,
  isBorrower as isBorrowerCheck,
  isBusinessInstantId,
  isBusinessInstantIdSys,
  isBusinessLicenseConfirmed,
  isBusinessNameMatch,
  isCommercialGuarantor,
  isConfirmedBusinessPhone,
  isConsumerInstantId,
  isDobMatch,
  isEinTinMatch,
  isFraudScoreBusiness,
  isFraudScoreConsumer,
  isHighRiskBusinessPass,
  isLegalNameMatch,
  isOfacPass,
  isOther,
  isPersonalGuarantor,
  isPhysicalBusinessAddressMatch,
  isPhysicalResidentialAddressMatch,
  isSolarSystemAddressMatch,
  isSsnMatch
} from '../helpers/fraudScorecardHelper';
import { formatAddress, redactSSN } from '../helpers/helpers';
import { getRelatedParties } from './salesforceService';
import { getUser } from './usersService';
// create a new, empty fraud scorecard model for a given application

const getFraudGroup = (fraudGroupTemplate: FraudGroupTemplate, name: string, parent: SFRelatedParty | SFLoanApplication) => {
  return {
    fraudGroupTemplateId: fraudGroupTemplate.id,
    fraudGroupTemplate: fraudGroupTemplate,
    parentId: parent.Id,
    name: name,
    fraudReports: [],
    parent: parent,
  };
}

const populateVerificationValues = (fraudGroup: FraudGroup, fraudItem: FraudItem) => {
  const isBorrower = isBorrowerCheck(fraudGroup);
  // populate verification values
  if (isBusinessNameMatch(fraudItem)) {
    if (isBorrower) {
      fraudItem.verificationValue = (fraudGroup?.parent as SFLoanApplication)?.Borrower_Business_Name__c ?? '';
    } else {
      fraudItem.verificationValue = (fraudGroup?.parent as SFRelatedParty)?.Business_Name__c ?? '';
    }
  } else if (isPhysicalBusinessAddressMatch(fraudItem)) {
    if (isBorrower) {
      const parent = fraudGroup?.parent as SFLoanApplication;
      fraudItem.verificationValue = formatAddress(parent.Business_Address__c ?? '', parent.Business_Address_Line_2__c ?? '', parent.Business_Address_City__c ?? '', parent.Business_Address_State__c ?? '', parent.Business_Address_Zip_Code__c ?? '');
    } else {
      const parent = fraudGroup?.parent as SFRelatedParty;
      fraudItem.verificationValue = formatAddress(parent.Address__c ?? '', parent.Address_Line_2__c ?? '', parent.City__c ?? '', parent.State_picklist__c ?? '', parent.Zip_Code__c ?? '');
    }
  } else if (isSolarSystemAddressMatch(fraudItem)) {
    const parent = fraudGroup?.parent as SFLoanApplication;
    fraudItem.verificationValue = formatAddress(parent.Sys_Prop_Address__c ?? '', parent.Sys_Prop_Address_Line_2__c ?? '', parent.Sys_Prop_Address_City__c ?? '', parent.Sys_Prop_Address_State__c ?? '', parent.Sys_Prop_Address_Zip_Code__c ?? '');
  } else if (isEinTinMatch(fraudItem)) {
    if (isBorrower) {
      fraudItem.verificationValue = (fraudGroup?.parent as SFLoanApplication)?.Borrower_TIN__c ?? '';
    } else {
      fraudItem.verificationValue = (fraudGroup?.parent as SFRelatedParty)?.SSN_text__c ?? '';
    }
  } else if (isConfirmedBusinessPhone(fraudItem)) {
    if (isBorrower) {
      fraudItem.verificationValue = (fraudGroup?.parent as SFLoanApplication)?.Borrower_Business_Phone_Number__c ?? '';
    } else {
      fraudItem.verificationValue = (fraudGroup?.parent as SFRelatedParty)?.Phone_Number__c ?? '';
    }
  } else if (isLegalNameMatch(fraudItem)) {
    const parent = fraudGroup.parent as SFRelatedParty;
    fraudItem.verificationValue = parent?.First_Name__c + ' ' + parent?.Last_Name__c;
  } else if (isPhysicalResidentialAddressMatch(fraudItem)) {
    const parent = fraudGroup?.parent as SFRelatedParty;
    fraudItem.verificationValue = formatAddress(parent.Address__c ?? '', parent.Address_Line_2__c ?? '', parent.City__c ?? '', parent.State_picklist__c ?? '', parent.Zip_Code__c ?? '');
  } else if (isSsnMatch(fraudItem)) {
    fraudItem.verificationValue = redactSSN((fraudGroup?.parent as SFRelatedParty)?.SSN_text__c ?? '');
  } else if (isDobMatch(fraudItem)) {
    fraudItem.verificationValue = (fraudGroup?.parent as SFRelatedParty)?.DOB__c ?? '';
  } else if (isHighRiskBusinessPass(fraudItem)) {
    const parent = fraudGroup?.parent as SFLoanApplication;
    fraudItem.verificationValue = parent?.Borrower_Is_High_Risk_Explanation__c ?? '';
    fraudItem.value = parent?.Borrower_Is_High_Risk__c === 'Yes' ? 'No' : 'Yes';
    fraudItem.status = fraudItem.value === 'Yes' ? FraudItemStatus.KNOCKOUT : FraudItemStatus.PASSED;
  }
}

export const getNewFraudScorecard = async (application: SFLoanApplication) => {
  const result = await axios.get('/api/v1/uw/fraud-scorecard-template');
  const fraudScorecardTemplate = result.data;

  const relatedParties = await getRelatedParties(application.Id);

  // filter personal guarantors are individuals with >25% ownership
  let pgs = relatedParties.filter((rp) => rp.Relationship__c === 'Principal' && rp.RecordType.Name === 'Individual' && rp.Percent_Ownership__c >= 25);
  // filter commercial guarantors are businesses
  let cgs = relatedParties.filter((rp) => rp.Relationship__c === 'Guarantor' && rp.Entity_Type__c === 'Business');

  const userId = await getUserId();
  if (!userId) {
    throw new Error('User ID not found');
  }

  const user = await getUser();
  if (!user) {
    throw new Error('User not found');
  }

  const fraudScorecard: FraudScorecard = {
    applicationId: application.Id,
    loanVersionId: '',
    fraudScorecardTemplateId: fraudScorecardTemplate.id,
    fraudScorecardTemplate: fraudScorecardTemplate,
    fraudGroups: [],
    createdAt: new Date(),
    createdBy: userId,
    createdByUser: user,
  };

  for (const fraudGroupTemplate of fraudScorecardTemplate.fraudGroupTemplates) {

    let newFraudGroups: FraudGroup[] = [];

    const isBorrower = isBorrowerCheck(fraudGroupTemplate);

    if (isBorrower) {
      const fraudGroup = getFraudGroup(fraudGroupTemplate, 'Borrower', application);
      newFraudGroups.push(fraudGroup);
    } else if (isPersonalGuarantor(fraudGroupTemplate)) {
      for (const pg of pgs) {
        if (pg.Is_Personal_Guarantor__c === 'Yes') {
          const fraudGroup = getFraudGroup(fraudGroupTemplate, 'Personal Guarantor: ' + pg.First_Name__c + ' ' + pg.Last_Name__c, pg);
          newFraudGroups.push(fraudGroup);
        }
      }
    } else if (isCommercialGuarantor(fraudGroupTemplate)) {
      for (const cg of cgs) {
        const fraudGroup = getFraudGroup(fraudGroupTemplate, 'Commercial Guarantor: ' + cg.Business_Name__c, cg);
        newFraudGroups.push(fraudGroup);
      }
    } else if (isBeneficialOwner(fraudGroupTemplate)) {
      for (const pg of pgs) {
        if (pg.Is_Personal_Guarantor__c !== 'Yes') {
          const fraudGroup = getFraudGroup(fraudGroupTemplate, 'Beneficial Owner: ' + pg.First_Name__c + ' ' + pg.Last_Name__c, pg);
          newFraudGroups.push(fraudGroup);
        }
      }
    }

    fraudScorecard.fraudGroups.push(...newFraudGroups);

    for (const fraudGroup of newFraudGroups) {

      for (const fraudReportTemplate of fraudGroupTemplate.fraudReportTemplates) {
        const fraudReport: FraudReport = {
          uwReportId: null,
          uwReport: null,
          uwReportStatus: null,
          fraudReportTemplateId: fraudReportTemplate.id,
          fraudReportTemplate: fraudReportTemplate,
          name: '',
          fraudItems: [],
        };

        fraudGroup.fraudReports.push(fraudReport);

        for (const fraudItemTemplate of fraudReportTemplate.fraudItemTemplates) {
          // populate verification values

          const fraudItem: FraudItem = {
            fraudItemTemplateId: fraudItemTemplate.id,
            fraudItemTemplate: fraudItemTemplate,
            index: fraudItemTemplate.index,
            value: '',
            valueOverride: null,
            overrideSource: null,
            overrideComment: null,
            verificationValue: '',
            currentValue: '',
            status: FraudItemStatus.NO_VALUE,
          };

          populateVerificationValues(fraudGroup, fraudItem);

          fraudReport.fraudItems.push(fraudItem);
        }
      }
    }
  }

  return fraudScorecard;
}

function getNestedValue(obj: any, path: string): any {
  return path.split('.').reduce((acc, key) => acc?.[key], obj);
}

const getRelatedPartyId = (fraudGroup: FraudGroup) => {
  const isBorrower = isBorrowerCheck(fraudGroup);
  return isBorrower ? '' : (fraudGroup.parent as SFRelatedParty)?.Id;
}

const generateBusinessInstantIdReport = async (applicationId: string, search_id: string, fraudReport: FraudReport, relatedPartyId: string, isSolarSystem: boolean) => {

  let uwReport: any = null;

  try {
    const result = await axios.post('/api/v1/uw/lexis-nexis/business-instant-id', {
      applicationId: applicationId,
      relatedPartyId: relatedPartyId,
      isSolarSystem: isSolarSystem,
      searchId: search_id,
    });
    uwReport = result.data;
  } catch (error) {
    console.error('Error getting business instant id', error);
    fraudReport.uwReportStatus = UwReportStatus.FAILED;
  }

  return uwReport;
}

const processBusinessInstantIdReport = async (fraudReport: FraudReport, uwReport: any) => {

  fraudReport.uwReportId = uwReport.id;
  fraudReport.uwReport = uwReport;
  fraudReport.uwReportStatus = UwReportStatus.COMPLETED;

  const companyResults = uwReport.report.BIID20ResponseEx.response.Result.CompanyResults;

  // populate verification values
  for (const fraudItem of fraudReport.fraudItems) {

    // populate values and statuses from the report
    const field = fraudItem.fraudItemTemplate.verificationField
    if (field) {
      const value = getNestedValue(companyResults, field);
      if (isFraudScoreBusiness(fraudItem)) {
        fraudItem.value = value;
        fraudItem.status = parseInt(value) >= 39 ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      } else if (isOfacPass(fraudItem)) {
        fraudItem.value = value ? 'No' : 'Yes';
        fraudItem.status = value ? FraudItemStatus.KNOCKOUT : FraudItemStatus.PASSED;
      } else if (isBusinessLicenseConfirmed(fraudItem)) {
        fraudItem.value = value === 'ACTIVE' ? 'Yes' : 'No';
        fraudItem.status = value === 'ACTIVE' ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      } else {
        fraudItem.value = value === '1' ? 'Yes' : 'No';
        fraudItem.status = value === '1' ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      }
    } else {
      if (isPhysicalBusinessAddressMatch(fraudItem) || isSolarSystemAddressMatch(fraudItem)) {
        const streetAddress = getNestedValue(companyResults, 'VerificationIndicators.StreetAddress');
        const city = getNestedValue(companyResults, 'VerificationIndicators.City');
        const state = getNestedValue(companyResults, 'VerificationIndicators.State');
        const zip = getNestedValue(companyResults, 'VerificationIndicators.Zip');

        const pass = streetAddress + city + state + zip === '1111';
        fraudItem.value = pass ? 'Yes' : 'No';
        fraudItem.status = pass ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      } else {
        console.error('No idea what to do with this field', field);
      }
    }
  }

  return fraudReport;
}

const processConsumerInstantIdReport = async (fraudReport: FraudReport, uwReport: any) => {
  let result = null;
  if (uwReport) {
    fraudReport.uwReportId = uwReport.id;
    fraudReport.uwReport = uwReport;
    fraudReport.uwReportStatus = UwReportStatus.COMPLETED;
    result = uwReport.report.IID20ResponseEx.response.Result;
  } else {
    fraudReport.uwReportStatus = UwReportStatus.FAILED;
  }

  // populate verification values

  for (const fraudItem of fraudReport.fraudItems) {

    // TODO: remove when we get CIID access
    if (!uwReport) {
      fraudItem.status = FraudItemStatus.NO_VALUE;
      continue;
    }

    // populate values and statuses from the report
    const field = fraudItem.fraudItemTemplate.verificationField
    if (field) {
      const value = getNestedValue(result, field);
      if (isFraudScoreBusiness(fraudItem)) {
        fraudItem.value = value;
        fraudItem.status = parseInt(value) >= 39 ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      } else if (isBusinessLicenseConfirmed(fraudItem)) {
        fraudItem.value = value === 'ACTIVE' ? 'Yes' : 'No';
        fraudItem.status = value === 'ACTIVE' ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      } else {
        fraudItem.value = value === 1 ? 'Yes' : 'No';
        fraudItem.status = value === 1 ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      }

      if (isLegalNameMatch(fraudItem)) {
        fraudItem.value = value === 12 ? 'Yes' : 'No';
        fraudItem.status = value === 12 ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      } else if (isPhysicalResidentialAddressMatch(fraudItem)) {
        fraudItem.value = value === 12 ? 'Yes' : 'No';
        fraudItem.status = value === 12 ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      } else if (isSsnMatch(fraudItem)) {
        fraudItem.value = ['C', 'G'].includes(value) ? 'Yes' : 'No';
        fraudItem.status = ['C', 'G'].includes(value) ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      } else if (isDobMatch(fraudItem)) {
        fraudItem.value = value === 1 ? 'Yes' : 'No';
        fraudItem.status = value === 1 ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      } else if (isFraudScoreConsumer(fraudItem)) {
        fraudItem.value = value === 1 ? 'Yes' : 'No';
        fraudItem.status = parseInt(value) >= 39 ? FraudItemStatus.PASSED : FraudItemStatus.KNOCKOUT;
      } else if (isOfacPass(fraudItem)) {
        fraudItem.value = value ? 'No' : 'Yes';
        fraudItem.status = value ? FraudItemStatus.KNOCKOUT : FraudItemStatus.PASSED;
      } else {
        throw new Error(`Unexpected fraud item template: ${fraudItem.fraudItemTemplate.title}`);
      }
    } else {
      console.error('No idea what to do with this field', field);
    }
  }
}


const getSearchId = (fraudGroup: FraudGroup, fraudReport: FraudReport, isBorrower: boolean) => {
  const tin = isBorrower ? (fraudGroup.parent as SFLoanApplication).Borrower_TIN__c : (fraudGroup.parent as SFRelatedParty)?.SSN_text__c;

  let prefix = '';
  if (isBusinessInstantId(fraudReport)) {
    prefix = ReportKeyPrefix.BUSINESS_INSTANT_ID2;
  } else if (isBusinessInstantIdSys(fraudReport)) {
    prefix = ReportKeyPrefix.BUSINESS_INSTANT_ID2_SYS;
  } else if (isConsumerInstantId(fraudReport)) {
    prefix = ReportKeyPrefix.CONSUMER_INSTANT_ID2;
  }
  return prefix + '-' + tin;
}

const getUwReport = (uwReports: any[], search_id: string) => {
  let matchingReports = uwReports?.filter((r) => r.search_id == search_id && r.report_type === ReportType.LEXIS)

  // find the most recent report
  matchingReports.sort((a, b) => new Date(b.generated_date).getTime() - new Date(a.generated_date).getTime());
  return matchingReports[0];
}

// create a new fraud scorecard for an application
export const generateFraudScorecard = async (application: SFLoanApplication, setGeneratingMessage: (message: string) => void): Promise<FraudScorecard> => {
  const fraudScorecard = await getNewFraudScorecard(application);

  const result = await axios.get(`/api/v1/uw/reports/${application.Id}`);
  const uwReports = result.data;

  // attach reports
  for (const fraudGroup of fraudScorecard.fraudGroups) {

    const isBorrower = isBorrowerCheck(fraudGroup);
    for (const fraudReport of fraudGroup.fraudReports) {

      // business instant id, business address and solar system address

      const search_id = getSearchId(fraudGroup, fraudReport, isBorrower);
      let uwReport = getUwReport(uwReports, search_id);

      if (isBusinessInstantId(fraudReport) || isBusinessInstantIdSys(fraudReport)) {
        const isSolarSystem = isBusinessInstantIdSys(fraudReport);
        if (isSolarSystem) {
          setGeneratingMessage(`Business Instant ID (Solar System) for ${fraudGroup.fraudGroupTemplate.type}...`);
        } else {
          setGeneratingMessage(`Business Instant ID for ${fraudGroup.fraudGroupTemplate.type}...`);
        }

        if (!uwReport) {
          uwReport = await generateBusinessInstantIdReport(application.Id, search_id, fraudReport, getRelatedPartyId(fraudGroup), isSolarSystem);
        }
        await processBusinessInstantIdReport(fraudReport, uwReport);

        // consumer instant id - currently disabled

      } else if (isConsumerInstantId(fraudReport)) {
        setGeneratingMessage(`Consumer Instant ID for ${fraudGroup.fraudGroupTemplate.type}...`);

        // TODO: re-enable when we haave CIID access
        // if (!uwReport) {
        //   try {
        //     throw new Error('Cannot generate consumer instant id yet');
        //     report = await axios.post('/api/v1/uw/lexis-nexis/consumer-instant-id', {
        //       applicationId: application.Id,
        //       searchId: ReportKeyPrefix.CONSUMER_INSTANT_ID2 + '-' + tin,
        //     });
        //   } catch (error) {
        //     console.error('Error getting consumer instant id', error);
        //     fraudReport.uwReportStatus = UwReportStatus.FAILED;
        //     continue;
        //   }
        // }

        await processConsumerInstantIdReport(fraudReport, uwReport);

        // other

      } else if (isOther(fraudReport)) {
        // nothing to do currently
      } else {
        console.error('Unknown fraud report template', fraudReport.fraudReportTemplate.report);
      }
    }
  };

  setGeneratingMessage('Saving fraud scorecard...');
  await createNewFraudScorecard(fraudScorecard);
  const resultFraudScorecard = await getFraudScorecard(application.Id);

  return resultFraudScorecard;
}

export const regenerateFraudReport = async (fraudScorecard: FraudScorecard, fraudReport: FraudReport) => {
  const fraudGroup = fraudScorecard.fraudGroups.find((fg) => fg.id === fraudReport.fraudGroupId);
  if (!fraudGroup) {
    throw new Error('Fraud group not found');
  }
  const isBorrower = isBorrowerCheck(fraudGroup);

  // update verification values, clear overrides
  for (const fraudItem of fraudReport.fraudItems) {
    fraudItem.verificationValue = fraudItem.currentValue;
    fraudItem.overrideSource = null;
    fraudItem.overrideComment = null;
    fraudItem.valueOverride = null;
  }

  if (isBusinessInstantId(fraudReport) || isBusinessInstantIdSys(fraudReport)) {
    const isSolarSystem = isBusinessInstantIdSys(fraudReport);
    const search_id = getSearchId(fraudGroup, fraudReport, isBorrower);
    const uwReport = await generateBusinessInstantIdReport(fraudScorecard.applicationId, search_id, fraudReport, getRelatedPartyId(fraudGroup), isSolarSystem);
    await processBusinessInstantIdReport(fraudReport, uwReport);

  } else if (isConsumerInstantId(fraudReport)) {
    const uwReport = fraudReport.uwReport;
    await processConsumerInstantIdReport(fraudReport, uwReport);

  } else if (isOther(fraudReport)) {
    const fraudItem = fraudReport.fraudItems.find((fi) => fi.fraudItemTemplate.title === FraudItemTemplateTitle.HIGH_RISK_BUSINESS_PASS);
    if (!fraudItem) {
      throw new Error('Fraud item not found');
    }
    fraudItem.value = (fraudGroup.parent as SFLoanApplication).Borrower_Is_High_Risk__c === 'Yes' ? 'No' : 'Yes';
    fraudItem.status = fraudItem.value === 'Yes' ? FraudItemStatus.KNOCKOUT : FraudItemStatus.PASSED;
    populateVerificationValues(fraudGroup, fraudItem);
  } else {
    console.error('Unknown fraud report', fraudReport.fraudReportTemplate.report);
  }

  await createNewFraudScorecard(fraudScorecard);
  const resultFraudScorecard = await getFraudScorecard(fraudScorecard.applicationId);
  return resultFraudScorecard;
}

// create a new fraud scorecard in the database
export const createNewFraudScorecard = async (fraudScorecard: FraudScorecard) => {
  const result = await axios.post('/api/v1/uw/fraud-scorecard', fraudScorecard);
  return result.data;
};

// get a fraud scorecard from the database
export const getFraudScorecard = async (appId: string) => {

  const result = await axios.get(`/api/v1/uw/fraud-scorecard/${appId}`);
  const fraudScorecard = result.data;

  // set the current value for each fraud item
  for (const fraudGroup of fraudScorecard.fraudGroups) {
    const parent = fraudGroup.parent;
    for (const fraudReport of fraudGroup.fraudReports) {
      const isBorrower = isBorrowerCheck(fraudGroup);
      for (const fraudItem of fraudReport.fraudItems) {
        const field = fraudItem.fraudItemTemplate.field
        if (field) {
          if (field === 'Special') {
            const temlplate = fraudItem.fraudItemTemplate;
            if (isLegalNameMatch(temlplate)) {
              fraudItem.currentValue = (parent.First_Name__c ?? '') + (parent.Last_Name__c ? ' ' + parent.Last_Name__c : '');
            } else if (isBusinessNameMatch(temlplate)) {
              if (isBorrower) {
                fraudItem.currentValue = parent.Borrower_Business_Name__c ?? '';
              } else {
                fraudItem.currentValue = parent.Business_Name__c ?? '';
              }
            } else if (isEinTinMatch(temlplate)) {
              if (isBorrower) {
                fraudItem.currentValue = parent.Borrower_TIN__c ?? '';
              } else {
                fraudItem.currentValue = parent.SSN_text__c ?? '';
              }
            } else if (isPhysicalBusinessAddressMatch(temlplate)) {
              if (isBorrower) {
                fraudItem.currentValue = formatAddress(parent.Business_Address__c, parent.Business_Address_Line_2__c, parent.Business_Address_City__c, parent.Business_Address_State__c, parent.Business_Address_Zip_Code__c);
              } else {
                fraudItem.currentValue = formatAddress(parent.Address__c, parent.Address_Line_2__c, parent.City__c, parent.State_picklist__c, parent.Zip_Code__c);
              }
            } else if (isConfirmedBusinessPhone(temlplate)) {
              if (isBorrower) {
                fraudItem.currentValue = parent.Borrower_Business_Phone_Number__c ?? '';
              } else {
                fraudItem.currentValue = parent.Phone_Number__c ?? '';
              }
            } else if (isSsnMatch(temlplate)) {
              fraudItem.currentValue = redactSSN(parent.SSN_text__c)
            } else if (isSolarSystemAddressMatch(temlplate)) {
              fraudItem.currentValue = formatAddress(parent.Sys_Prop_Address__c, parent.Sys_Prop_Address_Line_2__c, parent.Sys_Prop_Address_City__c, parent.Sys_Prop_Address_State__c, parent.Sys_Prop_Address_Zip_Code__c);
            } else if (isPhysicalResidentialAddressMatch(temlplate)) {
              fraudItem.currentValue = formatAddress(parent.Address__c, parent.Address_Line_2__c, parent.City__c, parent.State_picklist__c, parent.Zip_Code__c);
            }
          } else {
            fraudItem.currentValue = parent[field] ?? '';
          }
        }
      }
    }
  }

  return fraudScorecard;
};

// update a fraud item in the database
export const updateFraudItem = async (fraudItem: FraudItem) => {
  try {
    const result = await axios.put(`/api/v1/uw/fraud-item`, fraudItem);
    return result.data;
  } catch (error) {
    console.error('Error updating fraud item', error);
    throw error;
  }
};
