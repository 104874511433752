import React, { useState, useEffect } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { FileType, RequiredDocumentWithConventionName } from '../helpers/types';
import { GroupedDocumentsStatusTypography } from './StyledComponents';
import { FileUploadSource, RequiredDocumentApprovalStatus } from '../helpers/constants';
import { useRequiredDocuments } from '../contexts/RequiredDocumentsContext';
import { documentStatusPickerChoices } from '../helpers/documentTypeMappings';
import { niceDate } from '../helpers/converters';
import { ShowIf } from './ConditionalRender/ShowIf';


interface SelectRequiredDocumentCardProps {
  document: RequiredDocumentWithConventionName;
  file: FileType;
  selected: boolean;
  onSelect: (document: RequiredDocumentWithConventionName) => void;
}

export const SelectRequireDocumentCard = ({ document, file, selected, onSelect }: SelectRequiredDocumentCardProps) => {

  const isUploadedByBorrower = file.upload_source === FileUploadSource.ROSEFIELD;

  const isDocumentSentToBank = document.approval_status === RequiredDocumentApprovalStatus.SENT_TO_BANK;
  const isDocumentVerified = document.approval_status === RequiredDocumentApprovalStatus.VERIFIED;
  
  const {
    addFileToPreview, 
    setSelectedFile, 
    setSelectedRequiredDocument,
  } = useRequiredDocuments();


  const SharedMetaDataComponent = () => {
    return (
      <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
          <Typography variant="body2"  sx={{fontWeight: isDocumentSentToBank ? 500 : 400, fontSize: '12px', color: '#868795'}}>{file.file_name}</Typography>
          {(isDocumentVerified || isDocumentSentToBank) && (
            <Typography variant="body2"  sx={{fontWeight: isDocumentSentToBank ? 500 : 400, fontSize: '12px', color: '#868795'}}>Verified: {document.approval_date ? niceDate(document.approval_date) : 'Date not available'}</Typography>
          )}
          <Typography variant="body2"  sx={{fontWeight: 500, fontSize: '12px', color: '#868795'}}>Uploaded: {file.created_by} {isUploadedByBorrower ? '(Borrower)' : ''}</Typography>
        </Box>
    );
  };

  const SentToBankMetaDataComponent = () => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1}}>
        <SharedMetaDataComponent />
        {isDocumentSentToBank && file.bank_transmission_files.map((bankTransmissionFile) => (
          <Box
            key={bankTransmissionFile?.id}
            sx={{display: 'flex', alignItems: 'center', gap: 1}}
          >
            <Typography variant="body2"  sx={{fontWeight: 400, fontSize: '12px', color: '#868795'}}>Sent to: {bankTransmissionFile?.bank_transmission?.bank}</Typography>
            <Typography variant="body2"  sx={{fontWeight: 400, fontSize: '12px', color: '#868795'}}>{niceDate(bankTransmissionFile?.created_at)}</Typography>
            <Typography variant="body2"  sx={{fontWeight: 400, fontSize: '12px', color: '#868795'}}>Sent by: {bankTransmissionFile?.bank_transmission?.user.name}</Typography>
            <Typography variant="body2"  sx={{fontWeight: 400, fontSize: '12px', color: '#868795'}}>{bankTransmissionFile?.bank_transmission?.transmission_reason}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 1,
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 1,
          flexDirection: 'column',
        }}
      >
        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
          <Checkbox
            checked={selected}
            onChange={() => onSelect(document)}
            sx={{p:0}}
          />
          <Typography variant="body1" sx={{fontWeight: 700, fontSize: '12px'}}>{document.document_type.name}</Typography>
          <Typography variant="body1" sx={{color: '#1E89DD', fontWeight: 700, fontSize: '12px', cursor: 'pointer'}}
            onClick={(event) => {
              event.stopPropagation();
              addFileToPreview(file);
              setSelectedFile(file);
              const { convention_name, crb_convention_name, ...documentWithoutConventionName } = document;
              setSelectedRequiredDocument(documentWithoutConventionName);
            }}
          >{document.convention_name}</Typography>
        </Box>
        <ShowIf every={[isDocumentSentToBank]}>
          <SentToBankMetaDataComponent />
        </ShowIf>
        <ShowIf every={[!isDocumentSentToBank]}>
          <SharedMetaDataComponent />
        </ShowIf>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <GroupedDocumentsStatusTypography
          backgroundColor={documentStatusPickerChoices.find(status => status.value === document.approval_status)?.backgroundColor}
          color={documentStatusPickerChoices.find(status => status.value === document.approval_status)?.color}
        >
          {document.approval_status ? documentStatusPickerChoices.find(status => status.value === document.approval_status)?.label : ''}
        </GroupedDocumentsStatusTypography>
      </Box>
    </Box>
  );
};